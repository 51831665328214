export default {
	categories: [{
			slug: 'outdoor-still-life',
			paintings: [{
				slug: 'co-existants',
				key: [{
						"index": 1,
						"name": "Baboonwood “baboen”",
						latin: "Virola surinamensis"
					},
					{
						"index": 2,
						"name": "Vine",
						latin: "Phaeostemma surinamensis"
					},
					{
						"index": 3,
						"name": "Golden-winged parakeet",
						latin: "Brotogeris chrysoptera"
					},
					{
						"index": 4,
						"name": "Bromeliad",
						latin: "Canistropsis billbergioides"
					},
					{
						"index": 5,
						"name": "Clusia “abrasa”",
						latin: "Clusia grandifl ora"
					},
					{
						"index": 6,
						"name": "Guianan cock-of-the-rock",
						latin: "Rupicola rupicola",
						facts: "Sexual dimorphism is obvious in both cock-of-the-rock species: bright orange males gather at a communal display site called a “lek”, favoring remote rocky terrain. The duller colored females come to the lek to choose a mate but go off alone to nest and raise chicks on a cliff side or shallow cave. Both species of Rupicola are important seed dispersers: they eat fruits from all over the forest and tend to regurgitate or defecate the seeds at these communal sites. Many of these seeds germinate and grow, greatly increasing species diversity near both leks and nesting areas."
					},
					{
						"index": 7,
						"name": "Three-toed sloth",
						latin: "Bradypus tridacti lus"
					},
					{
						"index": 8,
						"name": "Palm",
						latin: "Astrocaryum vulgare"
					},
					{
						"index": 9,
						"name": "Mouse possum",
						latin: "Gracilinanus emiliae"
					},
					{
						"index": 10,
						"name": "Black Spider Monkey",
						latin: "Ateles paniscus",
						facts: "The black spider monkey is one of the biggest primates in South America. This large body mass equals a high caloric need, driving monkeys to forage over a wide range. A large home range in mammals usually results in low density of individuals. Like other spider monkeys these prefer undisturbed mature forest, utilizing the upper layers of the canopy, and they rarely if, ever visit, forest edges. Spider monkeys’ role as seed dispersers in the rain forest makes it hugely important to the maintenance of strong botanical diversity. The combined pressures of illegal hunting, slow reproductive rate, and deforestation make this primate’s future uncertain: its numbers continue to decline. Ateles paniscus is currently rated as Vulnerable on the IUCN’s Red List."
					},
					{
						"index": 11,
						"name": "Kapok “kankantri, dia makka”",
						latin: "Ceiba pentandra"
					},
					{
						"index": 12,
						"name": "Strangler Fig “strangler”",
						latin: "Ficus sp."
					},
					{
						"index": 13,
						"name": "Bitter-wood “Kwasibita”",
						latin: "Quassia amara"
					},
					{
						"index": 14,
						"name": "Squirrel monkey",
						latin: "Saimiri sciureus"
					},
					{
						"index": 15,
						"name": "Morpho butterfly",
						latin: "Morpho helenor"
					},
					{
						"index": 16,
						"name": "Andiroba “Krapa”",
						latin: "Carapa guianensis"
					},
					{
						"index": 17,
						"name": "Black-capped donacobius",
						latin: "Donacobius atricapilla",
						facts: "These wetland birds mate for life. They are “cooperative breeders,” meaning the chicks remain with the parents, helping rear subsequent broods. Despite their ubiquitous distribution throughout Suriname, they have not been studied well. Little is know about them: what they eat, where they nest, how they learn the antiphonal duet mated pairs sing to each other. Is it taught to offspring? Are there regional variations to duets? Does the duet reinforce pair bonding or familial ties, or does it help define territory? The Donacobius is famed for its tail-wagging, head-nodding dance but how and why did that evolve? What prompts the birds to put on such a display?"
					},
					{
						"index": 18,
						"name": "Heliconia",
						latin: "Heliconia sp."
					},
					{
						"index": 19,
						"name": "Giant mantid",
						latin: "Macromantis hyalina"
					},
					{
						"index": 20,
						"name": "Tufted coquette",
						latin: "Lophornis ornatus"
					},
					{
						"index": 21,
						"name": "Heaven lotus “watra-mamabobi, laagland konkoni-oedoe, tapoeripa”",
						latin: "Gustavia augusta"
					},
					{
						"index": 22,
						"name": "Golden-olive woodpecker",
						latin: "Colaptes rubiginosus"
					},
					{
						"index": 23,
						"name": "White-faced saki",
						latin: "Pithecia pithecia"
					},
					{
						"index": 24,
						"name": "Black spotted skink",
						latin: "Mabuya nigropunctata"
					},
					{
						"index": 25,
						"name": "Variable Cracker",
						latin: "Hamadryas feronia",
						facts: "As far as we know there are only a few types of butterflies in the world that make noise. For example, Heliconius cydno butterflies make subtle noises with their wings. And there are species of ant-dependant blue butterflies whose caterpillars communicate with their ant hosts through sound. The use of ultraviolet light reflection on wing patches is also used in butterfly communication. But male Hamdryas butterflies make clearly audible cracking noises with their wings. Most recorded sound exchanges of H. feronia involve competition over food or mates. Crackers tend to perch on tree bark that matches their wing pattern and coloration. They perch with their heads down, tails up, and wings spread, ready for flight."
					},
					{
						"index": 26,
						"name": "Tree “e-oedoe”",
						latin: "Enterolobium schomburgkii"
					},
					{
						"index": 27,
						"name": "Grey-winged trumpeter",
						latin: "Psophia crepitans"
					},
					{
						"index": 28,
						"name": "Fern",
						latin: "Species unknown (from IK photo in country)"
					},
					{
						"index": 29,
						"name": "Orchid",
						latin: "Lepanthes yunkeri"
					},
					{
						"index": 30,
						"name": "Philodendron “itana” (Arawak)",
						latin: "Philodendron acutatum"
					},
					{
						"index": 31,
						"name": "Black curassow",
						latin: "Crax alector"
					},
					{
						"index": 32,
						"name": "Snail",
						latin: "Species unknown (from IK photo in country)"
					},
					{
						"index": 33,
						"name": "Hiding herb “Kibri wiwiri”",
						latin: "Psychotria ulviformis",
						facts: "A great number of plants, and plant products like bulbs, wood, seeds, leaves, and flowers, are used in both local medicinal products and wintri preparations. This plant has the reputation for making one “invisible” when used “magically.” It has also been reportedly been included in some ayahuasca-type ethnogenic brews. Western medicine has much to learn from the pharmacological study of traditional medicinal preparations."
					},
					{
						"index": 34,
						"name": "Fer-de-lance (juvenile)",
						latin: "Bothrops atrox"
					},
					{
						"index": 35,
						"name": "Ground orchid",
						latin: "Spiranthes callifera"
					},
					{
						"index": 36,
						"name": "Jaguar",
						latin: "Panthera onca"
					},
					{
						"index": 37,
						"name": "Pod “kankan-oedoe”",
						latin: "Apeiba echinata"
					},
					{
						"index": 38,
						"name": "Pod “swit’bonki”",
						latin: "Inga coriacea"
					},
					{
						"index": 39,
						"name": "Giant anteater",
						latin: "Myrmecophaga tridactyla",
						facts: "These seven foot long animals can eat 30 thousand ants, termites, and larvae a day. No teeth are needed, just a lightening-fast 24” tongue that has back facing barbs and sticky saliva. The tongue is so long it actually anchors to the breastbone or sternum. Very strong arms are needed for tearing up earth, rotting logs, and termite mounds. Anteaters spend about 1-2 minutes at each feeding site, visiting up to sites 200 a day. The greatest dangers to giant anteaters are jaguars, pumas, fire, and man. The IUCN (International Union for the Conservation of Nature) lists anteaters as vulnerable due to habitat loss and hunting. The poaching and consumption of wild animals is one of the prime vectors for retrovirus chains to enter humans."
					},
					{
						"index": 40,
						"name": "Pompadour cotinga",
						latin: "Xipholena punicea"
					},
					{
						"index": 41,
						"name": "Herb “Adoja kers”",
						latin: "Campomanesia aromatica"
					},
					{
						"index": 42,
						"name": "Crimson-hooded manakin",
						latin: "Pipra aureola"
					},
					{
						"index": 43,
						"name": "Monkey ladder vine",
						latin: "Banisteriopsis sp."
					},
					{
						"index": 44,
						"name": "Arrowhead piculet",
						latin: "Picumnus minutissimus E",
						facts: "One of the smallest woodpeckers in the world, the arrowhead piculet measures just 4” or 10 cm. They roost in tree cavities. This tiny woodpecker is endemic to Suriname. Endemic in this instance means from here and nowhere else. There are no “back-up” localities for this bird: if it disappears from Suriname it will be extinct in the world completely."
					},
					{
						"index": 45,
						"name": "Begonia “kotto hati”",
						latin: "Begonia glabra"
					},
					{
						"index": 46,
						"name": "Curtain vine “malva”",
						latin: "Cissus verticillata"
					},
					{
						"index": 47,
						"name": "White-plumed antbird",
						latin: "Pithys albifrons"
					},
					{
						"index": 48,
						"name": "Three-striped dart frog",
						latin: "Ameerega trivittatus",
						facts: "Not uncommon among poison dart frogs, male parental care is the norm for this species. The male guards fertile eggs. He will move young tadpoles to more suitable habitat if necessary. For example, if the pool originally selected dries up or lacks proper concentration of food for the tadpoles. The male frog carries the tadpoles on his back until they reach a new source of fresh water, often a well inside of a bromeliad is chosen for last stage of metamorphosis. These frogs, and most of the other small birds, amphibians, and reptiles in the corner of this painting eat ants, represented here by leaf cutters (#54)."
					},
					{
						"index": 49,
						"name": "Great tinamou",
						latin: "Tinamus major",
						facts: "The cryptically colored tinamous are shy birds of undisturbed forests. Though their tremulous calls are frequently heard, they are rarely seen in the open. Tinamous lay their eggs in scrapes on the ground, often in leaf litter between the buttress roots of mature rain forest trees. Tinamou eggs are laid in a lovely array of colors, ranging from deep olive and chocolate brown to violet. Recent tests have shown that these eggs actually have an iridescent coating on them that makes them appear in different shades due to nanostructure coloration: underneath the coating, all tinamou eggs are just plain blue."
					},
					{
						"index": 50,
						"name": "Eggs of tinamou",
						latin: "Tinamus major"
					},
					{
						"index": 51,
						"name": "Yellow-footed tortoise",
						latin: "Chelonoidis denticulata"
					},
					{
						"index": 52,
						"name": "Giant ameiva (juvenile)",
						latin: "Ameiva ameiva"
					},
					{
						"index": 53,
						"name": "Purple Harlequin frog",
						latin: "Atelopus hoogmoedinassaui"
					},
					{
						"index": 54,
						"name": "Leaf-cutter ants",
						latin: "Atta cephalotes"
					},
					{
						"index": 55,
						"name": "Blue poison dart frog",
						latin: "Dendrobates tinctorius azureus"
					},
					{
						"index": 56,
						"name": "Guianan warbling antbird",
						latin: "Hypocnemis cantator"
					},
					{
						"index": 57,
						"name": "Whiptail lizard",
						latin: "Kentropyx pelviceps"
					},
					{
						"index": 58,
						"name": "Scarlet ibis",
						latin: "Eudocimus ruber",
						facts: "The only truly red shore bird, scarlet ibis get much of their color from carotenoids in the foods they eat: shrimps, molluscs, crabs and other crustaceans. These communally roosting birds try to find safety in numbers: large flocks can include several thousand individuals. Despite being the national bird of Trinidad and Tobago, many people choose to hunt ibis for food. Gregarious by nature, these birds can be fooled into thinking a red cloth is one of own kind. Some hunters use this trick to lure ibis within shooting range. One new measure to protect ibis from illegal hunting is to set up numerous beehives near roosting and nesting sites."
					},
					{
						"index": 59,
						"name": "Pond weed",
						latin: "Lemna minor"
					},
					{
						"index": 60,
						"name": "Suriname toad",
						latin: "Pipa pipa",
						facts: "Common to streams, ponds, and the flooded forests of northern South America, this 4 - 7” toad looks like a dead leaf in water. Its flat body and angular form suggested its name: “pipa” means “kite” in Portugese. Ambush predators, these toads wait for invertebrates, aquatic insects, and worms, sensing preys’ presence through tendrils on their skin and tiny star-shaped feelers on the front toes. A male Suriname toad attracts a female by making clicking noises in his throat. During their mating, or amplexus, 100-150 fertilized eggs stick to only the female’s back. Within hours the eggs cover over with her skin, eventually forming a honeycomb of embryonic sacks. Fully functional toadlets hatch from the sacs once they have reached about 2 cm in size. Once hatched the tiny toads are on their own. There is one other unrelated species of “back-brooding” toad, the European midwife toad. These two toads provide a perfect example of convergent evolution, where a trait evolves more than once, across separations of time and distance."
					},
					{
						"index": 61,
						"name": "Double-armoured catfish",
						latin: "Pseudacanthicus sp."
					},
					{
						"index": 62,
						"name": "Nest of catfish",
						latin: "Pseudacanthicus sp."
					},
					{
						"index": 63,
						"name": "American pygmy kingfisher",
						latin: "Chloroceryle aenea"
					},
					{
						"index": 64,
						"name": "Damselfly",
						latin: "Species unknown (from IK photo in country)"
					},
					{
						"index": 65,
						"name": "Giant otter",
						latin: "Pteronura brasiliensis"
					},
					{
						"index": 66,
						"name": "Green-and-rufous kingfisher",
						latin: "Chloroceryle inda"
					},
					{
						"index": 67,
						"name": "Dung beetle",
						latin: "Copraphaneus lancifer"
					},
					{
						"index": 68,
						"name": "Agouti",
						latin: "Dasyprocta cristata"
					},
					{
						"index": 69,
						"name": "Fruit of baboonwood",
						latin: "Virola surinamensis"
					},
					{
						"index": 70,
						"name": "Fruit of palm “awara”",
						latin: "Astrocaryum vulgare"
					},
					{
						"index": 71,
						"name": "Fruit of tree",
						latin: "Enterolobium schomburgkii"
					},
					{
						"index": 72,
						"name": "Purple gallinule (& chicks)",
						latin: "Porphyrio martinicus"
					},
					{
						"index": 73,
						"name": "Water lily “Pankuku wiri”",
						latin: "Nymphaea amazonum"
					},
					{
						"index": 74,
						"name": "Palo de hoz “Branti makka”",
						latin: "Machaerium lunatum"
					}
				]
			}, {
				slug: 'father-time',
				key: [{
						"index": 1,
						"name": "Acorn-Storing Woodpecker",
						latin: "Melanerpes formicivorus",
						facts: "These gregarious, vociferous birds have one of the most complexsocial structures in the avian world. Within their extended family group they are polyamorous, use a communal nest, females will eat one another’s eggs to coordinate laying cycles, and entire group build and maintain massive granaries of stored acorns for when other foods are scarce. A typical group is made up of (+/-) 7 breeding males, three or four breeding females, non-breeding adults, and juveniles. Generally, the males are related to each other and the females are related toeach other; however, the breeding males and females are not related. Non-breeding females guard the nest, help feed the young, and tend the granaries, but they also hunt for a nearby group with a vacancy. Maintenance of the granary includes drilling new holes, storage of new acorns, and, as the acorns dry during the year, rearrangement so that each acorn fits snugly in its hole to deter theft. Although granaries are used repeatedly, thousands of fresh acorns are stored each year by each family group. (If you want to discourage the use of your siding as a storage facility, offer sacrificial wood.)."
					},
					{
						"index": 2,
						"name": "Ma’s night monkey",
						latin: "Aotus nancymaae",
						facts: "Many small primate fathers (see #5, #6, #9, and #20) provide piggy- back service for their off-spring. Ma’s Night Monkey (also known as owl monkeys) mate for life. Each pair forms the foundation of a small family troop with its juveniles and young adult off-spring. Pairs mate all year long, but only one to three babies are born each year. Babies nurse for just three weeks. After the first week, outside of nursing, the father takes complete charge of the baby until it is able to keep up with the troop on its own. He grooms, provisions, trains and nurtures the baby during their active night and protects and keeps it on his body as they nest and sleep during the day."
					},
					{
						"index": 3,
						"name": "California mouse",
						latin: "Peromyscus californicus"
					},
					{
						"index": 4,
						"name": "Campbell’s dwarf hamster",
						latin: "Phodopus campbelli",
						facts: "Campbell’s dwarf hamsters are sexually mature at five to eight weeks of age. Gestation is only 20 days and a pair will mate again almost immediately after birth. With three to four litters per year and an average of eight pups per litter it is no surprise that midwifery skills have evolved in the males. These fathers contribute heavily to pup survival: they help pull pups from birth canal, lick them clean to remove amniotic fluids and clear nasal passages, they provision both the mom and pups, and will even eat the placenta."
					},
					{
						"index": 5,
						"name": "Common marmoset",
						latin: "Callithrix jacchus"
					},
					{
						"index": 6,
						"name": "Cotton top tamarin",
						latin: "Saguinus oedipus"
					},
					{
						"index": 7,
						"name": "Darwin’s frog",
						latin: "Rhinodermis darwinii"
					},
					{
						"index": 8,
						"name": "Dunnock",
						latin: "Prunella modularis"
					},
					{
						"index": 9,
						"name": "Golden lion tamarin",
						latin: "Leontopithecus rosalia"
					},
					{
						"index": 10,
						"name": "Masked cichlid",
						latin: "Julidochromis transcriptus",
						facts: "There are (+/-) 2500 species of Cichlids, the most diverse family in the animal kingdom. The “Julies” (Julidochromis species), popular in the aquarium trade, evolved in Lake Tanganyika’s rocky areas. This particular species displays some unusual breeding behavior. Pairs maintain strong and durable bonds, despite the female’s practice of mating with a beta male on the side. Breeding females prefer a tapered nesting site so that the larger alpha male can fertilize eggs from the greater opening, but the smaller beta male can still reach the eggs to fertilize them from the narrower end of the site. While the females guard the eggs themselves, both males guard the nest site as the eggs mature and hatch."
					},
					{
						"index": 11,
						"name": "Malleefowl",
						latin: "Leipoa ocellata",
						facts: "A pair of mallee fowl maintain a territory together, though as a rule they neither roost nor feed together. As breeding season nears the male locates a new or reusable nest site; he piles up and adjusts the mix of sand, compost, and soil for proper incubation. By means unknown, he must sense both the temperature and humidity of the mound: he works and guards it for nine months of the year. When the mound is ready and the time right, he digs an egg chamber and the female lays from two to 30 eggs. For (+/-) 60 days he tends the active nest, then chicks hatch one at a time: from that point on they are precocial and need no further parental involvement. Each parent roams for three months then they find one another to begin a new cycle."
					},
					{
						"index": 12,
						"name": "Meerkat",
						latin: "Suricata suricata"
					},
					{
						"index": 13,
						"name": "Prairie vole",
						latin: "Microtus ochrogaster"
					},
					{
						"index": 14,
						"name": "Pukeko",
						latin: "Porphyrio melanotus"
					},
					{
						"index": 15,
						"name": "African wild dog",
						latin: "Lycaon pictus",
						facts: "A pack of (+/-) 40 African wild dogs usually has one dominant breeding pair that is monogamous, it is thought, for life. Litter size varies from just a few to 20 pups. Once weaned the pups become the responsibility of the entire pack who cooperate to provide daycare, guard against predators, and tend any injured. The pack hunts collectively also, led by the alpha male. Each pack member at a kill fills its belly rapidly and entirely. On their eagerly awaited return to the den site, the hunters regurgitate some of this pre-digested food for the clan’s puppies."
					},
					{
						"index": 16,
						"name": "Organ pipe mud dauber",
						latin: "Trypoxylon politum"
					},
					{
						"index": 17,
						"name": "Hoogmoed’s harlequin frog",
						latin: "Atelopus hoogmoedi"
					},
					{
						"index": 18,
						"name": "Australian marsupial frog",
						latin: "Assa darlingtoni",
						facts: "Many male poison dart frogs (see #23, #24, & #26) carry tadpoles piggy-back from one body of water to another, from a temporary puddle or pond, up a tree, to the well of a bromeliad where they can safely complete their transition from tadpole to legged and tailless frog. The tiny marsupial frog has evolved a different strategy. The female lays her eggs on land, in leaf litter or under a stone. Both parents guard the eggs. Once the eggs hatch the tadpoles climb into pouches just under the skin near the hips of the male. The tadpoles will remain in these pouches through their metamorphosis."
					},
					{
						"index": 19,
						"name": "American burying beetle",
						latin: "Nicrophorus americanus"
					},
					{
						"index": 20,
						"name": "Pygmy marmoset",
						latin: "Cebuella pygmaea"
					},
					{
						"index": 21,
						"name": "Toe biter",
						latin: "Family Belostomatidae"
					},
					{
						"index": 22,
						"name": "Harvestman",
						latin: "Order Opiliones"
					},
					{
						"index": 23,
						"name": "Three-striped poison frog",
						latin: "Ameerega trivittata"
					},
					{
						"index": 24,
						"name": "Strawberry poison-dart frog",
						latin: "Oophaga pumilio"
					},
					{
						"index": 25,
						"name": "Double-wattled or Southern Cassowary",
						latin: "Casuarius casuarius",
						facts: "Cassowary males mate with one itinerant female, though the females may have multiple partners. The scrape-nest each male builds will be filled exclusively with his mate’s eggs. From the moment the four or so eggs are laid the father does not leave them untended and turns them faithfully every five or six hours. He will barely eat or drink during the entire incubation of (+/-) 50 days. Once his chicks hatch, he is solely responsible for their survival and training until they are self-sufficient... nearly a full year of diligent care."
					},
					{
						"index": 26,
						"name": "Golden poison frog",
						latin: "Phyllobates terribilis"
					}
				]
			}, {
				slug: 'nantahala',
				key: [{
						"index": 1,
						"name": "Turk’s Cap Lily (anther)",
						"latin": "Lillium superbum"
					},
					{
						"index": 2,
						"name": "Nantahala Millipede",
						"latin": "Sigmoria nantahalae"
					},
					{
						"index": 3,
						"name": "Red-backed Salamander (back)",
						"latin": "Plethodon serratus"
					},
					{
						"index": 4,
						"name": "Carolina Dusky Salamander (side)",
						"latin": "Desmagnathus ochrophaeus complex"
					},
					{
						"index": 5,
						"name": "Grand Globe (snail)",
						"latin": "Mesodon normalis"
					},
					{
						"index": 6,
						"name": "Basswood Leafroller",
						"latin": "Pantographa limata"
					},
					{
						"index": 7,
						"name": "Waxy Cap (fungus)",
						"latin": "Hygrocybe flavescens"
					},
					{
						"index": 8,
						"name": "Caroliniana Rhododendron",
						"latin": "Rhododendron minus var majus"
					},
					{
						"index": 9,
						"name": "Jelly Tooth or Cat’s Tongue (under surface)",
						"latin": "Pseudohydrum gelatinosum"
					},
					{
						"index": 10,
						"name": "Violet-tooth Polypore",
						"latin": "Trichaptum biforme"
					},
					{
						"index": 11,
						"name": "Appalachian Azure (butterfly)",
						"latin": "Celastina neglectamajor"
					},
					{
						"index": 12,
						"name": "Blue Jay (feather)",
						"latin": "Cyanocitta cristata"
					},
					{
						"index": 13,
						"name": "Elf Cup (fungus)",
						"latin": "Chlorociboria aeruginascens"
					},
					{
						"index": 14,
						"name": "Carolina Sweet Shrub (pod)",
						"latin": "Calycanthus floridus"
					},
					{
						"index": 15,
						"name": "Perforated Ruffle Lichen",
						"latin": "Parmotrema perforatum"
					},
					{
						"index": 16,
						"name": "Purple-flowering Raspberry (petal)",
						"latin": "Rubus odoratus"
					}
				]
			}, {
				slug: 'nudibranchia',
				key: [{
						"index": 1,
						"name": "Okenia cochimi",
						"latin": 2004
					},
					{
						"index": 2,
						"name": "Doto amyra",
						"latin": 1961
					},
					{
						"index": 3,
						"name": "Aegires pruvotfolae",
						"latin": 2004
					},
					{
						"index": 4,
						"name": "Eubranchus mandapamensis",
						"latin": 1968
					},
					{
						"index": 5,
						"name": "Dermatobranchus albineus",
						"latin": 2011
					},
					{
						"index": 6,
						"name": "Gymnodoris nigricolor",
						"latin": 1960
					},
					{
						"index": 7,
						"name": "Eubranchus inabai",
						"latin": 1964
					},
					{
						"index": 8,
						"name": "Babakina indopacifica",
						"latin": 2007
					},
					{
						"index": 9,
						"name": "Noumea laboutei",
						"latin": 1986
					},
					{
						"index": 10,
						"name": "Phyllodesmium macphersonae",
						"latin": 1962
					},
					{
						"index": 11,
						"name": "Cadlinella ornatissima",
						"latin": 1928
					},
					{
						"index": 12,
						"name": "Hypselodoris alboterminata",
						"latin": 1999
					},
					{
						"index": 13,
						"name": "Trapania miltabrancha",
						"latin": 2008
					},
					{
						"index": 14,
						"name": "Halgerda albocristata",
						"latin": 1998
					},
					{
						"index": 15,
						"name": "Noumea romeri",
						"latin": 1928
					},
					{
						"index": 16,
						"name": "Facelina rhodopos",
						"latin": 2000
					},
					{
						"index": 17,
						"name": "Jorunna parva",
						"latin": 1938
					},
					{
						"index": 18,
						"name": "Tambja oliva",
						"latin": 1977
					},
					{
						"index": 19,
						"name": "Okenia rosacea",
						"latin": 1905
					},
					{
						"index": 20,
						"name": "Trapania naeva",
						"latin": 2008
					},
					{
						"index": 21,
						"name": "Embletonia gracilis",
						"latin": 1928
					},
					{
						"index": 22,
						"name": "Cerberilla chavezi",
						"latin": 2007
					},
					{
						"index": 23,
						"name": "Phyllidiopsis sphingis",
						"latin": 1993
					},
					{
						"index": 24,
						"name": "Ancula gibbosa",
						"latin": 1818
					},
					{
						"index": 25,
						"name": "Charcotia granulosa",
						"latin": 1906
					},
					{
						"index": 26,
						"name": "Cuthona caerulea",
						"latin": 1804
					},
					{
						"index": 27,
						"name": "Polycera odhneri",
						"latin": 1955
					},
					{
						"index": 28,
						"name": "Thorunna arbuta",
						"latin": 1961
					},
					{
						"index": 29,
						"name": "Limacia cockerelli",
						"latin": 1905
					},
					{
						"index": 30,
						"name": "Acanthodoris rhodoceras",
						"latin": 1905
					},
					{
						"index": 31,
						"name": "Flabellina hamanni",
						"latin": 1994
					},
					{
						"index": 32,
						"name": "Favorinus tsuruganus",
						"latin": 1964
					},
					{
						"index": 33,
						"name": "Anteaeolidiella indica",
						"latin": 1888
					},
					{
						"index": 34,
						"name": "Cumanotus beaumonti",
						"latin": 1906
					},
					{
						"index": 35,
						"name": "Doto ussi",
						"latin": 1982
					},
					{
						"index": 36,
						"name": "Acanthodoris hudsoni",
						"latin": 1905
					},
					{
						"index": 37,
						"name": "Ceratophyllidia papilligera",
						"latin": 1890
					},
					{
						"index": 38,
						"name": "Doto pinnatifida",
						"latin": 1804
					},
					{
						"index": 39,
						"name": "Greilada elegans",
						"latin": 1894
					},
					{
						"index": 40,
						"name": "Crosslandia viridis",
						"latin": 1902
					},
					{
						"index": 41,
						"name": "Dermatobranchus rubidus",
						"latin": 1852
					},
					{
						"index": 42,
						"name": "Flabellina exoptata",
						"latin": 1991
					},
					{
						"index": 43,
						"name": "Mexichromis macropus",
						"latin": 1983
					},
					{
						"index": 44,
						"name": "Roboastra gracilis",
						"latin": 1877
					},
					{
						"index": 45,
						"name": "Lomanotus vermiformis",
						"latin": 1908
					},
					{
						"index": 46,
						"name": "Halgerda stricklandi",
						"latin": 1999
					},
					{
						"index": 47,
						"name": "Tambja stegosauriformis",
						"latin": 2005
					},
					{
						"index": 48,
						"name": "Cadlina sparsa",
						"latin": 1921
					},
					{
						"index": 49,
						"name": "Chromodoris lochi",
						"latin": 1982
					},
					{
						"index": 50,
						"name": "Phyllidiella rosans",
						"latin": 1873
					},
					{
						"index": 51,
						"name": "Chromodoris striatella",
						"latin": 1877
					},
					{
						"index": 52,
						"name": "Polycera abei",
						"latin": 1960
					},
					{
						"index": 53,
						"name": "Felimida punctilucens",
						"latin": 1890
					},
					{
						"index": 54,
						"name": "Felimida sphoni",
						"latin": 1971
					},
					{
						"index": 55,
						"name": "Hypselodoris babai",
						"latin": 2000
					},
					{
						"index": 56,
						"name": "Cerberilla albopunctata",
						"latin": 1976
					},
					{
						"index": 57,
						"name": "Phyllodesmium poindimiei",
						"latin": 1928
					},
					{
						"index": 58,
						"name": "Phestilla lugubris",
						"latin": 1870
					},
					{
						"index": 59,
						"name": "Roboastra arika",
						"latin": 1967
					},
					{
						"index": 60,
						"name": "Plocamopherus lucayensis",
						"latin": 1988
					},
					{
						"index": 61,
						"name": "Goniobranchus fidelis",
						"latin": 1858
					},
					{
						"index": 62,
						"name": "Dirona picta",
						"latin": 1905
					},
					{
						"index": 63,
						"name": "Ceratophyllidia africana",
						"latin": 1903
					},
					{
						"index": 64,
						"name": "Hypselodoris nigrostriata",
						"latin": 1904
					},
					{
						"index": 65,
						"name": "Polycera chilluna",
						"latin": 1961
					},
					{
						"index": 66,
						"name": "Herviella mietta",
						"latin": 1965
					},
					{
						"index": 67,
						"name": "Glaucus atlanticus",
						"latin": 1777
					},
					{
						"index": 68,
						"name": "Notobryon wardi",
						"latin": 1936
					},
					{
						"index": 69,
						"name": "Taringa halgerda",
						"latin": 1998
					},
					{
						"index": 70,
						"name": "Sakuraeolis kirembosa",
						"latin": 1980
					},
					{
						"index": 71,
						"name": "Reticulidia fungia",
						"latin": 1993
					},
					{
						"index": 72,
						"name": "Felimare fontandraui",
						"latin": 1951
					},
					{
						"index": 73,
						"name": "Phyllodesmium kabiranum",
						"latin": 1991
					},
					{
						"index": 74,
						"name": "Noumea verconis",
						"latin": 1905
					},
					{
						"index": 75,
						"name": "Pauleo jubatus",
						"latin": 1992
					},
					{
						"index": 76,
						"name": "Chromodoris elisabethina",
						"latin": 1877
					},
					{
						"index": 77,
						"name": "Tambja eliora",
						"latin": 1967
					},
					{
						"index": 78,
						"name": "Dondice occidentalis",
						"latin": 1925
					},
					{
						"index": 79,
						"name": "Tambja fantasmalis",
						"latin": 1986
					},
					{
						"index": 80,
						"name": "Flabellina trophina",
						"latin": 1890
					},
					{
						"index": 81,
						"name": "Phyllodesmium rudmani",
						"latin": 2006
					},
					{
						"index": 82,
						"name": "Janolus ignis",
						"latin": 1986
					},
					{
						"index": 83,
						"name": "Cuthona yamasui",
						"latin": 1993
					},
					{
						"index": 84,
						"name": "Halgerda tessellata",
						"latin": 1880
					},
					{
						"index": 85,
						"name": "Bornella stellifer",
						"latin": 1848
					},
					{
						"index": 86,
						"name": "Goniobranchus roboi",
						"latin": 1998
					},
					{
						"index": 87,
						"name": "Doriprismatica tibboeli",
						"latin": 2005
					},
					{
						"index": 88,
						"name": "Tambja gratiosa",
						"latin": 1890
					},
					{
						"index": 89,
						"name": "Flabellina cynara",
						"latin": 1967
					},
					{
						"index": 90,
						"name": "Polycera capensis",
						"latin": 1824
					},
					{
						"index": 91,
						"name": "Janolus cristatus",
						"latin": 1841
					},
					{
						"index": 92,
						"name": "Jason mirabilis",
						"latin": 1974
					},
					{
						"index": 93,
						"name": "Gymnodoris striata",
						"latin": 1908
					},
					{
						"index": 94,
						"name": "Chromodoris magnifica",
						"latin": 1832
					},
					{
						"index": 95,
						"name": "Jorunna pardus",
						"latin": 1981
					},
					{
						"index": 96,
						"name": "Hypselodoris bennetti",
						"latin": 1864
					},
					{
						"index": 97,
						"name": "Glossodoris cincta",
						"latin": 1888
					},
					{
						"index": 98,
						"name": "Leminda millecra",
						"latin": 1985
					},
					{
						"index": 99,
						"name": "Dendrodoris guttata",
						"latin": 1917
					},
					{
						"index": 100,
						"name": "Phyllidia coelestis",
						"latin": 1905
					},
					{
						"index": 101,
						"name": "Goniobranchus coi",
						"latin": 1956
					},
					{
						"index": 102,
						"name": "Gymnodoris impudica",
						"latin": 1830
					},
					{
						"index": 103,
						"name": "Goniobranchus geminus",
						"latin": 1987
					},
					{
						"index": 104,
						"name": "Felimare acriba",
						"latin": 1967
					},
					{
						"index": 105,
						"name": "Ardeadoris rubroannulata",
						"latin": 1986
					},
					{
						"index": 106,
						"name": "Dendrodoris krusensternii",
						"latin": 1850
					},
					{
						"index": 107,
						"name": "Atagema alba",
						"latin": 1927
					},
					{
						"index": 108,
						"name": "Goniobranchus geometricus",
						"latin": 1928
					},
					{
						"index": 109,
						"name": "Hypselodoris paulinae",
						"latin": 1999
					},
					{
						"index": 110,
						"name": "Dendrodoris krebsii",
						"latin": 1863
					},
					{
						"index": 111,
						"name": "Discodoris boholiensis",
						"latin": 1877
					},
					{
						"index": 112,
						"name": "Cerberilla affinis",
						"latin": 1888
					},
					{
						"index": 113,
						"name": "Marionia elongoreticulata",
						"latin": 2007
					},
					{
						"index": 114,
						"name": "Miamira sinuatum",
						"latin": 1824
					},
					{
						"index": 115,
						"name": "Reticulidia halgerda",
						"latin": 1990
					},
					{
						"index": 116,
						"name": "Spurilla neapolitana",
						"latin": 1841
					},
					{
						"index": 117,
						"name": "Tambja morosa",
						"latin": 1877
					},
					{
						"index": 118,
						"name": "Hermissenda crassicornis",
						"latin": 1831
					},
					{
						"index": 119,
						"name": "Felimare ghiselini",
						"latin": 1978
					},
					{
						"index": 120,
						"name": "Tritoniella belli",
						"latin": 1907
					},
					{
						"index": 121,
						"name": "Dermatobranchus ornatus",
						"latin": 1874
					},
					{
						"index": 122,
						"name": "Chromodoris kuiteri",
						"latin": 1982
					},
					{
						"index": 123,
						"name": "Phyllidia ocellata",
						"latin": 1804
					},
					{
						"index": 124,
						"name": "Halgerda willeyi",
						"latin": 1904
					},
					{
						"index": 125,
						"name": "Nembrotha megalocera",
						"latin": 1990
					},
					{
						"index": 126,
						"name": "Flabellina japonica",
						"latin": 1937
					},
					{
						"index": 127,
						"name": "Phyllidiopsis krempfi",
						"latin": 1957
					},
					{
						"index": 128,
						"name": "Plocamopherus imperialis",
						"latin": 1864
					},
					{
						"index": 129,
						"name": "Diaulula sandiegensis",
						"latin": 1863
					},
					{
						"index": 130,
						"name": "Dendrodoris nigra",
						"latin": 1855
					},
					{
						"index": 131,
						"name": "Nembrotha milleri",
						"latin": 1997
					},
					{
						"index": 132,
						"name": "Felimare agassizii",
						"latin": 1894
					},
					{
						"index": 133,
						"name": "Doriopsilla spaldingi",
						"latin": 1998
					},
					{
						"index": 134,
						"name": "Aegires serenae",
						"latin": 1997
					},
					{
						"index": 135,
						"name": "Miamira alleni",
						"latin": 1996
					},
					{
						"index": 136,
						"name": "Tritonia bollandi",
						"latin": 2003
					},
					{
						"index": 137,
						"name": "Felimida baumanni",
						"latin": 1970
					},
					{
						"index": 138,
						"name": "Felimare californiensis",
						"latin": 1879
					},
					{
						"index": 139,
						"name": "Melibe leonina",
						"latin": 1852
					},
					{
						"index": 140,
						"name": "Nembrotha rosannulata",
						"latin": 2008
					},
					{
						"index": 141,
						"name": "Hypselodoris tryoni",
						"latin": 1873
					},
					{
						"index": 142,
						"name": "Platydoris cruenta",
						"latin": 1832
					},
					{
						"index": 143,
						"name": "Nembrotha chamberlaini",
						"latin": 1997
					},
					{
						"index": 144,
						"name": "Aeolidia papillosa",
						"latin": 1761
					},
					{
						"index": 145,
						"name": "Goniobranchus annulatus",
						"latin": 1904
					},
					{
						"index": 146,
						"name": "Hypselodoris apolegma",
						"latin": 2001
					},
					{
						"index": 147,
						"name": "Aegires gardineri",
						"latin": 1906
					},
					{
						"index": 148,
						"name": "Jorunna funebris",
						"latin": 1859
					},
					{
						"index": 149,
						"name": "Flabellina iodinea",
						"latin": 1863
					},
					{
						"index": 150,
						"name": "Miamira miamirana",
						"latin": 1875
					},
					{
						"index": 151,
						"name": "Goniobranchus collingwoodi",
						"latin": 1987
					},
					{
						"index": 152,
						"name": "Doriprismatica atromarginata",
						"latin": 1804
					},
					{
						"index": 153,
						"name": "Bonisa nakaza",
						"latin": 1981
					},
					{
						"index": 154,
						"name": "Tambja sagamiana",
						"latin": 1955
					},
					{
						"index": 155,
						"name": "Scyllaea pelagica",
						"latin": 1758
					},
					{
						"index": 156,
						"name": "Armina Californica",
						"latin": 1863
					},
					{
						"index": 157,
						"name": "Nembrotha yonowae",
						"latin": 1992
					},
					{
						"index": 158,
						"name": "Lomanotus genei",
						"latin": 1846
					},
					{
						"index": 159,
						"name": "Gymnodoris aurita",
						"latin": 1852
					},
					{
						"index": 160,
						"name": "Nembrotha cristata",
						"latin": 1877
					},
					{
						"index": 161,
						"name": "Baeolidia moebii",
						"year": 1888
					},
					{
						"index": 162,
						"name": "Phyllidia varicosa",
						"year": 1801
					},
					{
						"index": 163,
						"name": "Miamira moloch",
						"year": 1988
					},
					{
						"index": 164,
						"name": "Nembrotha kubaryana",
						"year": 1877
					},
					{
						"index": 165,
						"name": "Halgerda okinawa",
						"year": 2000
					},
					{
						"index": 166,
						"name": "Phyllidiopsis shireenae",
						"year": 1990
					},
					{
						"index": 167,
						"name": "Hypselodoris pulchella",
						"year": 1830
					},
					{
						"index": 168,
						"name": "Glossodoris hikuerensis",
						"year": 1954
					},
					{
						"index": 169,
						"name": "Ardeadoris egretta",
						"year": 1984
					},
					{
						"index": 170,
						"name": "Felimare zebra",
						"year": 1889
					},
					{
						"index": 171,
						"name": "Ceratosoma tenue",
						"year": 1876
					},
					{
						"index": 172,
						"name": "Dendrodoris warta",
						"year": 1976
					},
					{
						"index": 173,
						"name": "Gymnodoris ceylonica",
						"year": 1858
					},
					{
						"index": 174,
						"name": "Dermatobranchus gonatophora",
						"year": 1824
					},
					{
						"index": 175,
						"name": "Marionia rubra",
						"year": 1828
					},
					{
						"index": 176,
						"name": "Doriprismatica stellata",
						"year": 1986
					},
					{
						"index": 177,
						"name": "Tambja verconis",
						"year": 1905
					},
					{
						"index": 178,
						"name": "Kalinga ornata",
						"year": 1864
					},
					{
						"index": 179,
						"name": "Felimare picta",
						"year": 1836
					},
					{
						"index": 180,
						"name": "Aegires minor",
						"year": 1904
					},
					{
						"index": 181,
						"name": "Doris montereyensis",
						"year": 1863
					},
					{
						"index": 182,
						"name": "Triopha catalinae",
						"year": 1863
					},
					{
						"index": 183,
						"name": "Phyllodesmium longicirrum",
						"year": 1905
					},
					{
						"index": 184,
						"name": "Armina variolosa",
						"year": 1904
					},
					{
						"index": 185,
						"name": "Bornella anguilla",
						"year": 1984
					},
					{
						"index": 186,
						"name": "Dendrodoris albopurpura",
						"year": 1957
					},
					{
						"index": 187,
						"name": "Platydoris formosa",
						"year": 1864
					},
					{
						"index": 188,
						"name": "Pteraeolidia ianthina",
						"year": 1864
					},
					{
						"index": 189,
						"name": "Ceratosoma brevicaudatum",
						"year": 1876
					},
					{
						"index": 190,
						"name": "Triopha maculata",
						"year": 1905
					},
					{
						"index": 191,
						"name": "Jorunna rubescens",
						"year": 1876
					},
					{
						"index": 192,
						"name": "Dendrodoris tuberculosa",
						"year": 1832
					},
					{
						"index": 193,
						"name": "Dirona albolineata",
						"year": 1905
					},
					{
						"index": 194,
						"name": "Dendrodoris rainfordi",
						"year": 1932
					},
					{
						"index": 195,
						"name": "Tambja gabrielae",
						"year": 2005
					},
					{
						"index": 196,
						"name": "Armina papillata",
						"year": 1933
					},
					{
						"index": 197,
						"name": "Doris odhneri",
						"year": 1966
					},
					{
						"index": 198,
						"name": "Doris wellingtonensis",
						"year": 1877
					},
					{
						"index": 199,
						"name": "Dendronotus iris",
						"year": 1863
					},
					{
						"index": 200,
						"name": "Tochuina tetraquetra",
						"year": 1788
					},
					{
						"index": 201,
						"name": "Dendronotus rufus",
						"year": 1921
					},
					{
						"index": 202,
						"name": "Asteronotus cespitosus",
						"year": 1824
					},
					{
						"index": 203,
						"name": "Hexabranchus sanguineus",
						"year": 1830
					},
					{
						"index": 204,
						"name": "Melibe viridis",
						"year": 1858
					},
					{
						"index": 205,
						"name": "Tochuina tetraquetra",
						"year": 1788
					},
					{
						"index": 206,
						"name": "Roboastra tigris",
						"year": 1978
					}
				]
			}]
		},
		{
			slug: 'insects-and-bugs',
			paintings: [
				{
					slug: 'edibles',
					key: [{
							"index": 1,
							"name": "Golden mantis",
							"latin": "Hierodula venosa"
						},
						{
							"index": 2,
							"name": "Rice ear bug",
							"latin": "Leptocorisa oratorio"
						},
						{
							"index": 3,
							"name": "Stick insect",
							"latin": "Haaniella grayi"
						},
						{
							"index": 4,
							"name": "Australian golden orb weaver",
							"latin": "Trichonephila edulis"
						},
						{
							"index": 5,
							"name": "Silk moth",
							"latin": "Bombyx mori"
						},
						{
							"index": 6,
							"name": "Thorn hopper",
							"latin": "Umbonia spinosa"
						},
						{
							"index": 7,
							"name": "Globe wanderer",
							"latin": "Pantala fravescens"
						},
						{
							"index": 8,
							"name": "Cerambycid beetle",
							"latin": "Threnetica lacrymans"
						},
						{
							"index": 9,
							"name": "Christmas beetle",
							"latin": "Anoplognathus viridiaeneus"
						},
						{
							"index": 10,
							"name": "Scarab",
							"latin": "Chrysina macropus"
						},
						{
							"index": 11,
							"name": "Owl butterfly",
							"latin": "Caligo memnon"
						},
						{
							"index": 12,
							"name": "Stick insect",
							"latin": "Eurycantha versirubra"
						},
						{
							"index": 13,
							"name": "Giant honey bee",
							"latin": "Apis dorsata"
						},
						{
							"index": 14,
							"name": "Teak caterpillar",
							"latin": "Hyblaea puera"
						},
						{
							"index": 15,
							"name": "Death’s head moth",
							"latin": "Acherontia lachesis"
						},
						{
							"index": 16,
							"name": "Fulgid",
							"latin": "Zanna tenebrosa"
						},
						{
							"index": 17,
							"name": "Scarlet skimmer",
							"latin": "Crocothemis servilia"
						},
						{
							"index": 18,
							"name": "Cerambycid beetle",
							"latin": "Psacothea hilaris"
						},
						{
							"index": 19,
							"name": "Bogong moth",
							"latin": "Agrotis infusa"
						},
						{
							"index": 20,
							"name": "Madagascan weevil",
							"latin": "Eugnoristus monachus"
						},
						{
							"index": 21,
							"name": "Dark kite swallowtail",
							"latin": "Protographium philolaus"
						},
						{
							"index": 22,
							"name": "Giant mesquite bug",
							"latin": "Thasus gigas"
						},
						{
							"index": 23,
							"name": "Atlas beetle",
							"latin": "Chalcosoma atlas"
						},
						{
							"index": 24,
							"name": "Harlequin beetle",
							"latin": "Acrocinus longimanus"
						},
						{
							"index": 25,
							"name": "Honey ant",
							"latin": "Melophorus bagoti"
						},
						{
							"index": 26,
							"name": "Mealworm",
							"latin": "Tenebrio molitor"
						},
						{
							"index": 27,
							"name": "European migratory locust",
							"latin": "Locusta migratoria"
						},
						{
							"index": 28,
							"name": "House cricket",
							"latin": "Acheta domesticus"
						},
						{
							"index": 29,
							"name": "Burrowing cricket",
							"latin": "Brachytrupes membranaceus"
						},
						{
							"index": 30,
							"name": "Asian forest scorpion",
							"latin": "Heterometrus longimanus"
						},
						{
							"index": 31,
							"name": "Tree hopper",
							"latin": "Lawana imitata"
						},
						{
							"index": 32,
							"name": "Mediterranean field cricket",
							"latin": "Gryllus bimaculatus"
						},
						{
							"index": 33,
							"name": "Shield bug",
							"latin": "Udonga montana"
						},
						{
							"index": 34,
							"name": "Red palm weevil",
							"latin": "Rhynchophorus ferrugineus"
						},
						{
							"index": 35,
							"name": "False leaf katydid",
							"latin": "Pseudophyllus titan"
						},
						{
							"index": 36,
							"name": "Japanese stag",
							"latin": "Prosopocoilis inclinatus"
						},
						{
							"index": 37,
							"name": "Witchetty grub",
							"latin": "Endoxyla leucomochia"
						},
						{
							"index": 38,
							"name": "Inflated stink bug",
							"latin": "Encosternum delegorguei"
						},
						{
							"index": 39,
							"name": "Elephant head hawk moth",
							"latin": "Deilephila elpenor"
						},
						{
							"index": 40,
							"name": "Japanese hornet",
							"latin": "Vespa mandarinia mandarinia"
						},
						{
							"index": 41,
							"name": "Weaver ant",
							"latin": "Oecophylla smaragdina"
						},
						{
							"index": 42,
							"name": "African mole cricket",
							"latin": "Gryllotalpa africana"
						},
						{
							"index": 43,
							"name": "Shield bug",
							"latin": "Tessaratoma quadrata"
						},
						{
							"index": 44,
							"name": "Goliath birdeater",
							"latin": "Theraphosa blondi"
						},
						{
							"index": 45,
							"name": "Sugarcane grub",
							"latin": "Lepidiota stigma"
						},
						{
							"index": 46,
							"name": "Death’s head roach",
							"latin": "Blaberus craniifer"
						},
						{
							"index": 47,
							"name": "Scarab",
							"latin": "Mimela linpingi"
						},
						{
							"index": 48,
							"name": "Plant sucker",
							"latin": "Anoplocnemis phasiana"
						},
						{
							"index": 49,
							"name": "Green jewel",
							"latin": "Sternocera aequisignata"
						},
						{
							"index": 50,
							"name": "Weevil",
							"latin": "Crytotachelus buqueti"
						},
						{
							"index": 51,
							"name": "Mopane worm",
							"latin": "Gonimbrasia belina"
						},
						{
							"index": 52,
							"name": "Tequila skipper",
							"latin": "Aegiale hesperiaris"
						},
						{
							"index": 53,
							"name": "Buprestid",
							"latin": "Chalcophora yunnana"
						},
						{
							"index": 54,
							"name": "Tropical carpenter bee",
							"latin": "Xylocopa latipes"
						},
						{
							"index": 55,
							"name": "Scarab",
							"latin": "Agestrata orichalca"
						},
						{
							"index": 56,
							"name": "Tree hopper",
							"latin": "Umbonia reclinata"
						},
						{
							"index": 57,
							"name": "Sugarcane stemborer",
							"latin": "Dorysthenes buqueti"
						},
						{
							"index": 58,
							"name": "Stink bug",
							"latin": "Nezara viridula"
						},
						{
							"index": 59,
							"name": "Six o’clock cicada",
							"latin": "Megapomponia merula"
						},
						{
							"index": 60,
							"name": "Zebra legged tarantula",
							"latin": "Cyriopagopus albostriatus"
						},
						{
							"index": 61,
							"name": "Harlequin roach",
							"latin": "Neostylopyga rhombifolia"
						},
						{
							"index": 62,
							"name": "Dobson fly",
							"latin": "Acanthacorydalis orientalis"
						},
						{
							"index": 63,
							"name": "Giant Buprestid",
							"latin": "Euchroma gigantea"
						},
						{
							"index": 64,
							"name": "Three-horned Rhino",
							"latin": "Chalcosoma moellenkampi"
						},
						{
							"index": 65,
							"name": "Giant acacia click beetle",
							"latin": "Tetralobus flabellicornis"
						},
						{
							"index": 66,
							"name": "Giant forest ant",
							"latin": "Camponotus gigas"
						}
					]
				}
			]
		},
		{
			slug: 'nova',
			paintings: [{
				slug: 'canopy',
				keyAddition: '* Taxonomy uncertain',
				key: [{
						"index": 1,
						"name": "Orchid",
						"latin": "Sobralia kerryae (Costa Rica, 1998)"
					},
					{
						"index": 2,
						"name": "Glass frog",
						"latin": "Nymphargus wileyi (Ecuador, 2006)"
					},
					{
						"index": 3,
						"name": "Mishana tyrannulet",
						"latin": "Zimmerius villarejoi (Peru, 2001)"
					},
					{
						"index": 4,
						"name": "Butterfly",
						"latin": "Lymanopoda nadia (Ecuador, 1998)"
					},
					{
						"index": 5,
						"name": "Hairstreak butterfly",
						"latin": "Chrysozephyrus obscurus (China, 2002)"
					},
					{
						"index": 6,
						"name": "Butterfly",
						"latin": "Penaincisalia caeruleanota (Ecuador, 2005)"
					},
					{
						"index": 7,
						"name": "Bromeliad",
						"latin": "Aechmea andersonii (Brazil, 1998)"
					},
					{
						"index": 8,
						"name": "Gomez’s web-footed salamander",
						"latin": "Bolitoglossa gomezi (Costa Rica/Panama, 2007)"
					},
					{
						"index": 9,
						"name": "Cryptic forest-falcon",
						"latin": "Micrastur mintoni (Brazil, 2002)"
					},
					{
						"index": 10,
						"name": "Acaro’s masdevallia",
						"latin": "Masdevallia acaroi (Ecuador, 2003)"
					},
					{
						"index": 11,
						"name": "Butterfly",
						"latin": "Penaincisalia andreae (Ecuador, 2005)"
					},
					{
						"index": 12,
						"name": "Orchid",
						"latin": "Holcoglossum sinicum (China, 1987)"
					},
					{
						"index": 13,
						"name": "Orchid bee",
						"latin": "Euglossa anodorhynchi (Brazil, 2006)"
					},
					{
						"index": 14,
						"name": "Gorgeted puffleg",
						"latin": "Eriocnemis isabellae (Columbia, 2007)",
						facts: "Three to five new birds, as well as around ten new animals, are documented each year. This flamboyantly colored hummingbird was first sighted in Colombia’s cloud forest in 2005, when two ornithologists went there on a hunch. Because a new species of plant had been found in the area, they hoped to make new observations, perhaps expand the range of known birds or make new observations about breeding or eating habits. Fulfilling their wildest hopes, they discovered a spectacular new hummingbird species. The gorgeted puffleg is critically endangered by the INCN but needs actual protection, as major threats to its habitat exist: increased coca cultivation in particular and slash-and-burn agriculture in general. To have gone unseen for so long, this tiny bird’s range must be small indeed, and the species could vanish in a flash."
					},
					{
						"index": 15,
						"name": "Bromeliad",
						"latin": "Guzmania scandens (Costa Rica, 1996)"
					},
					{
						"index": 16,
						"name": "Brame’s web-footed salamander",
						"latin": "Bolitoglossa bramei (Costa Rica/Panama, 2007)"
					},
					{
						"index": 17,
						"name": "Ant",
						"latin": "Myrmicine genus 1 (as yet unnamed) (Madagascar) (New genus)"
					},
					{
						"index": 18,
						"name": "Tiger huntsman",
						"latin": "Heteropodidae sp. nov. (as yet unnamed) (Australia)"
					},
					{
						"index": 19,
						"name": "Posadas’ paphinia",
						"latin": "Paphinia posadarum (Columbia; Ecuador, 1993)"
					},
					{
						"index": 20,
						"name": "Metalmark butterfly",
						"latin": "Mesene citrinella (Ecuador, 1995)"
					},
					{
						"index": 21,
						"name": "Orchid",
						"latin": "Bulbophyllum habrotinum (Borneo, 1994)"
					},
					{
						"index": 22,
						"name": "Anole",
						"latin": "Anolis datzorum (Panama, 2007)"
					},
					{
						"index": 23,
						"name": "Orchid",
						"latin": "Aerangis punctata (Madagascar, 1986)"
					},
					{
						"index": 24,
						"name": "Butterfly",
						"latin": "Euselasia palla (Ecuador, 1998)"
					},
					{
						"index": 25,
						"name": "Chinese dormouse",
						"latin": "Chaetocauda sichuanensis (China, 1985)"
					},
					{
						"index": 26,
						"name": "Butterfly",
						"latin": "Jemadia BURNS 01 (as yet unnamed) (Costa Rica)"
					},
					{
						"index": 27,
						"name": "Swan orchid",
						"latin": "Cycnoches herrenhusanum (Colombia, 1991)"
					},
					{
						"index": 28,
						"name": "Butterfly",
						"latin": "Lymanopoda caracara (Ecuador, 1998)"
					},
					{
						"index": 29,
						"name": "Mapwing butterfly",
						"latin": "Hypanartia christophori (Ecuador, Peru, 1998)"
					},
					{
						"index": 30,
						"name": "Togian white-eye",
						"latin": "Zosterops somadikartai (Indonesia, 2008)"
					},
					{
						"index": 31,
						"name": "Butterfly",
						"latin": "Penaincisalia juliae (Ecuador, 2005)"
					},
					{
						"index": 32,
						"name": "Scarab",
						"latin": "Chrysina curoei (Costa Rica, 1992)"
					},
					{
						"index": 33,
						"name": "Orchid",
						"latin": "Ascocentrum christensonianae (Vietnam, 1993)"
					},
					{
						"index": 34,
						"name": "Christenson’s macroclinium",
						"latin": "Macroclinium christensonii (Peru, 1994)"
					},
					{
						"index": 35,
						"name": "Ancient antwren",
						"latin": "Herpsilochmus gentryi (Peru, 1998)"
					},
					{
						"index": 36,
						"name": "Orchid",
						"latin": "Telipigon jostii (Ecuador, 2004)"
					},
					{
						"index": 37,
						"name": "Orchid (new genus)",
						"latin": "Ophioglossella chrysostoma (Papua New Guinea, 1998)"
					},
					{
						"index": 38,
						"name": "Masdevallia",
						"latin": "Masdevallia calocalix (Ecuador, 2002)"
					},
					{
						"index": 39,
						"name": "Masdevallia",
						"latin": "Masdevallia amoena (Ecuador, 1999)"
					},
					{
						"index": 40,
						"name": "Butterfly",
						"latin": "Heliconius tristero (Columbia, 1996)"
					},
					{
						"index": 41,
						"name": "Chestnut-eared laughing thrush",
						"latin": "Garrulax konkakinhensis (Vietnam, 2001)"
					},
					{
						"index": 42,
						"name": "Jewel scarab",
						"latin": "Chrysina cavei (Honduras, 1999)",
						facts: "Beetles have been chewing, digging, and flying for 230 million years, and represent a fifth of all known living organisms and a fourth of all animals in the world. To date 350,000 species have been described. Most beetles, like Chrysina cavei live in a relatively small, restricted area. However, members of the entire order Coleoptera—as beetles are known in scientific circles— are found nearly everywhere, from the fringes of the polar icecaps to the rainforest pictured here. They epitomize diversity, in that they eat nearly everything and contribute to the health of the biosphere as pollinators, decomposers, and spores transporters from place to place. And, of course, their extraordinary beauty has made them a source of fascination for mankind for thousands of years."
					},
					{
						"index": 43,
						"name": "Chapada flycatcher",
						"latin": "Suiriri islerorum (Brazil, 2001)"
					},
					{
						"index": 44,
						"name": "Serendib scops -owl",
						"latin": "Otus thilohoffmanni (Sri Lanka, 2004)"
					},
					{
						"index": 45,
						"name": "Jewel scarab",
						"latin": "Chrysina spectabilis (Honduras, 1992)"
					},
					{
						"index": 46,
						"name": "Lichen",
						"latin": "Erioderma barbellatum (Costa Rica; Bolivia; Peru, 2002)",
						facts: "Key to any canopy are dense mats of vegetation composed of mosses, liverworts, hornworts and lichens as you see on branches here. In a practice that could be described as bio-mining, compounds have been extracted from some of these species, with applications that range from medical to industrial. At least one recently described species of lichen is included here: Erioderma barbellatum. Canopy science is still in its infancy, so new species are identified often and there is still much to learn. We do know that moss mats act like sponges; and hold many gallons of water, sustaining the tree as well as many other forms of life in the canopy: orchids, worms, frogs, insects, lizards, salamanders, and hosts of microscopic organisms."
					},
					{
						"index": 47,
						"name": "Orchid (new genus)",
						"latin": "Calyptrochilum aurantiacum (Cameroon, 1988)"
					},
					{
						"index": 48,
						"name": "Long-horned beetle",
						"latin": "Hovorelus splendidus (Peru, 2003)"
					},
					{
						"index": 49,
						"name": "La Nevera thick anole",
						"latin": "Anolis pseudopachypus (Panama, 2007)"
					},
					{
						"index": 50,
						"name": "Del Hierro’s lepanthes",
						"latin": "Lepanthes delhierroi (Ecuador, 1993)"
					},
					{
						"index": 51,
						"name": "Mapwing butterfly",
						"latin": "Hypanartia cinderella (Venezuela to Ecuador, 2001)"
					},
					{
						"index": 52,
						"name": "Hainan leaf warbler",
						"latin": "Phylloscopus hainanus (China, 1993)"
					},
					{
						"index": 53,
						"name": "Beetle",
						"latin": "Acalodegma vidali (Chile, 2002)"
					},
					{
						"index": 54,
						"name": "Orchid",
						"latin": "Ceratocentron fesselii (Philippines, 1989)"
					},
					{
						"index": 55,
						"name": "Madidi titi",
						"latin": "Plecturocelous aureipalatii (Bolivia, 2006)"
					},
					{
						"index": 56,
						"name": "Butterfly (subspecies)",
						"latin": "Morpho godfreyi ssp. pinratanai (Thailand, 2007)"
					},
					{
						"index": 57,
						"name": "Orchid color-changing restrepia",
						"latin": "Restrepia chameleon (Columbia, 1996)"
					},
					{
						"index": 58,
						"name": "Munchigue wood-wren",
						"latin": "Henicorhina negreti (Columbia, 2003)"
					},
					{
						"index": 59,
						"name": "Orchid",
						"latin": "Dendrobium lueckelianum (Thailand, 1990)"
					},
					{
						"index": 60,
						"name": "Renanthera",
						"latin": "Renanthera citrina (Vietnam, 1997)"
					},
					{
						"index": 61,
						"name": "Butterfly",
						"latin": "Penaincisalia ismaeli (Ecuador, 2005)"
					},
					{
						"index": 62,
						"name": "Butterfly",
						"latin": "Argyrogrammana aparamilla (Ecuador, 1995)"
					},
					{
						"index": 63,
						"name": "Mouse (new genus)",
						"latin": "(as yet unnamed) (Philippines)",
						facts: "This mouse represents not only a new species, but probably a new genus as well—something that is truly newsworthy. The story of this discovery came out in Science News in 2004, but it can take from one to six years for a professional journal article to be published, which will officially establish the mouse’s credentials. In order to prepare this article, the describing scientist will have to differentiate this new mouse from all other mice, by comparing it to dozens of specimens, photographs, and drawings in the collections of natural history museums around the world. Because this mouse probably represents a new genus also, a very different branch on the tree of life, the preparation can take even longer."
					},
					{
						"index": 64,
						"name": "Treetop jewel tarantula",
						"latin": "Avicularia geroldi (Brazil, 1999)"
					},
					{
						"index": 65,
						"name": "Orchid",
						"latin": "Amesiella monticola (Philippines, 1998)"
					},
					{
						"index": 66,
						"name": "Butterfly",
						"latin": "Melanopyge Burns 01 (as yet unnamed) (Costa Rica)"
					},
					{
						"index": 67,
						"name": "Grammitid fern",
						"latin": "Grammitis tunquiniensis (Bolivia, 2008)"
					},
					{
						"index": 68,
						"name": "Araripe manakin",
						"latin": "Antilophia bokermanni (Brazil, 1998)"
					},
					{
						"index": 69,
						"name": "Rigid tail masdevallia",
						"latin": "Masdevallia crassicaudis (Ecuador, 1999)"
					},
					{
						"index": 70,
						"name": "Jewel scarab",
						"latin": "Chrysina tapantina (Costa Rica, 1992)"
					},
					{
						"index": 71,
						"name": "Ant (new genus)",
						"latin": "Myrmicine genus 2 (as yet unnamed) (Madagascar)"
					},
					{
						"index": 72,
						"name": "Orchid",
						"latin": "Bulbophyllum facetum (Philippines, 1996)"
					},
					{
						"index": 73,
						"name": "Mustache Orchid",
						"latin": "Aerangis splendida (Malawi; Zambia, formally described in 1987, though previously known)"
					},
					{
						"index": 74,
						"name": "Rio San Felix anole",
						"latin": "Anolis gruuo (Panama, 2007)"
					},
					{
						"index": 75,
						"name": "Orchid",
						"latin": "Vanda garayi (Malaysia; Vietnam, formally described in 1992, though previously known)"
					},
					{
						"index": 76,
						"name": "Butterfly",
						"latin": "Phocides Warren O1 (as yet unnamed) (Costa Rica)"
					},
					{
						"index": 77,
						"name": "Riodinid butterfly (new genus)",
						"latin": "Machaya obstinata (Ecuador, 1995)"
					},
					{
						"index": 78,
						"name": "Riodinid butterfly",
						"latin": "Anteros nubosus (Ecuador, 1995)"
					},
					{
						"index": 79,
						"name": "Sulphur-breasted parakeet",
						"latin": "Aratinga maculata (Brazil, 2005)"
					},
					{
						"index": 80,
						"name": "Orchid",
						"latin": "Phalaenopsis finley (Thailand; Burma, 1986/1988; date in dispute)"
					},
					{
						"index": 81,
						"name": "Orchid",
						"latin": "Dendrobium tapiniense (Papua New Guinea, 1980)"
					},
					{
						"index": 82,
						"name": "Masdevallia",
						"latin": "Masdevallia ximenae (Ecuador, 1991)"
					},
					{
						"index": 83,
						"name": "Ladder-like maxillaria",
						"latin": "Maxillaria scalariformis (Panama, 1999)"
					},
					{
						"index": 84,
						"name": "Tentacled lepanthes",
						"latin": "Lepanthes tentaculata (Ecuador, 1996)",
						facts: "Orchids are well adapted to life in the canopy. Their roots have a large surface area that facilitates the rapid absorption of water and nutrients, and secondary stems store additional moisture, to withstand dry spells. The success of orchids in the rainforest is due in part to the way they reproduce. Each generates hundreds of thousands of tiny seeds, which are dispersed by wind currents over great areas, propelled by their balloon-like seed coatings. There are 15 micro-orchids in this picture, many of them Pleurothallids. This sub-group of the orchid family is exclusively found in the New World—primarily the cloud forests of Central and South America. Dr. Carl Luer made the study of this group of more than 4,000 species his life’s work. Prior to his death he published 29 volumes of Icones Plurotheallidinarum."
					},
					{
						"index": 85,
						"name": "Brown-backed antwren",
						"latin": "Epinecrphylla fjeldsaai (Ecuador; Peru, 1999)"
					},
					{
						"index": 86,
						"name": "Red-lobed orchid",
						"latin": "Cattleya sanguiloba (Brazil, 1990)"
					},
					{
						"index": 87,
						"name": "Butterfly",
						"latin": "Megeuptychia monopunctata (Ecuador, 1995)"
					},
					{
						"index": 88,
						"name": "Zarciadero web-footed salamander",
						"latin": "Bolitoglossa oresbia (Honduras, 2005)"
					},
					{
						"index": 89,
						"name": "Butterfly",
						"latin": "Nascus BURNS 01 (as yet unnamed) (Costa Rica)"
					},
					{
						"index": 90,
						"name": "Mindoro stripe-faced fruit bat",
						"latin": "Styloctenium mindorensis (Philippines, 2007)",
						facts: "This species of bat eats the fruit and nectar of tropical flowering plants. Many people are frightened of these creatures and think of them as ugly or malevolent, but, like all other species, they carry out an important ecological function. Since their search for food takes them to many blossoms during a single night, fruit bats are major pollinators. First detected in 2006, thousands of bats died of a mysterious fungus related disease in caves located in upstate New York. Biologists now recogonize this malady as white nose syndrome, a fungal disease from Eurasia that has killed millions of bats in North America. The fungus wakes the bats up from hibernation, when food is scarce, and burn their fat reserves."
					},
					{
						"index": 91,
						"name": "Butterfly",
						"latin": "Delias kristianiae (Irian Jaya, Indonesia, 2006)"
					},
					{
						"index": 92,
						"name": "Orchid",
						"latin": "Phalaenopsis doweryensis (Borneo, 2001)"
					},
					{
						"index": 93,
						"name": "Weevil",
						"latin": "Eupholus vlasimskii (Papua New guinea, 2002)"
					},
					{
						"index": 94,
						"name": "Butterfly",
						"latin": "Idioneurula donegani (Columbia, 2007)"
					},
					{
						"index": 95,
						"name": "Orchid (new genus)",
						"latin": "Vanda vietnamica (Vietnam, 1993)"
					},
					{
						"index": 96,
						"name": "Jose’s porroglossum",
						"latin": "Porroglossum josei (Ecuador, 1995)"
					},
					{
						"index": 97,
						"name": "Grammitid fern",
						"latin": "Grammitis setosora (Bolivia, 2008)"
					},
					{
						"index": 98,
						"name": "Chestnut-capped piha",
						"latin": "Lipaugus weberi (Columbia, 2001)"
					},
					{
						"index": 99,
						"name": "Bromeliad",
						"latin": "Guzmania cinnabarina (Panama, 2006)"
					},
					{
						"index": 100,
						"name": "Sandalwood tree",
						"latin": "Acanthosyris annonagustata (Ecuador, 1998)"
					},
					{
						"index": 101,
						"name": "Cerambycid beetle",
						"latin": "Oncideres tuberosa (Brazil, 2006)"
					},
					{
						"index": 102,
						"name": "Loop lichen",
						"latin": "Hypotrachyna protoboliviana (Costa Rica, 2002)"
					}
				]
			}, {
				slug: 'forest-floor',
				key: [{
						"index": 1,
						"name": "Ginger (new genus)",
						"latin": "Cureuma supraneeanae"
					},
					{
						"index": 2,
						"name": "Nicobar scops-owl",
						"latin": "Otus alius"
					},
					{
						"index": 3,
						"name": "Caterpillar",
						"latin": "Talides sp.Burns01",
						facts: "Generally, a species is defined as a type of plant or animal that breeds exclusively with its own kind. It also usually has some physical features that clearly distinguish it from its kin. Some scientists tend to aggregate closely related organisms into one umbrella species and call the variants races or subspecies, while others splinter groups of organisms into many narrowly defined individual species. Now, in addition to these approaches, described as “lumping” and “splitting,” DNA is used to determine differences. A prime example of DNA speciation came about as a result of Dan Janzen’s many years of rearing caterpillars in Costa Rica. On numerous occasions he and his team collected several very different-looking caterpillars that, after metamorphosis, appeared to be the exact same butterfly. Extraction and coparison of the genes of the different caterpillars clearly showed that they were separate species, despite the near identical appearance of their adult phase. Similarly, via genetic analysis, we now know that the African elephant, once we assumed to be one species, is in fact two noninterbreeding species: one a creature mainly of the forest and the other a denizen of the savannah."
					},
					{
						"index": 4,
						"name": "Arum",
						"latin": "Colocasia lihengiae"
					},
					{
						"index": 5,
						"name": "Shrub",
						"latin": "Blakea chlorantha"
					},
					{
						"index": 6,
						"name": "Leaf lichenized fungi",
						"latin": "Loflammia epiphylla"
					},
					{
						"index": 7,
						"name": "Caterpillar",
						"latin": "Dyscophellus sp.Burns01",
						facts: "Generally, a species is defined as a type of plant or animal that breeds exclusively with its own kind. It also usually has some physical features that clearly distinguish it from its kin. Some scientists tend to aggregate closely related organisms into one umbrella species and call the variants races or subspecies, while others splinter groups of organisms into many narrowly defined individual species. Now, in addition to these approaches, described as “lumping” and “splitting,” DNA is used to determine differences. A prime example of DNA speciation came about as a result of Dan Janzen’s many years of rearing caterpillars in Costa Rica. On numerous occasions he and his team collected several very different-looking caterpillars that, after metamorphosis, appeared to be the exact same butterfly. Extraction and coparison of the genes of the different caterpillars clearly showed that they were separate species, despite the near identical appearance of their adult phase. Similarly, via genetic analysis, we now know that the African elephant, once we assumed to be one species, is in fact two noninterbreeding species: one a creature mainly of the forest and the other a denizen of the savannah."
					},
					{
						"index": 8,
						"name": "Kovach’s slipper orchid (50% life size)",
						"latin": "Phragmipedium kovachii"
					},
					{
						"index": 9,
						"name": "Moth",
						"latin": "Aethalida borneana"
					},
					{
						"index": 10,
						"name": "Udzungwa forest-partridge (new genus)",
						"latin": "Xenoperdix udzungwensis"
					},
					{
						"index": 11,
						"name": "Dracula ant (new genus)",
						"latin": "Adetomyrma venatrix"
					},
					{
						"index": 12,
						"name": "Amanita",
						"latin": "Amanita fuligineodisca"
					},
					{
						"index": 13,
						"name": "Horned scarab",
						"latin": "Megasoma nogueirai"
					},
					{
						"index": 14,
						"name": "Orchid",
						"latin": "Spathoglottis parriflora"
					},
					{
						"index": 15,
						"name": "Gentry’s sobralia",
						"latin": "Sobralia gentryi"
					},
					{
						"index": 16,
						"name": "Burmese spitting cobra",
						"latin": "Naja mandalayensis",
						facts: "This cobra, can spit its venom 6 feet into the eyes of its prey and was discovered in central Myanmar by herpetologist Joe Slowinsky. Slowinsky, along with other researchers from the California Academy of Sciences, made several trips to Myanmar, a country which hosts 37 known species of venomous snakes. These trips served dual functions: to survey the biodiversity of this rarely visited nation and to train local biologists in the latest field techniques. The practice of field biology in the last most remote pockets of the planet is high adventure travel, but with a purpose. However, the risks of this type of adventure are very real, Joe Slowinsky died from snakebite in Myanmar in 2001, in an isolated mountainous region."
					},
					{
						"index": 17,
						"name": "Rain lily",
						"latin": "Zephyranthes susatana"
					},
					{
						"index": 18,
						"name": "Annamite striped rabbit",
						"latin": "Nesolagus timminsi"
					},
					{
						"index": 19,
						"name": "Fungus",
						"latin": "Tetrapyrgos longicystidiata"
					},
					{
						"index": 20,
						"name": "Google ant",
						"latin": "Proceratium google"
					},
					{
						"index": 21,
						"name": "Moss (new genus)",
						"latin": "Ozobryum ogalalense"
					},
					{
						"index": 22,
						"name": "Jim’s clover",
						"latin": "Trifolium jokerstii"
					},
					{
						"index": 23,
						"name": "Van Sung’s shrew",
						"latin": "Chodsigoa caovansunga"
					},
					{
						"index": 24,
						"name": "Giant pill-millipede",
						"latin": "Zoosphaerium arborealis"
					},
					{
						"index": 25,
						"name": "Pygmy tarantula",
						"latin": "Reichlingia annae"
					},
					{
						"index": 26,
						"name": "Rakhin Yoma toad",
						"latin": "Duttaphrynus crocus"
					},
					{
						"index": 27,
						"name": "Piani side-necked turtle",
						"latin": "Mesoclemmys perplexa"
					},
					{
						"index": 28,
						"name": "Bloodfin tetra",
						"latin": "Aphyocharax yekwanae"
					},
					{
						"index": 29,
						"name": "Laos warty newt",
						"latin": "Laotritan laoensis"
					},
					{
						"index": 30,
						"name": "Indonesian superdwarf fish",
						"latin": "Paedocypris progenetica",
						facts: "This recently-discovered fish is thought to be the world’s smallest vertebrate, at just under 8 millimeters. A member of the carp family, it is so tiny that it doesn’t even have a skull. The genus Paedocypris includes just two species, found in remote tropical peat swamps in Sumatra and Indonesia. Like all the other organisms in these paintings, these fish have a unique story of discovery, survival and wonder. Each living thing take hundreds of human hours to walk, hunt, capture, sort, draw, dry, read, and correspond in order to make it possible to fit the tiny shard that is one species into the vast, complicated picture of evolution."
					},
					{
						"index": 31,
						"name": "Guanjo Stabfoot toad or Puca Sapo",
						"latin": "Atelopus guanujo"
					},
					{
						"index": 32,
						"name": "Catfish",
						"latin": "Ompok platyrhynchus"
					},
					{
						"index": 33,
						"name": "Sulawesi forest turtle",
						"latin": "Leucocephalon yuwonoi"
					},
					{
						"index": 34,
						"name": "Hybrid butterfly",
						"latin": "Heliconius heurippa"
					},
					{
						"index": 35,
						"name": "Purple harlequin toad",
						"latin": "Atelopus barbotini"
					},
					{
						"index": 36,
						"name": "Grandfather mountain crayfish",
						"latin": "Cambarus eeseeohensis"
					},
					{
						"index": 37,
						"name": "Cumberland dusky salamander",
						"latin": "Desmognathus abditus"
					},
					{
						"index": 38,
						"name": "Rio Acari marmoset",
						"latin": "Mico acariensis"
					},
					{
						"index": 39,
						"name": "Little brown mudbug",
						"latin": "Lacunicambarus Cambarus thomai"
					},
					{
						"index": 40,
						"name": "Chin Hills garden lizard",
						"latin": "Calotes chincollium"
					},
					{
						"index": 41,
						"name": "Rainbowfish (50% life size)",
						"latin": "Bedotia leucopteron"
					},
					{
						"index": 42,
						"name": "Pugh’s frog",
						"latin": "Philoria pughi"
					},
					{
						"index": 43,
						"name": "Ecuadoran spiny pocket mouse (50% life size)",
						"latin": "Heteromys teleus"
					},
					{
						"index": 44,
						"name": "Chimata Massif marsh pitcher plant (50% life size)",
						"latin": "Heliamphora chimantensis"
					},
					{
						"index": 45,
						"name": "Boyden Cave gilia (50% life size)",
						"latin": "Gilia yorkii"
					},
					{
						"index": 46,
						"name": "Pitcher plant (50% life size)",
						"latin": "Heliamphora elongata"
					},
					{
						"index": 47,
						"name": "Caterpillar",
						"latin": "Dyscophellus sp.Burns02",
						facts: "Generally, a species is defined as a type of plant or animal that breeds exclusively with its own kind. It also usually has some physical features that clearly distinguish it from its kin. Some scientists tend to aggregate closely related organisms into one umbrella species and call the variants races or subspecies, while others splinter groups of organisms into many narrowly defined individual species. Now, in addition to these approaches, described as “lumping” and “splitting,” DNA is used to determine differences. A prime example of DNA speciation came about as a result of Dan Janzen’s many years of rearing caterpillars in Costa Rica. On numerous occasions he and his team collected several very different-looking caterpillars that, after metamorphosis, appeared to be the exact same butterfly. Extraction and coparison of the genes of the different caterpillars clearly showed that they were separate species, despite the near identical appearance of their adult phase. Similarly, via genetic analysis, we now know that the African elephant, once we assumed to be one species, is in fact two noninterbreeding species: one a creature mainly of the forest and the other a denizen of the savannah."
					},
					{
						"index": 48,
						"name": "Fungus",
						"latin": "Marasmius \"complicates\""
					},
					{
						"index": 49,
						"name": "Naung Mung scimitar babbler",
						"latin": "Jabouilleia naungmungensis"
					},
					{
						"index": 50,
						"name": "Dancing shadow emerald",
						"latin": "Idionyx victor"
					},
					{
						"index": 51,
						"name": "Leaf lichen",
						"latin": "Trichothelium akeassii"
					},
					{
						"index": 52,
						"name": "Caterpillar",
						"latin": "Chrysophlectrum sp.Burns01",
						facts: "Generally, a species is defined as a type of plant or animal that breeds exclusively with its own kind. It also usually has some physical features that clearly distinguish it from its kin. Some scientists tend to aggregate closely related organisms into one umbrella species and call the variants races or subspecies, while others splinter groups of organisms into many narrowly defined individual species. Now, in addition to these approaches, described as “lumping” and “splitting,” DNA is used to determine differences. A prime example of DNA speciation came about as a result of Dan Janzen’s many years of rearing caterpillars in Costa Rica. On numerous occasions he and his team collected several very different-looking caterpillars that, after metamorphosis, appeared to be the exact same butterfly. Extraction and coparison of the genes of the different caterpillars clearly showed that they were separate species, despite the near identical appearance of their adult phase. Similarly, via genetic analysis, we now know that the African elephant, once we assumed to be one species, is in fact two noninterbreeding species: one a creature mainly of the forest and the other a denizen of the savannah."
					},
					{
						"index": 53,
						"name": "Stern’s medlar (50% life size)",
						"latin": "Mespilus canescens"
					},
					{
						"index": 54,
						"name": "Jocotoco antpitta (50% life size)",
						"latin": "Grallaria ridgelyi"
					},
					{
						"index": 55,
						"name": "Passionflower",
						"latin": "Passiflora exura"
					},
					{
						"index": 56,
						"name": "Laoatian rock rat (50% life size) (new family)",
						"latin": "Laonastes aenigmamus"
					},
					{
						"index": 57,
						"name": "Killifish (50% life size)",
						"latin": "Laimosemion mahdiaensis"
					},
					{
						"index": 58,
						"name": "Dragonfly",
						"latin": "Matti tamdaoensis"
					},
					{
						"index": 59,
						"name": "York groundsel",
						"latin": "Senecio x eboracensis"
					},
					{
						"index": 60,
						"name": "Slipper orchid",
						"latin": "Paphiopedilum tigrinum"
					},
					{
						"index": 61,
						"name": "Giant-leaf paphiopedilum (25% life size)",
						"latin": "Paphiopedilum gigantifolium"
					},
					{
						"index": 62,
						"name": "Cundinamarca antpitta",
						"latin": "Grallaria kaestneri"
					},
					{
						"index": 63,
						"name": "Puerto Rico maidenhair",
						"latin": "Adiantum vivesii"
					},
					{
						"index": 64,
						"name": "Orchid",
						"latin": "Hemipilia purpureopunctata"
					},
					{
						"index": 65,
						"name": "Beetle (new genus)",
						"latin": "Pseudothonalmus woodleyi"
					},
					{
						"index": 66,
						"name": "Parasitic plant",
						"latin": "Balanophora laxiflora"
					},
					{
						"index": 67,
						"name": "Cardamom mountain wolf snake",
						"latin": "Lycodon cardamomensis"
					},
					{
						"index": 68,
						"name": "Chinchilla rat (new genus)",
						"latin": "Cuscomys ashaninka"
					},
					{
						"index": 69,
						"name": "Onore’s harleguin frog",
						"latin": "Atelopus onorei"
					},
					{
						"index": 70,
						"name": "Parasitic plant",
						"latin": "Afrothismia saingei"
					},
					{
						"index": 71,
						"name": "Jaragua geckolet",
						"latin": "Sphaerodactylus ariasae"
					},
					{
						"index": 72,
						"name": "Pelton’s rose gentian",
						"latin": "Sabatia arkansana"
					},
					{
						"index": 73,
						"name": "Dave Thau’s ant",
						"latin": "Plectroctena thaui"
					},
					{
						"index": 74,
						"name": "Dung beetle",
						"latin": "Copris warneri"
					},
					{
						"index": 75,
						"name": "Moss",
						"latin": "Fissidens yucatanensis"
					},
					{
						"index": 76,
						"name": "Bioluminescent agaric",
						"latin": "Gerronema viridilucens"
					},
					{
						"index": 77,
						"name": "Purple frog (new family)",
						"latin": "Nasikabatrachus sahyadrensis"
					},
					{
						"index": 78,
						"name": "Agaric fungus",
						"latin": "Tricholosporum violaceum"
					},
					{
						"index": 79,
						"name": "Cofre de Perote salamander",
						"latin": "Isthmura naucampatepetl"
					},
					{
						"index": 80,
						"name": "Bukidnon woodcock",
						"latin": "Scolopax bukidnonensis"
					},
					{
						"index": 81,
						"name": "Ant (new genus)",
						"latin": "Boloponera vicans"
					},
					{
						"index": 82,
						"name": "Shasta snow-wreath",
						"latin": "Neviusia cliftonii"
					},
					{
						"index": 83,
						"name": "Caterpillar",
						"latin": "Euglyphis sp.Janzen01",
						facts: "Generally, a species is defined as a type of plant or animal that breeds exclusively with its own kind. It also usually has some physical features that clearly distinguish it from its kin. Some scientists tend to aggregate closely related organisms into one umbrella species and call the variants races or subspecies, while others splinter groups of organisms into many narrowly defined individual species. Now, in addition to these approaches, described as “lumping” and “splitting,” DNA is used to determine differences. A prime example of DNA speciation came about as a result of Dan Janzen’s many years of rearing caterpillars in Costa Rica. On numerous occasions he and his team collected several very different-looking caterpillars that, after metamorphosis, appeared to be the exact same butterfly. Extraction and coparison of the genes of the different caterpillars clearly showed that they were separate species, despite the near identical appearance of their adult phase. Similarly, via genetic analysis, we now know that the African elephant, once we assumed to be one species, is in fact two noninterbreeding species: one a creature mainly of the forest and the other a denizen of the savannah."
					},
					{
						"index": 84,
						"name": "Cane begonia",
						"latin": "Begonia lazat",
						facts: "The tall red stemmed plant on the right side of this picture, Begonia lazat, was discovered with the help of village elders in Sabah, Borneo. It is known to the locals and its leaves were cooked with prawns and chilies to make a succulent dish. (Lazat means delicious in Malay.) Though this plant was not new to the people living where it grows, it was indeed a new species to botanists. The importance of this kind of local knowledge cannot be over emphasized. As indigenous peoples westernize and open to the wider world, their unique knowledge of the local environment may be lost."
					},
					{
						"index": 85,
						"name": "Minerva owl butterfly",
						"latin": "Opsiphanes blythekitzmillerae",
						facts: "In order to raise funds for butterfly research, the Florida Museum of Natural History auctioned off the right to name this spectacular new owl butterfly, the first from this group to be named in more than 100 years. Opsiphanes blythekitzmillerae, was named in honor of Margery Minerva Blythe Kitzmiller on behalf of her five grandchildren. The proceeds from the winning bid of $40,800 will fund continued research on Mexican butterflies in poorly known and threatened habitats throughout the country. Two other examples of species named by auction in this picture are the weevil, Eupholus vlasimskyi, and the Golden Palace.com monkey, Callicebus aureipalatii. There has been some outcry about the ramifications of a monetary—and commercial—value attached to a species. Still, as one researcher put it, “it demonstrates how science can become self-perpetuating; an important discovery can fund additional important discoveries.”"
					},
					{
						"index": 86,
						"name": "Stile’s tapaculo",
						"latin": "Scytalopus stilesi"
					},
					{
						"index": 87,
						"name": "Squinting bush brown butterfly",
						"latin": "Bicyclus anynana"
					},
					{
						"index": 88,
						"name": "Damselfly",
						"latin": "Aristocypha chaoi"
					},
					{
						"index": 89,
						"name": "Shrub (50% life size)",
						"latin": "Arnaldoa argentea"
					},
					{
						"index": 90,
						"name": "Leaf deer (50% life size)",
						"latin": "Muntiacus putaoensis",
						facts: "The inclusion of this species again illustrates the importance of local knowledge in the study of plants and animals. This leaf deer was found through a chance encounter with local hunters who had skulls in their possession that were morphologically different from any others the research scientists had seen. In another generation, however, there may not be enough researchers who possess the special skills necessary to recognize such subtle differences. Many of the bright young men and women in science today are attracted to genetics and microbiology, rather than taxonomy, in part because of a huge difference in funding. As a consequence, the present generation of field researchers try to rapidly train people who live in remote locations to identify new species themselves. In this way, local knowledge may be harnessed before it is lost."
					},
					{
						"index": 91,
						"name": "Caterpillar",
						"latin": "Sorocaba sp.Janzen01",
						facts: "Generally, a species is defined as a type of plant or animal that breeds exclusively with its own kind. It also usually has some physical features that clearly distinguish it from its kin. Some scientists tend to aggregate closely related organisms into one umbrella species and call the variants races or subspecies, while others splinter groups of organisms into many narrowly defined individual species. Now, in addition to these approaches, described as “lumping” and “splitting,” DNA is used to determine differences. A prime example of DNA speciation came about as a result of Dan Janzen’s many years of rearing caterpillars in Costa Rica. On numerous occasions he and his team collected several very different-looking caterpillars that, after metamorphosis, appeared to be the exact same butterfly. Extraction and coparison of the genes of the different caterpillars clearly showed that they were separate species, despite the near identical appearance of their adult phase. Similarly, via genetic analysis, we now know that the African elephant, once we assumed to be one species, is in fact two noninterbreeding species: one a creature mainly of the forest and the other a denizen of the savannah."
					}
				]
			}, {
				slug: 'emergent',
				key: [{
						"index": 1,
						"name": "Moth",
						"latin": "Molippa pilarae"
					},
					{
						"index": 2,
						"name": "Flowering vine",
						"latin": "Oxypetalum flavopurpureum"
					},
					{
						"index": 3,
						"name": "Canopy ant",
						"latin": "(Genus & species unknown)",
						"facts": "This ant may well be a new genus as well as a new species. It was found in the crown of a fallen canopy tree and immediately recognized as unique. Many species of arboreal ant have been found to “glide” towards their own tree trunk if they fall; indeed, they have been found to have an 85% chance of landing on their home tree. Ants that live in trees use a diverse variety of nesting strategies. Some build nests out of rolled leaves, some make a bivouac out of their own linked bodies, some use an existing cavity such as a hollow thorn. And some seed their nests with plants beneficial to the colony; ant gardens can be found throughout the tropics. This particular kind of ant, with its two sharp dorsal spikes, will be described and published with a Latin name once its evolutionary relationship to other ant species is understood."
					},
					{
						"index": 4,
						"name": "Bird-head orchid",
						"latin": "Ornithocephalus montealegreae"
					},
					{
						"index": 5,
						"name": "Tree snail",
						"latin": "Amphidromus abbasi"
					},
					{
						"index": 6,
						"name": "Micro-orchid",
						"latin": "Brachionidium gonzalesii"
					},
					{
						"index": 7,
						"name": "Micro-orchid",
						"latin": "Brachionidium quatuor",
						"facts": "The scale of botanist and orchid specialists Carl A. Luer and his wife Jane’s achievement is remarkable. In addition they almost single-handedly revised the subtribe Pleurothallidinae and published twenty-eight monographs on them before his death at 97 in 2019. This set of orchids is thought to contain over 3,500 species; a microscope is often needed to tell them apart. The Luers not only reorganized them, but also identified over 100 new species along the way. Their labor of love resulted in new appreciation among orchid breeders and fanciers for this large and challenging group of orchids."
					},
					{
						"index": 8,
						"name": "Swallowtail butterfly",
						"latin": "Graphium poggianus"
					},
					{
						"index": 9,
						"name": "Micro-orchid",
						"latin": "Dresslerella lasiocampa"
					},
					{
						"index": 10,
						"name": "Stick insect",
						"latin": "Asceles artabotrys"
					},
					{
						"index": 11,
						"name": "Yungas tyrannulet",
						"latin": "Phyllomyias weedeni"
					},
					{
						"index": 12,
						"name": "Emerald moth",
						"latin": "Episothalma irrobustaria"
					},
					{
						"index": 13,
						"name": "Lichen",
						"latin": "Corella melvinii"
					},
					{
						"index": 14,
						"name": "Bald parrot",
						"latin": "Pyrilia aurantiocephala"
					},
					{
						"index": 15,
						"name": "Smoky honeyeater",
						"latin": "Melipotes fumigatus"
					},
					{
						"index": 16,
						"name": "Kapok tree",
						"latin": "Lecythis oldemani"
					},
					{
						"index": 17,
						"name": "Saturnid moth",
						"latin": "Gamelioides deniseae"
					},
					{
						"index": 18,
						"name": "Odedi",
						"latin": "Horornis haddeni"
					},
					{
						"index": 19,
						"name": "Thai Magnolia",
						"latin": "Magnolia thailandica"
					},
					{
						"index": 20,
						"name": "Satyr butterfly",
						"latin": "Neominois carmen"
					},
					{
						"index": 21,
						"name": "Ichneumon wasp",
						"latin": "Umanella giacometti"
					},
					{
						"index": 22,
						"name": "Glasswing butterfly",
						"latin": "Pachacutia baroni",
						"facts": "In January of 1992, on a ridge top in the Monteverde cloud forest in Costa Rica, I watched a kettle of many hundreds of “glasswinged” butterflies swarm up a steep mountainside. As they reached the summit of the rise, they were swept back down the mountain by strong winds blowing over the ridge. Occasionally a few would tumble over the ridge and make it to the next group of mountains and valleys. My inclusion of them here memorializes the pure awe I felt as these fragile bits of life attempted the summit again and again."
					},
					{
						"index": 23,
						"name": "Tarsier",
						"latin": "Tarsius tumpara"
					},
					{
						"index": 24,
						"name": "Hawkmoth",
						"latin": "Xylophanes vagliai"
					},
					{
						"index": 25,
						"name": "Passionflower",
						"latin": "Passiflora microstipula",
						"facts": "Most passionflower plants are pollinated by heavy-bodied bees or hummingbirds. So why do they produce nectar in little nub-like bodies on the stems of their leaves? The caterpillars of many species of tropical butterfly eat Passiflower leaves; the butterflies from such caterpillars are therefore attracted to them as a place to lay their eggs. The nubs are thought to perform a dual role: they visually fool the butterfly think there are already eggs on the plant, while the nectar they contain attracts ants that will discourage leaf-eating pests."
					},
					{
						"index": 26,
						"name": "Vanikoro white-eye",
						"latin": "Zosterops gibbsi"
					},
					{
						"index": 27,
						"name": "Butterfly",
						"latin": "Aeria elara nigra",
						"facts": "Taxonomists, scientists who parse out the evolutionary position of species, tend to be either “lumpers” or “splitters.” Lumpers are so called because when separating plants or animals into species they emphasize signature similarities over minute differences. Splitters, on the other hand, argue for the separation of species based on fine differentiation, they also tend to name a lot of subspecies, such as this butterfly. Behind this divide lies the definition of species. According to Wikipedia, a species is “a group of organisms capable of interbreeding and producing fertile offspring.” The word “capable” is the cause of much contention. Are differently colored species geographically separated by, say, a river but otherwise capable of mating, a single species or two separate species? This complicated debate continues despite DNA results, since it remains to be determined how much genetic difference is necessary to define speciation."
					},
					{
						"index": 28,
						"name": "Flowering vine",
						"latin": "Schnella trichosepala"
					},
					{
						"index": 29,
						"name": "Rickart’s Dyak Fruit Bat",
						"latin": "Dyacopterus rickarti"
					},
					{
						"index": 30,
						"name": "Steve Irwin’s snail",
						"latin": "Crikey steveirwini",
						"facts": "This new genus and species of Australian land snail was named for the late Steve Irwin, star of the television show “The Crocodile Hunter.” The practice of using honorifics in Latin nomenclature is old and well established: many new species have been named after someone famous or of special significance to the person who first described them. One scientist named a new fish, Chromis pammy, after his wife as an anniversary present to her. A particularly gnarly new genus of spiders has been given the genus name Orsonwelles. Descriptions of three new slime mold beetles, Agathidium bushi, Agathidium cheneyi, and Agathidium rumsfeldi, were all published in the Bulletin of the American Museum of Natural History in 2005."
					},
					{
						"index": 31,
						"name": "Moth",
						"latin": "Getta tica"
					},
					{
						"index": 32,
						"name": "Buprestid beetle",
						"latin": "Coraebosoma viridis"
					},
					{
						"index": 33,
						"name": "Flowering vine",
						"latin": "Oxypetalum fuscum"
					},
					{
						"index": 34,
						"name": "Cryptic warbler",
						"latin": "Cryptosylvicola randrianasoloi"
					},
					{
						"index": 35,
						"name": "Leaf beetle",
						"latin": "Spilopyra semiramis"
					},
					{
						"index": 36,
						"name": "Gabriel’s passionflower",
						"latin": "Passiflora gabrielliana"
					},
					{
						"index": 37,
						"name": "Tiger-striped pit viper",
						"latin": "Trimeresurus honsonensis"
					},
					{
						"index": 38,
						"name": "Olive-backed forest robin",
						"latin": "Stiphrornis pyrrholeamus"
					},
					{
						"index": 39,
						"name": "Morning glory",
						"latin": "Ipomoea cuprinacoma"
					},
					{
						"index": 40,
						"name": "Butterfly",
						"latin": "Lasiophila luna"
					},
					{
						"index": 41,
						"name": "Butterfly",
						"latin": "Pronophila margarita"
					},
					{
						"index": 42,
						"name": "Pleurothalid orchid",
						"latin": "Masdevallia vilcabambensis"
					},
					{
						"index": 43,
						"name": "Orchid bee",
						"latin": "Euglossa natesi",
						"facts": "There are a lot of orchids in this world—about twice as many species of them as there are of birds and four times as many as there are of mammals. Because orchids often have few pollinators, they bloom longer than most flowers and some offer other attractants as well. For orchid bees the lure is volatile chemicals, which the male bees use to synthesize a perfume that attracts females. While the visiting bee is occupied with chemistry, the orchid deposits a sticky sac of pollen onto the bee’s body. The highly specific placement of this pollen packet ensures that cross-pollination only occurs between orchids of the same species. When the bee visits another of the same type of orchid, the pollinia (pollen sac) adheres to a perfectly placed waiting stigma, and voilà, orchid seeds!"
					},
					{
						"index": 44,
						"name": "Pygmy possum",
						"latin": "Cercartetus (species unknown)"
					},
					{
						"index": 45,
						"name": "Satyr butterfly",
						"latin": "Moneuptychia itapeva"
					},
					{
						"index": 46,
						"name": "Orchid",
						"latin": "Dichaea filiarum"
					},
					{
						"index": 47,
						"name": "Butterfly",
						"latin": "Ideopsis fojana"
					},
					{
						"index": 48,
						"name": "Sincora antwren",
						"latin": "Formicivora grantsaui"
					},
					{
						"index": 49,
						"name": "Red-shouldered vanga",
						"latin": "Calicalicus rufocarpalis"
					},
					{
						"index": 50,
						"name": "Butterfly",
						"latin": "Delias laknekei"
					},
					{
						"index": 51,
						"name": "Annamite big-footed mouse-eared bat",
						"latin": "Myotis annamiticus"
					},
					{
						"index": 52,
						"name": "Weevil",
						"latin": "Eupholus vlasimskii"
					},
					{
						"index": 53,
						"name": "Hawkmoth",
						"latin": "Xylophanes cthulhu"
					},
					{
						"index": 54,
						"name": "Nonggang babbler",
						"latin": "Stachyris nonggangensis"
					},
					{
						"index": 55,
						"name": "Butterfly",
						"latin": "Delias durai"
					},
					{
						"index": 56,
						"name": "Flowering pea",
						"latin": "Callerya neocaledonica"
					},
					{
						"index": 57,
						"name": "Katydid",
						"latin": "(Genus & species unknown)"
					},
					{
						"index": 58,
						"name": "Bushbrown butterfly",
						"latin": "Mycalesis richardi"
					},
					{
						"index": 59,
						"name": "Tolimense poison frog",
						"latin": "Andinobates tolimense"
					},
					{
						"index": 60,
						"name": "Yariguíes brush-finch",
						"latin": "Atlapetes latinuchus subsp. yariguierum"
					},
					{
						"index": 61,
						"name": "Noble’s pygmy frog",
						"latin": "Noblella pygmaea"
					},
					{
						"index": 62,
						"name": "Weevil",
						"latin": "Eupholus schneideri"
					},
					{
						"index": 63,
						"name": "Satyr butterfly",
						"latin": "Cyllopsis emilia"
					},
					{
						"index": 64,
						"name": "Aerial roots",
						"latin": "",
						"facts": "The growth of aerial roots on trees, shrubs, and epiphytes in tropical forests is driven by the constant search for nutrients. The soil beneath rainforests is notoriously shallow: the vast majority of the biomass in a rainforest habitat exists above ground in a constant flux of uptake and breakdown. Air roots absorb dissolved nutrients from the constant moisture that seeps down to them through layers of living and decaying matter above, the tips of air roots function just the way underground roots do. Eventually some air roots reach the ground, burrow into the ground, and some grow big enough to form additional anchors for tall, top-heavy trees."
					}
				]
			}, {
				slug: 'understory',
				key: [{
						"index": 1,
						"name": "Butterfly",
						latin: "Catasticta thomasorum",
						"location": "Ecuador, 1998"
					},
					{
						"index": 2,
						"name": "Passion flower",
						latin: "Passiflora luzmarina",
						"location": "Ecuador, 1997"
					},
					{
						"index": 3,
						"name": "Pernambuco pygmy-owl",
						latin: "Glaucidium mooreorum",
						"location": "Brazil, 2002",
						facts: "To a virtuoso violinist like Joshua Bell, his Stradivarius wouldn’t sound as good without his 200-years old, $40,000 bow. Fine bows like his are made from pernambuco, the heartwood of the pau brasil tree. This tree’s habitat is Brazil’s Atlantic Forest, which is home as well to Glaucidium mooreorum. This new species of pygmy-owl is named for Gordon and Betty Moore, generous supporters of research in biodiversity and many other causes. Unfortunately, the survival of both the pau brasil and the Pernambuco pygmy-owl is in question, as Brazil’s population has encroached on coastal forests and harvested the tree for fence posts, firewood and other uses. The pau-brasil recently received protection under CITES, the Convention on International Trade in Endangered Species, but most of the areas of forest that remain are small, isolated, and difficult to protect."
					},
					{
						"index": 4,
						"name": "Bush-cricket",
						latin: "Rhomboptera selangorensis",
						"location": "Malaysia, 2005"
					},
					{
						"index": 5,
						"name": "El Oro parakeet",
						latin: "Pyrrhura orcesi",
						"location": "Ecuador, 1988",
						facts: "The story of the discovery of this bird in Ecuador demonstrates how surprises can be hidden in museum collections. In 1980, a field team observed some unrecognizable parakeets in the cloud forest of southwestern Ecuador. Five years later, a second expedition made further observations and collected some specimens, identifying these parakeets as a probable new species. Pyrrhura orcesi’s formal description was published in 1988, using the recently-collected birds as the type specimens. (A type specimen is the example to which other birds are compared.) During the research that took place between discovery and description, however, a lone example of this ‘new’ species was found in the ornithological collections of Britain’s Natural History Museum, lurking unrecognized amongst a closely- related group. This museum specimen, a male, had in fact been collected in 1939. What is particularly interesting is that it was found well below the altitude at which the ‘new’ birds were encountered-possibly indicating that the species’ habitat has contracted. The El Oro Parakeet is still only known in a very restricted range, and is classified as endangered."
					},
					{
						"index": 6,
						"name": "Butterfly",
						latin: "Tithorea pacifica",
						"location": "Ecuador, 2004"
					},
					{
						"index": 7,
						"name": "Rock firefinch",
						latin: "Lagonosticta sanguinodorsalis",
						"location": "Nigeria, 1998"
					},
					{
						"index": 8,
						"name": "Lina’s sunbird",
						latin: "Aethopyga linaraborae",
						"location": "Philippines, 1997"
					},
					{
						"index": 9,
						"name": "Flowering shrub",
						latin: "Toussaintia patriciae",
						"location": "Tanzania, 2005"
					},
					{
						"index": 10,
						"name": "Butterfly",
						latin: "Actinote eberti",
						"location": "Brazil, 2004"
					},
					{
						"index": 11,
						"name": "White-eared tree frog",
						latin: "Rhacophorus kajau",
						"location": "Borneo, 1984"
					},
					{
						"index": 12,
						"name": "Bent-toed gecko",
						latin: "Cyrtodactylus slowinskii",
						"location": "Myanmar, 2002",
						facts: "Why do we study such seemingly inconsequential animals? For the important lessons they can teach us. Since the fifth century BC, when Aristotle noted how a gecko “can run up and down a tree in any way, even with the head downward,” we have wondered how geckos defy gravity. It’s not because their feet are sticky—they’re dry and smooth to the touch. These tiny appendages owe their remarkable adhesion to microscopic, spatula-tipped filaments (some two billion per square centimeter) on their toe pads. Each filament, at only a hundred nanometers thick, is so fine that it interacts at the molecular level with the surface on which the gecko walks. One of the reasons that maintaining biodiversity is so important is the ways in which complex designs found in nature, like sticky gecko’s feet or hydrodynamic shark skin, can be used to solve problems in engineering, materials science, medicine, and other fields."
					},
					{
						"index": 13,
						"name": "Green bush frog",
						latin: "Philautus bunitus",
						"location": "Borneo, 1995"
					},
					{
						"index": 14,
						"name": "Roosmalen’s dwarf marmoset",
						latin: "Callibella humilis",
						"location": "Brazil, 1998"
					},
					{
						"index": 15,
						"name": "Parachuting frog",
						latin: "Rhacophorus pseudomalabaricus",
						"location": "India, 2000"
					},
					{
						"index": 16,
						"name": "Butterfly",
						latin: "Zaretis pythagoras",
						"location": "Panama, 2001"
					},
					{
						"index": 17,
						"name": "Babbler",
						latin: "Liocichla bugunorum",
						"location": "India, 2006"
					},
					{
						"index": 18,
						"name": "Saturniid moth",
						latin: "Actias angulocaudata",
						"location": "China, 1998"
					},
					{
						"index": 19,
						"name": "Suntiger tarantula",
						latin: "Psalmopoeus irminia",
						"location": "Venezuela, 1994"
					},
					{
						"index": 20,
						"name": "Roosmalen’s dwarf porcupine",
						latin: "Coendou roosmalenorum",
						"location": "Brazil, 2001",
						facts: "The tree porcupine in the lower left and the titi monkey to the right of it were both found by Marc van Roosmalen, a taxonomist and conservationist who has spent much of his life working in the Amazon. The tree porcupine was described by Marc, along with his son Tomas, who has also become a scientist. The problems that many environmental workers face as they confront the consequences of rapid, destructive change are well illustrated by Marc’s current plight: in his haste to work rapidly, he got into trouble with the state of Brazil for failing to comply precisely with state protocols and paperwork. He has been charged with “bio-piracy.” At the time of this writing he has been released from jail while his case is on appeal. Please check the news online in order to follow developments."
					},
					{
						"index": 21,
						"name": "Parachute gecko",
						latin: "Ptychozoon trinotaterra",
						"location": "Vietnam/Thailand, 1999"
					},
					{
						"index": 22,
						"name": "Saturniid moth",
						latin: "Copaxa bella",
						"location": "Peru, 2005"
					},
					{
						"index": 23,
						"name": "Stick insect (*New Genus)",
						latin: "Parapodacanthus hasenpuschorum",
						"location": "Australia, 2003"
					},
					{
						"index": 24,
						"name": "Butterfly",
						latin: "Papilio hermeli",
						"location": "Philippines, 1992"
					},
					{
						"index": 25,
						"name": "Panay cloudrunner",
						latin: "Crateromys heaneyi",
						"location": "Philippines, 1996"
					},
					{
						"index": 26,
						"name": "Spider",
						latin: "Scytodes mapinguari",
						"location": "Brazil, 2004"
					},
					{
						"index": 27,
						"name": "Butterfly",
						latin: "Delias hidecoae",
						"location": "Philippines, 1993"
					},
					{
						"index": 28,
						"name": "Golden-legged bush frog",
						latin: "Philautus aurantium",
						"location": "Borneo, 1989"
					},
					{
						"index": 29,
						"name": "Butterfly",
						latin: "Delias nuydaorum",
						"location": "Philippines, 1975"
					},
					{
						"index": 30,
						"name": "Butterfly",
						latin: "Adelpha hesterbergi",
						"location": "Costa Rica, 1999"
					},
					{
						"index": 31,
						"name": "Black-crowned barwing",
						latin: "Actinodura sodangorum",
						"location": "Vietnam, 1999"
					},
					{
						"index": 32,
						"name": "Moon-gentian",
						latin: "Macrocarpaea apparata",
						"location": "Ecuador, 2001",
						facts: "This three to four meter tall tree grows in the hills of Ecuador. When researchers Lena Struwe and Jason Grant first saw it, they were certain it was something new—probably in the Gentian family. In order to confirm this hypothesis, however, they had to locate and collect specimens from a plant in flower. As darkness approached, the cold, wet, and discouraged botanists suddenly saw some white flowers that seemed to “apparate,” like Harry Potter, out of the mist. The ghostly, almost magical nature of the flower’s appearance is immortalized in this tree’s Latin name. The night blooming, yellowish-white, bell shaped blossoms suggest pollination by moths, bats, or other small nocturnal mammals."
					},
					{
						"index": 33,
						"name": "Mekong wagtail",
						latin: "Motacilla samveasnae",
						"location": "Cambodia, 2001"
					},
					{
						"index": 34,
						"name": "Carrizal seedeater",
						latin: "Amaurospiza carrizalensis",
						"location": "Venezuela, 2003"
					},
					{
						"index": 35,
						"name": "Flying lizard",
						latin: "Draco palawanensis",
						"location": "Philippines, 1998"
					},
					{
						"index": 36,
						"name": "Madame Berthe’s mouse lemur",
						latin: "Microcebus berthae",
						"location": "Madagascar, 2000",
						facts: "In the upper middle of the picture, an animal makes direct eye contact with you: a representative of a newly discovered species of mouse lemur. Lemurs are primates found only on the island nation of Madagascar and the nearby Comoro Islands. Microcebus berthae was described by Steve Goodman of the Field Museum and named in honor of Madame Berthe Rakotosamimanana. “Madame Berthe,” as she was known to hundreds of foreign researchers and Malagasy university students, was the Coordinator of Research Activities in Kirindy forest and a major force in the advancement of Malagasy zoology. Sadly, she died unexpectedly in November of 2005."
					},
					{
						"index": 37,
						"name": "Stick insect",
						latin: "Bactrododema krugeri",
						"location": "South Africa, 2004"
					},
					{
						"index": 38,
						"name": "Htun Win’s tree frog",
						latin: "Rhacophorus htunwini",
						"location": "Myanmar, 2005"
					},
					{
						"index": 39,
						"name": "Chiribiquete emerald",
						latin: "Chlorostilbon olivaresi",
						"location": "Colombia, 1996"
					},
					{
						"index": 40,
						"name": "Medo pit viper",
						latin: "Trimeresurus medoensis",
						"location": "Myanmar, 1977"
					},
					{
						"index": 41,
						"name": "Orchid bee",
						latin: "Euglossa paisa",
						"location": "Colombia, 2005"
					},
					{
						"index": 42,
						"name": "Stephen Nash’s titi monkey",
						latin: "Callicebus stephennashi",
						"location": "Brazil, 2002"
					},
					{
						"index": 43,
						"name": "Lacewing (*New Genus)",
						latin: "Adamsiama curoei",
						"location": "Honduras, 1996"
					},
					{
						"index": 44,
						"name": "Sangha forest robin",
						latin: "Stiphrornis sanghensis",
						"location": "Central African Republic, 1999"
					},
					{
						"index": 45,
						"name": "Sharp-snouted bush frog",
						latin: "Philautus ingeri",
						"location": "Borneo, 1987"
					},
					{
						"index": 46,
						"name": "Saturniid moth",
						latin: "Samia kohlli",
						"location": "Malaysia, 2001"
					},
					{
						"index": 47,
						"name": "Cami quin hanging-parrot",
						latin: "Loriculus camiguinensis",
						"location": "Philippines, 2006"
					},
					{
						"index": 48,
						"name": "Butterfly",
						latin: "Graphium kosii",
						"location": "Papua New Guinea, 1999"
					},
					{
						"index": 49,
						"name": "Big-eared bat",
						latin: "Micronycteris brosseti",
						"location": "French Guiana, 1998"
					},
					{
						"index": 50,
						"name": "Farmland green tree frog",
						latin: "Rhacophorus arvalis",
						"location": "Taiwan, 1995"
					},
					{
						"index": 51,
						"name": "Spider",
						latin: "Scytodes altamira",
						"location": "Brazil, 2000"
					},
					{
						"index": 52,
						"name": "Gladiator bug (*New Order)",
						latin: "Tyrannophasma gladiator",
						"location": "Namibia, 2003"
					},
					{
						"index": 53,
						"name": "Myanmar spotted tree frog",
						latin: "Chirixalus punctatus",
						"location": "Myanmar, 2003"
					},
					{
						"index": 54,
						"name": "Lulu’s tody-tyrant",
						latin: "Poecilotriccus luluae",
						"location": "Peru, 2001"
					},
					{
						"index": 55,
						"name": "Murinae rat (*New Genus)",
						latin: "Sommeromys macrorhinos",
						"location": "Sulawesi, 2002"
					},
					{
						"index": 56,
						"name": "Sucking louse",
						latin: "Hoploplerura sommeri",
						"location": "Sulawesi, 2002"
					},
					{
						"index": 57,
						"name": "Scarlet-banded barbet",
						latin: "Capito wallacei",
						"location": "Peru, 2000"
					},
					{
						"index": 58,
						"name": "Appalachian tiger swallowtail",
						latin: "Pterourus appalachiensis",
						"location": "North Carolina, USA, 2002"
					}
				]
			}]
		}, {
			slug: 'phasmid-eggs',
			paintings: [{
				slug: 'phasmid-eggs-1',
				keyAddition: '(*”sp.” = indeterminate species)',
				key: [{
						"index": 1,
						"latin": "Paracalynda utilaensis"
					},
					{
						"index": 2,
						"latin": "Dinophasma saginatum"
					},
					{
						"index": 3,
						"latin": "Alienbostra brocki"
					},
					{
						"index": 4,
						"latin": "Phaenopharos herwaardeni"
					},
					{
						"index": 5,
						"latin": "Pseudsodatamini sp*"
					},
					{
						"index": 6,
						"latin": "Sungaya inexpectata"
					},
					{
						"index": 7,
						"latin": "Acanthoxyla geisovii"
					},
					{
						"index": 8,
						"latin": "Parapachymorpha spiniger"
					},
					{
						"index": 9,
						"latin": "Oreophoetes peruana"
					},
					{
						"index": 10,
						"latin": "Lonchodes brevipes"
					},
					{
						"index": 11,
						"latin": "Periphetes forcipatus"
					},
					{
						"index": 12,
						"latin": "Phyllium mabantai"
					},
					{
						"index": 13,
						"latin": "Lonchodiodes samarensis"
					},
					{
						"index": 14,
						"latin": "Neohirasea nana"
					},
					{
						"index": 15,
						"latin": "Lonchodes malleti"
					},
					{
						"index": 16,
						"latin": "Prisomera spinicollis"
					},
					{
						"index": 17,
						"latin": "Bacteria horni"
					},
					{
						"index": 18,
						"latin": "Extatosoma tiaratum"
					},
					{
						"index": 19,
						"latin": "Asceles glaber"
					},
					{
						"index": 20,
						"latin": "Haaniella erringtoniae"
					},
					{
						"index": 21,
						"latin": "Medaurini sp*"
					},
					{
						"index": 22,
						"latin": "Cranidium gibbosum"
					},
					{
						"index": 23,
						"latin": "Tachythorax maculicollis"
					},
					{
						"index": 24,
						"latin": "Hermagoras hosei"
					},
					{
						"index": 25,
						"latin": "Necroscia annulipes"
					},
					{
						"index": 26,
						"latin": "Phryganistria heusii"
					},
					{
						"index": 27,
						"latin": "Haplopus micropterus"
					},
					{
						"index": 28,
						"latin": "Carausius detractus"
					},
					{
						"index": 29,
						"latin": "Mithrenes panayensis"
					},
					{
						"index": 30,
						"latin": "Tiracoidea biceps"
					},
					{
						"index": 31,
						"latin": "Orestes mouhoti"
					},
					{
						"index": 32,
						"latin": "Lapaphus sp*"
					},
					{
						"index": 33,
						"latin": "Eurycnema osiris"
					},
					{
						"index": 34,
						"latin": "Phenacephorus latifemur"
					},
					{
						"index": 35,
						"latin": "Paramenexenus laetus"
					},
					{
						"index": 36,
						"latin": "Diapherodes martinicensis"
					},
					{
						"index": 37,
						"latin": "Lonchodes dalawangsungay"
					},
					{
						"index": 38,
						"latin": "Melophasma antillarum"
					},
					{
						"index": 39,
						"latin": "Orxines xiphias"
					},
					{
						"index": 40,
						"latin": "Achrioptera punctipes"
					},
					{
						"index": 41,
						"latin": "Medauroidea extradentata"
					},
					{
						"index": 42,
						"latin": "Marmessoidea rosea"
					},
					{
						"index": 43,
						"latin": "Phyllium bioculatum"
					}
				]
			}, {
				slug: 'phasmid-eggs-2',
				keyAddition: '(*”sp.” = indeterminate species)',
				key: [{
						"index": 1,
						"latin": "Matutumetes amoenus"
					},
					{
						"index": 2,
						"latin": "Lopaphus sphalerus"
					},
					{
						"index": 3,
						"latin": "Asaceles margaritatus"
					},
					{
						"index": 4,
						"latin": "Phyllium giganteum"
					},
					{
						"index": 5,
						"latin": "Anisomorpha ferruginea"
					},
					{
						"index": 6,
						"latin": "Micadina sp*"
					},
					{
						"index": 7,
						"latin": "Necrosciinae sp* (Bauduin’s Thai 2)"
					},
					{
						"index": 8,
						"latin": "Diapheromera femorata"
					},
					{
						"index": 9,
						"latin": "Asystata sp*"
					},
					{
						"index": 10,
						"latin": "Tirachoidea jianfenglingensis"
					},
					{
						"index": 11,
						"latin": "Eurycantha insularis"
					},
					{
						"index": 12,
						"latin": "Hermagoras megabeast"
					},
					{
						"index": 13,
						"latin": "Caribbiopheromera trinitatis"
					},
					{
						"index": 14,
						"latin": "Pseudophasma subapterum"
					},
					{
						"index": 15,
						"latin": "Marmessoidea sp*"
					},
					{
						"index": 16,
						"latin": "Lonchodes jejunus"
					},
					{
						"index": 17,
						"latin": "Sipyloidea biplagiata"
					},
					{
						"index": 18,
						"latin": "Manduria systropedon"
					},
					{
						"index": 19,
						"latin": "Ramulus nematodes"
					},
					{
						"index": 20,
						"latin": "Pseudodiacantha macklottii"
					},
					{
						"index": 21,
						"latin": "Rhamphophasma spinicorne"
					},
					{
						"index": 22,
						"latin": "Eurycnema versirubra"
					},
					{
						"index": 23,
						"latin": "Lonchodes philippinicus"
					},
					{
						"index": 24,
						"latin": "Isagoras sp* (Los Baos)"
					},
					{
						"index": 25,
						"latin": "Monandroptera acanthomera"
					},
					{
						"index": 26,
						"latin": "Phasma gigas (Jailolo)"
					},
					{
						"index": 27,
						"latin": "Megacrania tsudai"
					},
					{
						"index": 28,
						"latin": "Mearnsiana bullosa"
					},
					{
						"index": 29,
						"latin": "Tropidoderus childrenii"
					},
					{
						"index": 30,
						"latin": "Calynda coronata"
					},
					{
						"index": 31,
						"latin": "Dimorphodes sp*"
					},
					{
						"index": 32,
						"latin": "Lobolibethra panguana"
					},
					{
						"index": 33,
						"latin": "Andropromachus scutatus"
					},
					{
						"index": 34,
						"latin": "Onchestus rentzi"
					},
					{
						"index": 35,
						"latin": "Pseudophasma subapterum"
					},
					{
						"index": 36,
						"latin": "Necrosciinae sp* (Tam Dao)"
					},
					{
						"index": 37,
						"latin": "Carausius globosus"
					}
				]
			}]
		}, {
			slug: 'taxa',
			paintings: [{
				slug: 'ascendant',
				key: [

					{
						"index": 1,
						"name": "Asian long-horned beetle",
						"latin": "Anoplophora glabripennis"
					},
					{
						"index": 2,
						"name": "Japanese beetle",
						"latin": "Popillia japonica"
					},
					{
						"index": 3,
						"name": "European gypsy moth",
						"latin": "Lymantria dispar"
					},
					{
						"index": 4,
						"name": "Neon tetra",
						"latin": "Paracheirodon innesi"
					},
					{
						"index": 5,
						"name": "Killer bee",
						"latin": "Apis mellifera scutellata"
					},
					{
						"index": 6,
						"name": "Chinese mantis",
						"latin": "Tenodera aridifolia sinensis"
					},
					{
						"index": 7,
						"name": "Brown-headed cowbird egg",
						"latin": "Molothrus ater",
						"facts": "The cowbird is an opportunist that lays its eggs in the unattended nests of other birds. A female cowbird uses trickery to ensure acceptance of her egg; she will puncture and eat, or eject one of the host’s eggs so that when the host female returns, there are the same number of eggs as when she left. If the ruse goes undetected, the host bird will incubate the cowbird egg as part of her own clutch. Cowbird chicks are large at birth and grow rapidly, often displace their nest-mates, and sorely tax the host parents’ energy and skills. This strategy of finding unsuspecting hosts to incubate their eggs and raise their young evolved so that the cowbird could migrate with the great bison herds that once roamed central North America, freeing them to follow the feast of insects stirred up by the bison. Many species of songbird along the old migratory bison routes learned to recognize and reject a brown-headed cowbird egg. Since the demise of the bison, the cowbird has switched its foraging strategy to sedentary domestic livestock, and now its range includes all of sub-Arctic Canada, the United States, and northern Mexico. This sleight-of-hand artist, free to lay eggs all season long, now parasitizes the nests of over 200 species of bird, most of which do not recognize the usurper’s egg."
					},
					{
						"index": 8,
						"name": "Yellow starthistle",
						"latin": "Centaurea solstitialis"
					},
					{
						"index": 9,
						"name": "Rusty crayfish",
						"latin": "Faxonius rusticus"
					},
					{
						"index": 10,
						"name": "African clawed frog",
						"latin": "Xenopus laevis"
					},
					{
						"index": 11,
						"name": "Cane toad",
						"latin": "Rhinella marina"
					},
					{
						"index": 12,
						"name": "Bullfrog",
						"latin": "Lithobates catesbeianus"
					},
					{
						"index": 13,
						"name": "Musk thistle",
						"latin": "Carduus nutans"
					},
					{
						"index": 14,
						"name": "Wild artichoke or cardoon",
						"latin": "Cynara cardunculus"
					},
					{
						"index": 15,
						"name": "Purple loosestrife",
						"latin": "Lythrum salicaria",
						"facts": "Imported into the United States in the early 1800s from Europe as a garden ornamental, these stunning spikes of purple flourish in wetlands in every state except Florida. The “purple plague” shares all of the major traits that characterize successful invasive plants: early reproduction by seed and shoot; many and easily dispersed seeds that have few special germination requirements; long flowering and fruiting seasons; tolerance of a wide range of climatic and soil conditions; and few if any predators or diseases in its new habitat, two factors which normally keep populations in check."
					},
					{
						"index": 16,
						"name": "Medusahead",
						"latin": "Taeniatherum caput-medusae"
					},
					{
						"index": 17,
						"name": "Cheat grass",
						"latin": "Bromus tectorum",
						"facts": "Now covering over 100 million acres of the intermountain west of North America, cheat grass supplies green forage for cattle. This range invader entered British Columbia in 1899 and spread along the path of the railroads. Trains transporting settlers’ livestock carried cheat grass seed mixed in with seed grain, feed, bedding, and animal dung. B. tectorum seeds sprout early and choke out competing native grasses and shrubs. Unlike many native plants, cheat grass burns readily, regenerating rapidly after fire, resulting in a vast monoculture. When cheat grass takes over, there is a drop in the populations of small animals like chipmunks, ground squirrels, kangaroo rats, voles, shrews, and mice. Bird diversity drops as well. There may be from eight to twelve species of birds in sagebrush scrub, but there may be only one to three in cheat grass. This is because the native inhabitants rely on a variety of roots, sprouts, and seeds, as well as the contingent insect populations of the more varied sagebrush plant community in order to thrive."
					},
					{
						"index": 18,
						"name": "Periwinkle",
						"latin": "Vinca major"
					},
					{
						"index": 19,
						"name": "English ivy",
						"latin": "Hedera helix"
					},
					{
						"index": 20,
						"name": "Multiflora rose",
						"latin": "Rosa multiflora"
					},
					{
						"index": 21,
						"name": "Ring-billed gull",
						"latin": "Larus delawarensis"
					},
					{
						"index": 22,
						"name": "Tamarisk",
						"latin": "Tamarix parviflora"
					},
					{
						"index": 23,
						"name": "Nutria",
						"latin": "Myocastor coypus",
						"facts": "Beaver fur is ideal for making felt hats, beaver oil was used in medicines and perfumes, and beaver tail was once considered a great delicacy. By 1350, all of Europe and much of Russia had been denuded of beavers. Between 1700 and 1900, over 400 million pelts were harvested and exported from America. The nutria, a large South American rodent, was imported to the United States in 1938 with the hope to establish a replacement for the American beaver trade. Nutrias escaped from breeding farms and spread widely, rooting up marshland in the deep southeast. With no large predators left to reduce the nutria population, their vigorous feeding habits are having a significant impact on marsh vegetation, particularly cypress seedlings, and are effectively converting marsh to open water."
					},
					{
						"index": 24,
						"name": "Strawberry",
						"latin": "Monoculturalization"
					},
					{
						"index": 25,
						"name": "Corn",
						"latin": "Bioengineering"
					},
					{
						"index": 26,
						"name": "Water hyacinth",
						"latin": "Pontederia crassipes"
					},
					{
						"index": 27,
						"name": "Pigeon",
						"latin": "Columba livia"
					},
					{
						"index": 28,
						"name": "Jackson’s chameleon",
						"latin": "Trioceros jacksonii"
					},
					{
						"index": 29,
						"name": "Rosy wolfsnail",
						"latin": "Euglandina rosea"
					},
					{
						"index": 30,
						"name": "Hydrilla",
						"latin": "Hydrilla verticillata"
					},
					{
						"index": 31,
						"name": "Green crab",
						"latin": "Carcinus maenas"
					},
					{
						"index": 32,
						"name": "Death cap",
						"latin": "Amanita phalloides"
					},
					{
						"index": 33,
						"name": "Lawn grasses",
						"latin": "Various"
					},
					{
						"index": 34,
						"name": "Chinese tallow tree",
						"latin": "Sapium sebiferum"
					},
					{
						"index": 35,
						"name": "Zebra mussel",
						"latin": "Dreissena polymorpha"
					},
					{
						"index": 36,
						"name": "Asiatic clam",
						"latin": "Corbicula fluminea"
					},
					{
						"index": 37,
						"name": "Largemouth bass",
						"latin": "Micropterus salmoides"
					},
					{
						"index": 38,
						"name": "Dandelion",
						"latin": "Taraxacum officinale"
					},
					{
						"index": 39,
						"name": "Bachelor’s buttons",
						"latin": "Centaurea cyanus"
					},
					{
						"index": 40,
						"name": "Freeway ice plant",
						"latin": "Carpobrotus edulis"
					},
					{
						"index": 41,
						"name": "Indian mongoose",
						"latin": "Herpestes javanicus",
						"facts": "Rats have followed humans throughout time and around the globe, with devastating consequences for many local species, especially on islands. In Hawaii, black rats damage native trees and shrubs. They also eat birds’ eggs, nestlings, and invertebrates, including many beautiful native varieties of tree snail. The Indian mongoose was imported to Hawaii in 1883 in a misguided attempt to exterminate rats. However, rats are largely nocturnal, or active at night, and the mongoose is diurnal, or active during the day. This ill-conceived introduction devastated Hawaii’s ground-nesting birds, including three endangered species: the Hawaiian black-rumped petral, Newell’s shearwater, and the ne-ne or Hawaiian goose. The ne-ne was pushed to the brink of extinction by mongoose predation, but strict protective measures and captive breeding programs have increased the ne-ne population."
					},
					{
						"index": 42,
						"name": "Red-eared pond slider",
						"latin": "Trachemys scripta"
					},
					{
						"index": 43,
						"name": "Domestic cat",
						"latin": "Felis catus",
						"facts": "While much effort has been made to curb the cat population through neutering and spaying, there are still over 100 million domestic cats in the United States alone. It is thought that cats kill about 500 million birds, one billion small mammals, and 250 million lizards and other reptiles annually in North America. One study in Britain showed that the average number of birds killed by a free-ranging house cat was 40 per year, despite being well fed at home. Another study showed that bell-wearing cats actually caught more prey than un-belled cats. It is uncertain from the second study whether the cats actually become stealthier because of the bell, or whether only the most successful hunters are generally belled in the first place. Cats are just one of a growing list of pet animals that become a problem when they are irresponsibly or accidentally “set free.”"
					},
					{
						"index": 44,
						"name": "Kudzu",
						"latin": "Pueraria montana var. lobata"
					},
					{
						"index": 45,
						"name": "Velvet tree",
						"latin": "Miconia calvescens",
						"facts": "Tahitians call this Latin American transplant the “green cancer,” as it now covers nearly 70% of Tahiti’s forests. This beautiful plant’s leaves are velvety and have purple undersides. A mature tree can grow to 50 feet and casts such dense shade that very few plants can survive beneath it. Unlike native plants, it’s shallow roots do not hold the soil well, So run-off and siltation from large stands contribute heavily to reef degradation. Like other successful invasive plants, this tree matures early and can produce millions of seeds a year. There are many vectors for the spread of these seeds: wind, water, bird droppings, and mud on grading equipment, bulldozers, autos, pig’s feet or hiking shoes. Some 11,000 acres of Hawaii are also covered with it at present, although intensive manual eradication efforts are underway."
					},
					{
						"index": 46,
						"name": "Norway rat",
						"latin": "Rattus norvegicus"
					},
					{
						"index": 47,
						"name": "Mile-a-minute vine",
						"latin": "Ipomoea cairica"
					},
					{
						"index": 48,
						"name": "Oriental bittersweet",
						"latin": "Celastrus orbiculatus"
					},
					{
						"index": 49,
						"name": "Cattle egret",
						"latin": "Bubulcus ibis"
					},
					{
						"index": 50,
						"name": "Japanese knotweed",
						"latin": "Fallopia japonica"
					},
					{
						"index": 51,
						"name": "House sparrow",
						"latin": "Passer domesticus"
					},
					{
						"index": 52,
						"name": "French broom",
						"latin": "Genista monspessulana"
					},
					{
						"index": 53,
						"name": "Melaleuca",
						"latin": "Melaleuca quinquenervia"
					},
					{
						"index": 54,
						"name": "Princess tree",
						"latin": "Paulownia tomentosa"
					},
					{
						"index": 55,
						"name": "Scotch broom",
						"latin": "Cytisus scoparious"
					},
					{
						"index": 56,
						"name": "Giant reed",
						"latin": "Arundo donax"
					},
					{
						"index": 57,
						"name": "Starling",
						"latin": "Sturnus vulgaris",
						"facts": "Eugene Schiefflin was the leader of a Victorian society whose aim was to bring to America plants and animals that reminded its members of “home.” Schiefflin’s particular passion was for the birds mentioned by Shakespeare, so in the early 1890s, he released 100 starlings in Central Park in New York. The current population estimate for starlings is over 200 million, making this the most successful avian introduction to this continent. Normally associated with disturbed areas, starlings have adapted well to modern life, making use of buildings, dumps, and large-scale farming to great advantage. Unfortunately they tend to outcompete native birds for cavity nesting sites. Competition is also heightened because there are so few remaining natural forests with standing dead wood. One of the few benefits of the starlings’ great numbers is their ready availability as study subjects in the exploration of flight mechanics, flocking dynamics, and avian genetics."
					},
					{
						"index": 58,
						"name": "Kahili ginger",
						"latin": "Hedychium gardnerianum"
					},
					{
						"index": 59,
						"name": "Brown tree snake",
						"latin": "Boiga irregularis"
					},
					{
						"index": 60,
						"name": "Pampas grass",
						"latin": "Cortaderia jubata"
					},
					{
						"index": 61,
						"name": "Red-vented bulbul",
						"latin": "Pycnonotus cafer"
					},
					{
						"index": 62,
						"name": "Brazilian peppertree",
						"latin": "Schinus terebinthifolius"
					},
					{
						"index": 63,
						"name": "Blue gum",
						"latin": "Eucalyptus globulus"
					},
					{
						"index": 64,
						"name": "Baby’s breath",
						"latin": "Gypsophila paniculata"
					},
					{
						"index": 65,
						"name": "Common mullein",
						"latin": "Verbascum thapsus"
					},
					{
						"index": 66,
						"name": "Red imported fire ant",
						"latin": "Solenopis invicta"
					},
					{
						"index": 67,
						"name": "Painted lady",
						"latin": "Vanessa cardui"
					},
					{
						"index": 68,
						"name": "Goldfish",
						"latin": "Carassius auratus"
					},
					{
						"index": 69,
						"name": "Boll weevil",
						"latin": "Anthonomus grandis"
					},
					{
						"index": 70,
						"name": "Honeybee",
						"latin": "Apis mellifera"
					},
					{
						"index": 71,
						"name": "African bigheaded ant",
						"latin": "Pheidole megacephala"
					},
					{
						"index": 72,
						"name": "Argentine ant",
						"latin": "Linepithema humile"
					},
					{
						"index": 73,
						"name": "Dalmatian toadflax",
						"latin": "Linaria dalmatica"
					},
					{
						"index": 74,
						"name": "Milkweed tortoise beetle",
						"latin": "Chelymorpha cribraria"
					}
				]
			}, {
				slug: 'back',
				key: [{
						"index": 1,
						"name": "Forest owlet",
						"latin": "Athene blewitti",
						"facts": "For half a century, the forest owlet was known from only seven museum specimens. Four of these were shot in the 1880s and the skins belong to Britain’s Natural History Museum. One additional specimen, purportedly shot in 1914, was acquired by the museum as part of the enormous collection of flamboyant World War I soldier, spy, and amateur ornithologist Colonel Richard Meinhertzhagen. In 1993 while doing research for The Birds of India, Pamela Rasmussen of the Smithsonian, Nigel Collar of Birdlife International, and Robert Prys-Jones of the Natural History Museum’s Bird Group found incongruities in Meinhertzhagen’s documentation for his forest owlet specimen. Using modern forensic technologies, Rasmussen and colleagues discovered evidence of theft (possibly from the Museum’s own holdings), re-stuffing, and falsified label data. Based, in part, on Meinhertzhagen’s invented location for the bird he claimed to have shot, for 113 years researchers had looked for the bird in the wrong place. Considering the habitat preferred by some of these species, in 1997, Rasmussen and Collar, et al rediscovered the forest owlet in lowland hill forest near Bombay, one of the most populous cities in India. The team is currently re-examining Meinhertzhagen’s entire Asian collection."
					},
					{
						"index": 2,
						"name": "Cebu flowerpecker",
						"latin": "Dicaeum quadricolor"
					},
					{
						"index": 3,
						"name": "Golden-crowned manakin",
						"latin": "Lepidothrix vilasboasi"
					},
					{
						"index": 4,
						"name": "Hairy-eared dwarf lemur",
						"latin": "Allocebus trichotis"
					},
					{
						"index": 5,
						"name": "Fender’s blue",
						"latin": "Icaricia icarioides fenderi"
					},
					{
						"index": 6,
						"name": "Madagascar red owl",
						"latin": "Tyto soumagnei"
					},
					{
						"index": 7,
						"name": "Jamaican rock iguana",
						"latin": "Cyclura collei"
					},
					{
						"index": 8,
						"name": "Laysan finch",
						"latin": "Telespiza cantans"
					},
					{
						"index": 9,
						"name": "Kobushi magnolia",
						"latin": "Magnolia kobus",
						"facts": "There is an apocryphal story about some researchers who found a few black seeds in jars of 2,000 year-old rice buried at an archaeological site in Japan. Supposedly the seeds were sprouted, propagated, and ten years later the magnolia bloomed with an unusual number of petals. This unusual petal count suggested that it was an ancient species of magnolia that had returned from a two century long dormancy. This story seems to have no basis in fact. Perhaps this “resurrected” magnolia was a genetic aberration. Perhaps it was wishful thinking. Magnolia seeds are, in fact, known for being fleshy and short-lived. This “resurrected” magnolia was included in the picture because this story so clearly exemplifies the depth of our desire for nature to be resilient."
					},
					{
						"index": 10,
						"name": "Colorful puffleg",
						"latin": "Eriocnemis mirabilis"
					},
					{
						"index": 11,
						"name": "Kakapo or Taonga",
						"latin": "Strigops habroptila"
					},
					{
						"index": 12,
						"name": "Whooping crane egg",
						"latin": "Grus americana"
					},
					{
						"index": 13,
						"name": "Brown pelican egg",
						"latin": "Pelecanus occidentalis"
					},
					{
						"index": 14,
						"name": "Shasta owl’s clover",
						"latin": "Orthocarpus pachystachyus"
					},
					{
						"index": 15,
						"name": "Peregrine falcon egg",
						"latin": "Falco peregrinus"
					},
					{
						"index": 16,
						"name": "Arctic lupine seeds",
						"latin": "Lupinus arcticus",
						"facts": "A fairly common plant in the arctic, this lupine is unique for its seeds’ extraordinary longevity and resilience. At a gold mine in Canada in 1954, an entire lemming burrow was dug up from 3-6 meters below the frozen surface. A curious mining engineer put the nest and all its contents in a cool, dry storage shed. Twelve years later, Canadian Museum of Nature paleontologist Dick Harington heard about the unearthed burrow and took the contents to Ottawa for examination. Harington inferred an age of about 10,000 years from some partially fossilized bones. Of about 20 seeds found in the burrow, six sprouted and grew into healthy Lupinus arcticus plants. If these seeds are as old as the bones, this would be the longest known example of seed dormancy. The previous documented record holder was a sacred lotus that sprouted from a 1,275 year-old seed."
					},
					{
						"index": 17,
						"name": "Relict leopard frog",
						"latin": "Lithobates onca"
					},
					{
						"index": 18,
						"name": "Bald eagle egg",
						"latin": "Haliaeetus leucocephalus"
					},
					{
						"index": 19,
						"name": "Nene egg",
						"latin": "Brante sandvicensis"
					},
					{
						"index": 20,
						"name": "Showy Indian clover",
						"latin": "Trifolium amoenum"
					},
					{
						"index": 21,
						"name": "Large blue butterfly",
						"latin": "Maculinea arion",
						"facts": "The last of Britain’s large blue butterflies died in 1979, despite 200 years of conservation efforts to save the species. Maculinea arion is myrmecophilous, or ant-loving, and has an astounding relationship to the red ants native to its habitat. The butterfly lays its eggs on wild thyme. The caterpillars hatch, eat the thyme leaves for a few weeks, and then drop to the ground. There they secrete “honey” to attract ants. After the ants have eaten their fill, a caterpillar waits for most of them to leave, then rears up on its back feet and imitates an ant grub. If this deception works, one red ant will think the caterpillar is an escaped ant grub, and carry the caterpillar into the ant nest. Once inside, the caterpillar feasts on ant grubs with which it has been placed. In spring, the caterpillar forms a chrysalis near the entrance to the nest. The adult butterflies of this species all emerge from the ground within a few, short weeks in mid- to late summer. In 1972, in the face of the imminent extinction of Maculinea arion, Jeffrey Thomas went to creative and exhaustive lengths to research what remained of the butterfly’s habitat in Cornwall. There he discovered five nearly identical species of red ants. Several of these are invasive species that tend to recognize the caterpillars as intruders and kill them. The red ant species key to the butterfly’s survival has dwindled because of well meaning but incorrect conservation efforts. The requisite ants require close-cropped, warm, sandy southern exposures for their nest sites. The erroneous efforts had yielded cool, shady soil. Without enough of its needed ants the butterfly went extinct. In 1983, Maculinea arion’s close cousins from Sweden were introduced to Cornwall and other sites on correctly prepared soils with enough wild thyme. There are now several carefully monitored populations around Britain; one of which hatches a thousand or more adults each summer."
					},
					{
						"index": 22,
						"name": "Wollemi pine",
						"latin": "Wollemia nobilis"
					},
					{
						"index": 23,
						"name": "Deppea",
						"latin": "Deppea splendens"
					},
					{
						"index": 24,
						"name": "Yadkin river goldenrod",
						"latin": "Solidago plumosa"
					},
					{
						"index": 25,
						"name": "Ventura marsh milkvetch",
						"latin": "Astragalus pycnostachyus var. lanosissimus"
					},
					{
						"index": 26,
						"name": "Koki’o",
						"latin": "Kokia cookei"
					},
					{
						"index": 27,
						"name": "Golden pennant",
						"latin": "Glischrocaryon"
					},
					{
						"index": 28,
						"name": "Winteraceae",
						"latin": "Takhtajania perrieri"
					},
					{
						"index": 29,
						"name": "Orchid",
						"latin": "Dendrobium biloculare"
					},
					{
						"index": 30,
						"name": "Orchid",
						"latin": "Bulbophyllum rothschildianum"
					},
					{
						"index": 31,
						"name": "Pale-headed brush-finch",
						"latin": "Atlapetes pallidiceps"
					},
					{
						"index": 32,
						"name": "Franklin tree",
						"latin": "Franklinia alatamaha",
						"facts": "Franklinia alatamaha no longer survives in the wild. The well-known Philadelphia plantsman, John Bartram, and his son William first discovered the spreading green tree with graceful white flowers along the Alatamaha River in Georgia in 1765. He returned to the site a few years later and collected seeds. The plant was reportedly seen in the wild in 1790, and possibly in 1803, but has never again been found in its native environment. Lucky for us, Bartram was able to grow the plant in his garden from the seeds he collected. It is believed that all of the plants available from nurseries today are descendants of those he cultivated."
					},
					{
						"index": 33,
						"name": "Antiguan racer",
						"latin": "Alsophis antiguae"
					},
					{
						"index": 34,
						"name": "Snail darter",
						"latin": "Percina tanasi"
					},
					{
						"index": 35,
						"name": "Owens pupfish",
						"latin": "Cyprinodon radiosus",
						"facts": "The Central Valley of California was nearly mosquito-free before the decline of the native pupfish. Uniquely tolerant of the elevated temperature and shallow waters in the valley, the Owens pupfish has the misfortune of living in the river system most severely impacted by the water demands of Los Angeles. By 1948, it was thought extinct. The fish was re-found in 1956 and efforts were made in 1964 to build refuges with sanctuary pools and barriers to keep out non-native, predatory fish. In 1967, the Owens pupfish was federally listed as endangered. In 1969, a combination of natural forces almost completely dried one of the last remaining pupfish pools. Fisheries biologist Phil Pister and colleagues raced out to an area called Fish Slough with buckets, dipnets, and aerators to save the fish. They removed 800 fish, put them in mesh cages in a different pool nearby, and planned to move them the following day to better sites. On Pister’s final check before leaving for the day, he noticed that fish were dying in large numbers from lack of oxygen. He gathered all surviving fish in two 5-gallon buckets. Praying he would not trip, Pister carried the buckets and the fate of the species, as he searched for more suitable temporary pools. Fortunately for the pupfish, he succeeded. Less drastic conservation efforts continue to this day."
					},
					{
						"index": 36,
						"name": "Na’u",
						"latin": "Gardenia brighamii"
					},
					{
						"index": 37,
						"name": "Hualalai han Kuahiwi",
						"latin": "Hibiscadelphus hualalaiensis"
					},
					{
						"index": 38,
						"name": "Short-tailed albatross egg",
						"latin": "Phoebastria albatrus",
						"facts": "The short-tailed albatrosses of Torishima Island, 370 miles south of Tokyo, once numbered in the millions. They were slaughtered for their plumage from the early 1800s until the 1930s. The harvest of feathers and down was so great it could be measured in tons. In 1902, a volcano erupted on Torishima, destroying nesting habitat, killing many of the birds, and all of the 125 villagers who made their livelihood from them. The short-tailed albatross was believed extinct for two decades. Happily, short-tails were seen again on the island in 1951. By 2013, their population was estimated at 4,200 individuals, with 1,136 pairs breeding in two separate colonies."
					},
					{
						"index": 39,
						"name": "Hawaiian damselfly or Flying earwing",
						"latin": "Megalagrion nesiotes"
					},
					{
						"index": 40,
						"name": "Noisy scrubbird",
						"latin": "Atrichornis clamosus"
					},
					{
						"index": 41,
						"name": "Servaline genet",
						"latin": "Genetta servalina"
					},
					{
						"index": 42,
						"name": "Jewel scarab",
						"latin": "Chrysina spectabilis"
					},
					{
						"index": 43,
						"name": "Kinglet calyptura",
						"latin": "Calyptura cristata"
					},
					{
						"index": 44,
						"name": "Black-footed ferret",
						"latin": "Mustela nigripes"
					},
					{
						"index": 45,
						"name": "Cherry-throated tanager",
						"latin": "Nemosia rourei"
					},
					{
						"index": 46,
						"name": "Lord Howe Island stickinsect",
						"latin": "Dryococelus australis"
					},
					{
						"index": 47,
						"name": "Lange’s metalmark",
						"latin": "Apodemia mormo langei"
					},
					{
						"index": 48,
						"name": "Mauritius kestrel",
						"latin": "Falco punctatus"
					}
				]
			}, {
				slug: 'collection',
				key: [{
						"index": 1,
						"name": "Golden or Syrian hamster",
						"latin": "Mesocricetus auratus",
						"facts": "Most golden hamsters in captivity are descended from a single mother and her six pups. They were collected in Syria in 1930 and are now common in captivity."
					},
					{
						"index": 2,
						"name": "Siberian musk deer scent gland",
						"latin": "Moschus moschiferus"
					},
					{
						"index": 3,
						"name": "Tibetan antelope or chiru shawl",
						"latin": "Pantholops hodgsonii",
						"facts": "The underfur wool of this antelope is used to make shahtoosh, a fabric so light it can pass through a wedding ring. The chiru is now near threatened due to poaching for shahtoosh."
					},
					{
						"index": 4,
						"name": "Panther chameleon",
						"latin": "Furcifer pardalis",
						"facts": "Known for its bright color variation, the panther chameleon was one of the first chameleons bred in captivity. It was heavily collected illegally between 1988 and 1994."
					},
					{
						"index": 5,
						"name": "Northern velvet gecko",
						"latin": "Oedura castelnaui"
					},
					{
						"index": 6,
						"name": "Horned parakeet",
						"latin": "Eunymphicus cornutus"
					},
					{
						"index": 7,
						"name": "Imperial amazon or sisserou",
						"latin": "Amazona imperialis",
						"facts": "The national bird of Dominica, known for its color-changing feathers. It is critically endangered, with only about 40 to 60 individuals remaining as of 2020."
					},
					{
						"index": 8,
						"name": "Palawan peacock-pheasant",
						"latin": "Polyplectron napoleonis"
					},
					{
						"index": 9,
						"name": "Banded pitta",
						"latin": "Pitta guajana"
					},
					{
						"index": 10,
						"name": "Princess parrot",
						"latin": "Polytelis alexandrae"
					},
					{
						"index": 11,
						"name": "Imperial harp shell",
						"latin": "Harpa costata"
					},
					{
						"index": 12,
						"name": "Precious wentletrap",
						"latin": "Epitonium scalare"
					},
					{
						"index": 13,
						"name": "Japanese wonder shell",
						"latin": "Thatcheria mirabilis"
					},
					{
						"index": 14,
						"name": "Redracer nerite",
						"latin": "Vittina waigiensis"
					},
					{
						"index": 15,
						"name": "Spindle tibia",
						"latin": "Tibia fusus"
					},
					{
						"index": 16,
						"name": "Manus Island tree",
						"latin": "Papustyla pulcherrima"
					},
					{
						"index": 17,
						"name": "West Indian Ocean coelacanth",
						"latin": "Latimeria chalumnae",
						"facts": "Believed extinct until rediscovered in 1938, the coelacanth's population is now estimated at 1,000 individuals. Conservation efforts are in place to protect them."
					},
					{
						"index": 18,
						"name": "Great white shark jaw",
						"latin": "Carcharodon carcharias",
						"facts": "Great white sharks are vital to ocean ecosystems as top predators. They are protected in many regions due to their vulnerable status."
					},
					{
						"index": 19,
						"name": "Golden Kaiser-I-Hind butterfly",
						"latin": "Teinopalpus aureus"
					},
					{
						"index": 20,
						"name": "Bhutan glory butterfly",
						"latin": "Bhutanitis lidderdalii"
					},
					{
						"index": 21,
						"name": "Priam’s blue birdwing butterfly",
						"latin": "Ornithoptera priamus subsp. urvillianus"
					},
					{
						"index": 22,
						"name": "Leafwing butterfly",
						"latin": "Charaxes fournieriae subsp. fournieriae"
					},
					{
						"index": 23,
						"name": "Leafwing butterfly",
						"latin": "Charaxes andranodorous"
					},
					{
						"index": 24,
						"name": "Madagascar diadem brushfoot butterfly",
						"latin": "Hypolimnas dexithea"
					},
					{
						"index": 25,
						"name": "Lijiang cypripedium",
						"latin": "Cypripedium lichiangense"
					},
					{
						"index": 26,
						"name": "Irapeao cypripedium",
						"latin": "Cypripedium irapeanum"
					},
					{
						"index": 27,
						"name": "Antelope orchid (May river variant)",
						"latin": "Dendrobium lasianthera"
					},
					{
						"index": 28,
						"name": "Antelope orchid (Sepik river variant)",
						"latin": "Dendrobium lasianthera"
					},
					{
						"index": 29,
						"name": "Orchid",
						"latin": "Dendrobium eximium"
					},
					{
						"index": 30,
						"name": "Orchid",
						"latin": "Phalaenopsis gigantea"
					},
					{
						"index": 31,
						"name": "Orchid",
						"latin": "Grandiphyllum micranthum"
					},
					{
						"index": 32,
						"name": "Silversword or ’Ahinahina",
						"latin": "Argyroxiphium sandwicense",
						"facts": "This Hawaiian plant grows for about 40 years before flowering and dying. It is endangered by climate change and invasive species."
					},
					{
						"index": 33,
						"name": "Holy wood or Roughbark lignum vitae stand",
						"latin": "Guaiacum sanctum"
					},
					{
						"index": 34,
						"name": "Barbary striped grass or zebra mouse",
						"latin": "Lemniscomys barbarus"
					},
					{
						"index": 35,
						"name": "Black-patch hairstreak",
						"latin": "Hypolycaena liara"
					},
					{
						"index": 36,
						"name": "Butterfly",
						"latin": "Cyrestis camillus elegans"
					},
					{
						"index": 37,
						"name": "Banggai cardinal fish",
						"latin": "Pterapogon kauderni"
					},
					{
						"index": 38,
						"name": "Flying frog",
						"latin": "Rhacophorus reinwardtii"
					},
					{
						"index": 39,
						"name": "Glass frog",
						"latin": "Cochranella sp."
					},
					{
						"index": 40,
						"name": "Beetle",
						"latin": "Fornasinius russus"
					},
					{
						"index": 41,
						"name": "Orchid",
						"latin": "Phragmipedium besseae"
					},
					{
						"index": 42,
						"name": "Black rhino horn",
						"latin": "Diceros bicornis",
						"facts": "Rhino horn is heavily poached for use in traditional medicines and dagger handles, though conservation efforts have increased the population to over 6,000 individuals."
					},
					{
						"index": 43,
						"name": "Orchid",
						"latin": "Oncidium leucochilum"
					},
					{
						"index": 44,
						"name": "Gouldian finch",
						"latin": "Erythrura gouldiae"
					},
					{
						"index": 45,
						"name": "Golden silk spider",
						"latin": "Trichonephila clavipes"
					},
					{
						"index": 46,
						"name": "Agreeable caterpillar hunter",
						"latin": "Calosoma sycophanta"
					},
					{
						"index": 47,
						"name": "Weevil",
						"latin": "Eupholus bennetti"
					},
					{
						"index": 48,
						"name": "Brush jewel beetle",
						"latin": "Julodis viridipes"
					},
					{
						"index": 49,
						"name": "Regal fruit chafer",
						"latin": "Rhamphorrhina splendens"
					},
					{
						"index": 50,
						"name": "Beetle",
						"latin": "Psalidognathus friendii"
					},
					{
						"index": 51,
						"name": "Beetle",
						"latin": "Sagra sp."
					},
					{
						"index": 52,
						"name": "Leafwing butterfly",
						"latin": "Anaea nessus"
					},
					{
						"index": 53,
						"name": "Common red glider butterfly",
						"latin": "Cymothoe coccinata"
					},
					{
						"index": 54,
						"name": "Redline doctor butterfly",
						"latin": "Ancyluris meliboeus"
					},
					{
						"index": 55,
						"name": "Resplendant quetzel tail feather",
						"latin": "Pharomachrus mocinno"
					},
					{
						"index": 56,
						"name": "Red-cheeked Cordon bleu",
						"latin": "Uraeginthus bengalus"
					}
				]
			}, {
				slug: 'descendant',
				key: [{
						"index": 1,
						"name": "Hawksbill sea turtle",
						"latin": "Eretmochelys imbricata"
					},
					{
						"index": 2,
						"name": "Condor egg",
						"latin": "Gymnogyps californianus"
					},
					{
						"index": 3,
						"name": "Giant kangaroo rat",
						"latin": "Dipodomys ingens"
					},
					{
						"index": 4,
						"name": "Little Kern golden trout",
						"latin": "Oncorhynchus mykiss whitei"
					},
					{
						"index": 5,
						"name": "San Francisco garter snake",
						"latin": "Thamnophis sirtalis tetrataenia"
					},
					{
						"index": 6,
						"name": "Golden toad",
						"latin": "Incilius periglenes",
						"fact": "Native to Costa Rica’s Monte Verde cloud forest, this species has not been seen since 1989. Amphibian populations have been in sharp decline due to pollution, habitat degradation, and a global epidemic of chytridiomycosis."
					},
					{
						"index": 7,
						"name": "’I’iwi",
						"latin": "Vestiaria coccinea",
						"fact": "Once found on all Hawaiian islands, the ’i’iwi has been severely impacted by habitat loss, predation by introduced species, and mosquito-borne diseases. It evolved to feed on nectar from tubular flowers."
					},
					{
						"index": 8,
						"name": "Okeechobee gourd",
						"latin": "Cucurbita okeechobeensis"
					},
					{
						"index": 9,
						"name": "Presidio manzanita",
						"latin": "Arctostaphylos montana ssp. ravenii",
						"fact": "Discovered in 1952 by Peter Raven, this subspecies is now limited to a few clones in the Presidio of San Francisco and botanical gardens. It is vulnerable to extinction from natural disasters and human error."
					},
					{
						"index": 10,
						"name": "James spinymussel",
						"latin": "Pleurobema collinae"
					},
					{
						"index": 11,
						"name": "Fat pocketbook pearly mussel",
						"latin": "Potamilus capax",
						"fact": "Freshwater mussels purify water and are dependent on host fish for reproduction. Historical overharvesting and habitat disruption have led to severe population declines."
					},
					{
						"index": 12,
						"name": "Dwarf wedgemussel",
						"latin": "Prolasmidonta heterodon"
					},
					{
						"index": 13,
						"name": "Geysers panicum",
						"latin": "Panicum acuminatum sw.var thermale"
					},
					{
						"index": 14,
						"name": "Valley oak acorn",
						"latin": "Quercus lobata"
					},
					{
						"index": 15,
						"name": "Guadalupe violet",
						"latin": "Viola guadalupensis"
					},
					{
						"index": 16,
						"name": "Missouri bladderpod",
						"latin": "Physaria filiformis"
					},
					{
						"index": 17,
						"name": "Indian Knob mountainbalm",
						"latin": "Eriodictyon altissimum"
					},
					{
						"index": 18,
						"name": "American burying beetle",
						"latin": "Nicrophorus americanus",
						"fact": "Once common in the eastern U.S., this beetle plays a role in carrion decomposition. Decline factors include habitat fragmentation, pesticide use, and competition from other scavengers."
					},
					{
						"index": 19,
						"name": "Vine Hill clarkia",
						"latin": "Clarkia imbricata"
					},
					{
						"index": 20,
						"name": "Price’s potato bean",
						"latin": "Apios priceana"
					},
					{
						"index": 21,
						"name": "Na’u",
						"latin": "Gardenia brighamii"
					},
					{
						"index": 22,
						"name": "Valley elderberry longhorn beetle",
						"latin": "Desmocerus californicus dimorphus"
					},
					{
						"index": 23,
						"name": "Baker’s stickyseed or Sonoma sunshine",
						"latin": "Blennosperma bakeri"
					},
					{
						"index": 24,
						"name": "Running buffalo clover",
						"latin": "Trifolium stoloniferum"
					},
					{
						"index": 25,
						"name": "Myrtle’s silverspot",
						"latin": "Speyeria zerene myrtleae"
					},
					{
						"index": 26,
						"name": "Laysan finch",
						"latin": "Telespiza cantans"
					},
					{
						"index": 27,
						"name": "Striped adobe lily",
						"latin": "Fritillaria striata"
					},
					{
						"index": 28,
						"name": "Schaus’ swallowtail",
						"latin": "Heraclides aristodemus"
					},
					{
						"index": 29,
						"name": "Golden-cheeked warbler",
						"latin": "Setophago chrysoparia"
					},
					{
						"index": 30,
						"name": "McFarlane’s four o’clock",
						"latin": "Mirabilis macfarlanei"
					},
					{
						"index": 31,
						"name": "Oahu tree snail",
						"latin": "Achatinella spp.",
						"fact": "With over 750 species once present in Hawaii, Achatinella is now endangered due to predation by the introduced wolf snail. Collecting for adornments and a shell craze in the 1800s also contributed to their decline."
					},
					{
						"index": 32,
						"name": "Ash Meadows sunray",
						"latin": "Enceliopsis nudicaulis"
					},
					{
						"index": 33,
						"name": "Tennessee purple coneflower",
						"latin": "Echinacea tenneseensis"
					},
					{
						"index": 34,
						"name": "Lotis blue",
						"latin": "Plebejus anna lotis"
					},
					{
						"index": 35,
						"name": "Hungerford’s crawling water beetle",
						"latin": "Brychius hungerfordi"
					},
					{
						"index": 36,
						"name": "Schweinitz’s sunflower",
						"latin": "Helianthus schweinitzeii"
					},
					{
						"index": 37,
						"name": "Shasta salamander",
						"latin": "Hydromantes shastae"
					},
					{
						"index": 38,
						"name": "Desert slender salamander",
						"latin": "Batrachoseps major"
					},
					{
						"index": 39,
						"name": "Arizona agave",
						"latin": "Agave arizonica"
					},
					{
						"index": 40,
						"name": "Sensitive joint-vetch",
						"latin": "Aeschynomene virginica"
					},
					{
						"index": 41,
						"name": "San Clemente Island woodland star",
						"latin": "Lithophragma maximum"
					},
					{
						"index": 42,
						"name": "Strohbeen’s parnassian",
						"latin": "Parnassius clodius strohbeeni",
						"fact": "Last seen in 1956 in California, this butterfly may have been pushed to extinction by rising temperatures and habitat loss due to logging and development."
					},
					{
						"index": 43,
						"name": "Black-capped vireo",
						"latin": "Vireo atricapilla"
					},
					{
						"index": 44,
						"name": "Santa Ana River woolystar",
						"latin": "Eriastrum densifolium ssp. sanctorum"
					},
					{
						"index": 45,
						"name": "Large-fruited sand verbena",
						"latin": "Abronia macrocarpa"
					},
					{
						"index": 46,
						"name": "Swamp pink",
						"latin": "Helonias bullata"
					},
					{
						"index": 47,
						"name": "Oblivious tiger beetle",
						"latin": "Cicindela latesignata obliviosa"
					},
					{
						"index": 48,
						"name": "Contra Costa wallflower",
						"latin": "Erysimum capitatum var. angustatum"
					},
					{
						"index": 49,
						"name": "Nuku pu’u",
						"latin": "Hemignathus lucidus"
					},
					{
						"index": 50,
						"name": "Western lily",
						"latin": "Lilium occidentale"
					},
					{
						"index": 51,
						"name": "Antioch Dunes shield-backed katydid",
						"latin": "Nebuda extincta"
					},
					{
						"index": 52,
						"name": "Delta green ground beetle",
						"latin": "Elaphrus viridus",
						"fact": "A resident of California’s vernal pools, this beetle’s habitat has been reduced due to development and agriculture. It now survives only in the Jepson Prairie Reserve and adjacent lands."
					},
					{
						"index": 53,
						"name": "Dehli Sands flower-loving fly",
						"latin": "Rhaphiomidas terminatus abdominalis"
					},
					{
						"index": 54,
						"name": "Western prairie fringed orchid",
						"latin": "Platanthera praeclara"
					},
					{
						"index": 55,
						"name": "Eastern prairie fringed orchid",
						"latin": "Platanthera leucophaea"
					},
					{
						"index": 56,
						"name": "White sedge",
						"latin": "Carex albida"
					},
					{
						"index": 57,
						"name": "El Segundo blue",
						"latin": "Euphilotes battoides allyni"
					},
					{
						"index": 58,
						"name": "Mission blue",
						"latin": "Icaricia icarioides missionensis"
					},
					{
						"index": 59,
						"name": "‘Akepa",
						"latin": "Loxops coccineus"
					},
					{
						"index": 60,
						"name": "San Francisco forktail damsel fly",
						"latin": "Ischnura gemina"
					},
					{
						"index": 61,
						"name": "San Bruno elfin",
						"latin": "Callophrys mossii bayensis"
					}
				]
			}, {
				slug: 'gone',
				keyAddition: "* Critically endangered <br/><br/>** Refound",
				key: [{
						"index": 1,
						"name": "Punaluu Cyanea*",
						"latin": "Cyanea truncata"
					},
					{
						"index": 2,
						"name": "Hawaii Mamo",
						"latin": "Drepanis pacifica"
					},
					{
						"index": 3,
						"name": "Ula-ai-hawane",
						"latin": "Ciridops anna"
					},
					{
						"index": 4,
						"name": "Hawaii ‘O’o",
						"latin": "Moho nobilis"
					},
					{
						"index": 5,
						"name": "Polynesian tree snail",
						"latin": "Partula bilineata"
					},
					{
						"index": 6,
						"name": "Tree snail",
						"latin": "Partula radiolata"
					},
					{
						"index": 7,
						"name": "Passenger pigeon",
						"latin": "Ectopistes migratorius"
					},
					{
						"index": 8,
						"name": "Land snail",
						"latin": "Achatinella buddii"
					},
					{
						"index": 9,
						"name": "Waihanan Lobelia*",
						"latin": "Lobelia dunbariae"
					},
					{
						"index": 10,
						"name": "Stenogyne",
						"latin": "Stenogyne haliakalae"
					},
					{
						"index": 11,
						"name": "Tree snail",
						"latin": "Achatinella papyracea"
					},
					{
						"index": 12,
						"name": "Bachman’s warbler",
						"latin": "Vermivora bachmanii"
					},
					{
						"index": 13,
						"name": "Paradise parrot",
						"latin": "Psephotellus pulcherrimus"
					},
					{
						"index": 14,
						"name": "Trans-Pecos paintbrush**",
						"latin": "Castilleja nervata"
					},
					{
						"index": 15,
						"name": "Hawaii ‘Akialoa",
						"latin": "Akialoa obscura",
						facts: "Both the Polynesian and Latin names of this honeycreeper mean “curvebill.” All of the honeycreepers of the Hawaiian Island chain evolved to fill highly specialized niches: the ’akialoa’s deeply curved bill was perfectly suited to drink nectar from the long corolla of native lobelia flowers. As deforestation and invasive plants from the mainland replaced the lobelias and other endemic flora once common to the Hawaiian Islands, bird species vanished. The Nature Conservancy estimates that one-half to two-thirds of all living things native to the Hawaiian Islands are in jeopardy or gone. Though there were once ’akialoa subspecies on four of the islands, the last of them died on Kauai in 1965."
					},
					{
						"index": 16,
						"name": "Tree snail",
						"latin": "Partula formosa"
					},
					{
						"index": 17,
						"name": "Carolina parakeet",
						"latin": "Conuropsis carolinensis",
						facts: "The only parrots native to eastern North America, Carolina parakeets were very sociable birds: flocking was central to their way of life, much as it was to the passenger pigeon. As Europeans colonized the eastern states, the birds quickly adapted to farmed grains and orchard fruits over wild sources. The settlers, who shot them to protect their crops, considered them pests and described the parakeets’ feeding habits as “particularly raucous and wantonly destructive.” These gregarious creatures had the unfortunate habit of shrieking and circling over their dead comrades, making it easy for angry settlers to decimate an entire flock. The birds were eradicated incrementally, locality by locality. For roost and nest the parakeets favored the big, old, hardwood trees of bottomland habitats. These trees were often the first to be cut for timber to build barns and cabins on newly settled land. The last wild parakeet was shot in 1913 and the last captive died in a zoo in 1918."
					},
					{
						"index": 18,
						"name": "Mann’s Island Asplenium",
						"latin": "Spleenwort dielmannii"
					},
					{
						"index": 19,
						"name": "Island grapefern",
						"latin": "Botrychium subbifoliatum"
					},
					{
						"index": 20,
						"name": "Maui love grass",
						"latin": "Eragrostis mauiensis"
					},
					{
						"index": 21,
						"name": "Gould’s emerald",
						"latin": "Riccordia elegans"
					},
					{
						"index": 22,
						"name": "Jamaican giant galliwasp",
						"latin": "Celestus occiduus"
					},
					{
						"index": 23,
						"name": "Laughing owl",
						"latin": "Sceloglaux albifacies"
					},
					{
						"index": 24,
						"name": "Southern gastric brooding frog",
						"latin": "Rheobatrachus silus"
					},
					{
						"index": 25,
						"name": "Rodrigues blue-dotted day gecko",
						"latin": "Phelsuma edwardnewtoni"
					},
					{
						"index": 26,
						"name": "Bluebuck horns",
						"latin": "Hippotragus leucophaeus",
						facts: "This heavyset antelope was the first documented African species to go extinct from over-hunting. The Portuguese, first inhabited the cape of South Africa in the 1600s and each successive wave of European settlers also valued the buck’s blue tinged pelt and plentiful meat. As the number of guns proliferated, the number of bluebucks plummeted and all were gone completely by the early 1800s."
					},
					{
						"index": 27,
						"name": "Falls of the Ohio scurfpea",
						"latin": "Orbexilum stipulatum"
					},
					{
						"index": 28,
						"name": "White-footed rabbit-rat",
						"latin": "Conilurus albipes"
					},
					{
						"index": 29,
						"name": "Chatham Islands rail",
						"latin": "Cabalus modestus"
					},
					{
						"index": 30,
						"name": "Tree snail",
						"latin": "Achatinella abbreviata"
					},
					{
						"index": 31,
						"name": "Golden toad",
						"latin": "Incilius periglenes"
					},
					{
						"index": 32,
						"name": "Arabian ostrich egg",
						"latin": "Struthio camelus syriacus"
					},
					{
						"index": 33,
						"name": "Cuban begonia",
						"latin": "Begonia cowellii"
					},
					{
						"index": 34,
						"name": "Amistad gambusia",
						"latin": "Gambusia amistadensis",
						facts: "This little fish used to inhabit Goodenough Springs, which once fed into the Rio Grande in Texas. The entire area of the spring was inundated with the creation of the Amistad Reservoir in 1968. Gambusia amistadensis were collected from the wild prior to the dam’s completion and captive populations were maintained at two aquariums until the late 1970s: one at Brackenridge Field Laboratory at the University of Texas in Austin and one at Dexter National Fish Laboratory in southeastern New Mexico. “Hatchery errors” occurred simultaneously at both labs and by 1980, all of the fish in both populations had died. The Amistad gambusia’s fate is an often-cited cautionary tale on the need for redundancy, data sharing, long-term monitoring, and maintenance of captive populations of endangered species."
					},
					{
						"index": 35,
						"name": "Nearby pearly mussel",
						"latin": "Epioblasma propinqua"
					},
					{
						"index": 36,
						"name": "Arc-form pearly mussel",
						"latin": "Epioblasma arcaeformis"
					},
					{
						"index": 37,
						"name": "Fresh water mussel",
						"latin": "Pleurobema trochelianum"
					},
					{
						"index": 38,
						"name": "Fresh water mussel leafshell",
						"latin": "Epioblasma flexuosa"
					},
					{
						"index": 39,
						"name": "Narrow catspaw",
						"latin": "Epioblasma lenoir"
					},
					{
						"index": 40,
						"name": "Acopnshell",
						"latin": "Epioblasma haysiana"
					},
					{
						"index": 41,
						"name": "Xerces blue",
						"latin": "Glaucopsyche lygdamus xerces"
					},
					{
						"index": 42,
						"name": "New Zealand grayling",
						"latin": "Prototroctes oxyrhynchus"
					},
					{
						"index": 43,
						"name": "Blackfin cisco",
						"latin": "Coregonus nigripinnis"
					},
					{
						"index": 44,
						"name": "Clear Lake splittail",
						"latin": "Pogonichthys ciscoides"
					},
					{
						"index": 45,
						"name": "Thicktail chub",
						"latin": "Gila crassicauda",
						facts: "Evidence of these tasty fish is abundant in the refuse middens of native Californians. In the 1800s, they were common in the fish markets of San Francisco, and were served in saloons in Sacramento for only five cents. In addition to fishing pressure, the chub’s extinction was driven by extreme habitat modification of the Central Valley such as: water diversion for irrigation, reduced stream flows, drainage of large shallow lakes, removal of tule beds, and river channelization for easier ship passage. The introduction of voracious striped bass and largemouth bass for sport fishery sealed the chub’s doom. By the 1970s, the species was gone."
					},
					{
						"index": 46,
						"name": "Desert bandicoot",
						"latin": "Perameles eremiana"
					},
					{
						"index": 47,
						"name": "Polynesian tree snail",
						"latin": "Partula remota"
					},
					{
						"index": 48,
						"name": "Hula painted frog",
						"latin": "Latonia nigriventer",
						facts: "Hula Lake lies just 20 miles north of the Sea of Galilee on the border between Israel and Syria. This frog, unique to the swampy wetlands on the eastern side of the lake, was discovered in 1940. In the 1950s, 14,036 acres of wetlands were converted to agricultural use to feed the growing population of settlers in Israel. Although 791 acres of the original wetlands were set aside as a preserve, this frog and other species endemic to the area have not been seen since 1955."
					},
					{
						"index": 49,
						"name": "Pringle’s monardella",
						"latin": "Monardella pringlei"
					},
					{
						"index": 50,
						"name": "Great argus pheasant feather",
						"latin": "Argus bipunctatus"
					},
					{
						"index": 51,
						"name": "Darwin’s Galapagos mouse",
						"latin": "Nesoryzomys darwini"
					},
					{
						"index": 52,
						"name": "Stephens island rockwren",
						"latin": "Traversia lyalli"
					},
					{
						"index": 53,
						"name": "Rocks phyllostegia",
						"latin": "Phyllostegia rockii"
					},
					{
						"index": 54,
						"name": "Pink-headed duck egg",
						"latin": "Rhodonessa caryophyllacea"
					},
					{
						"index": 55,
						"name": "Aldabran brush warbler egg",
						"latin": "Nesillas aldabrana"
					},
					{
						"index": 56,
						"name": "Mariposa lily",
						"latin": "Calochortus monanthus"
					},
					{
						"index": 57,
						"name": "Long-tailed hopping mouse",
						"latin": "Notomys longicaudatus"
					},
					{
						"index": 58,
						"name": "Tree snail",
						"latin": "Achatinella valida"
					},
					{
						"index": 59,
						"name": "Great auk egg",
						"latin": "Penguinus impennis"
					},
					{
						"index": 60,
						"name": "Tree snail",
						"latin": "Achatinella elegans"
					},
					{
						"index": 61,
						"name": "Tasmanian tiger skull",
						"latin": "Thylacinus cynocephalus",
						facts: "Unique to Tasmania, this marsupial carnivore was first described as having “the head and teeth of a wolf, the stripes of a tiger, the tail of a kangaroo, and the pouch of a possum.” Like all other creatures called “wolf,” the thylacine had a reputation for blood lust and senseless killing. But in fact, there are no recorded attacks on humans, and fewer sheep were killed annually by thylacines than by the settlers’ own dogs. Nevertheless, the Tasmanian government and the Van Dieman’s Land Company instituted a bounty system for dead thylacines. Between 1888 and 1914, 2,268 carcasses were turned in for payment, though the actual number of animals killed was probably much higher. Distemper from domestic dogs ran through the remaining population. Relentlessly hunted, snared, baited, and trapped for 120 years, the last thylacine died at the Hobart Zoo in 1933. Much too late to save the species, the government finally gave the Tasmanian tiger full protection in 1936."
					},
					{
						"index": 62,
						"name": "Turquoise-throated puffleg",
						"latin": "Eriocnemis godini"
					},
					{
						"index": 63,
						"name": "Polynesian tree snail",
						"latin": "Partula nodosa"
					}
				]
			}, {
				slug: 'trade',
				key: [{
						"index": 1,
						"name": "Elephant ivory",
						"latin": "Loxodonta africana"
					},
					{
						"index": 2,
						"name": "Clouded leopard skin",
						"latin": "Neofelis nebulosa"
					},
					{
						"index": 3,
						"name": "Northern flying squirrel",
						"latin": "Glaucomys sabrinus fuscus"
					},
					{
						"index": 4,
						"name": "Golden-headed lion tamarin",
						"latin": "Leontopithecus chrysomelas",
						facts: "By the 1970’s there were only about 200 of these beautiful little Brazilian tamarins living in the wild in very fragmented habitat. By 2023, a new field survey estimated the total population to be 4,000. Worldwide 150 zoos participate in a cooperative program to breed this species. Some are still being trapped for the pet market. When a population falls below a certain critical point, it can become too inbred. In a primate like the tamarin, which produces only a few offspring in its lifetime, genetic traits that would have remained recessive under normal circumstances can quickly become dominant in a small breeding group. Each successive generation becomes weaker and more vulnerable. Individuals of a species as close to extinction as this tamarin that are kept in private hands, and thus out of the worldwide breeding population, endanger the survivability of the species as a whole."
					},
					{
						"index": 5,
						"name": "Emerald",
						"latin": "Varanus prasinus"
					},
					{
						"index": 6,
						"name": "Satanic leaf-tailed gecko",
						"latin": "Uroplatus phantasticus"
					},
					{
						"index": 7,
						"name": "Yellow-headed day gecko",
						"latin": "Phelsuma klemmeri",
						facts: "Madagascar is a source of many exotic reptiles found nowhere else, including two-thirds of the world’s chameleon species, the leaf-nosed snake, the leaf-tailed gecko, the colorful day gecko, and the tiny Brookesia gecko. Middlemen will pay boys in Madagascar a quarter apiece for leaf-tailed geckos, while in the West, a pair may sell for up to $250. A Phelsuma gecko might cost $1.20 in Madagascar, and bring $70 to $100 in the United States. A juvenile radiated tortoise might sell to a middleman for $3.60, but a distinctively marked adult female can fetch $10,000 on the black market. The economics of this trade are simple and compelling. The annual income in Madagascar for the year 2023 was $460."
					},
					{
						"index": 8,
						"name": "Radiated tortoise",
						"latin": "Astrochelys radiata"
					},
					{
						"index": 9,
						"name": "Golden dart frog",
						"latin": "Phyllobates terribilis"
					},
					{
						"index": 10,
						"name": "Madagascan tree boa",
						"latin": "Sanzinia madagascariensis"
					},
					{
						"index": 11,
						"name": "Hawksbill sea turtle shell",
						"latin": "Eretmochelys imbricata"
					},
					{
						"index": 12,
						"name": "Ruby poison frog",
						"latin": "Ameerega parvula"
					},
					{
						"index": 13,
						"name": "Dwarf leaf chameleon",
						"latin": "Brookesia peyrierasi"
					},
					{
						"index": 14,
						"name": "Gurney’s pitta",
						"latin": "Pitta gurneyi"
					},
					{
						"index": 15,
						"name": "Blue lorikeet",
						"latin": "Vini peruviana"
					},
					{
						"index": 16,
						"name": "Red siskin",
						"latin": "Spinus cucullatus"
					},
					{
						"index": 17,
						"name": "Lear’s or Indigo macaw",
						"latin": "Anodorhynchus leari",
						facts: "Most of the world’s 16 macaw species are listed as “globally threatened” by Bird Life International. The greatest threat to their survival is deforestation; but there is also heavy pressure from the caged-bird trade. The Lear’s macaw, more intensely aqua blue and a bit smaller than the more common hyacinth macaw, is one of the most prized animals on the black market. In a smuggling case in Britain in 2005, it was reported that a pair of Lear’s macaws could bring $88,500 on the black market. This macaw was thought extinct in the wild until 1978, when two small populations were found in eastern Brazil. Perhaps Lear’s macaw has always been sparsely dispersed in its habitat. As of 2018, there were thought to be 1,700 birds left in the wild."
					},
					{
						"index": 18,
						"name": "Toucan barbet",
						"latin": "Semnornis ramphastinus"
					},
					{
						"index": 19,
						"name": "Seven-colored tanager",
						"latin": "Tangara fastuosa"
					},
					{
						"index": 20,
						"name": "Atlantic trumpet triton",
						"latin": "Charonia tritonis"
					},
					{
						"index": 21,
						"name": "Glory of the sea cone",
						"latin": "Conus gloriamaris"
					},
					{
						"index": 22,
						"name": "Loebbecke’s murex",
						"latin": "Chicoreus loebbeckei"
					},
					{
						"index": 23,
						"name": "Golden cowrie",
						"latin": "Callisto aurantium"
					},
					{
						"index": 24,
						"name": "Red coral",
						"latin": "Corallium rubrum"
					},
					{
						"index": 25,
						"name": "Fluted giant clam",
						"latin": "Tridacna squamosa"
					},
					{
						"index": 26,
						"name": "Androgeus swallowtail",
						"latin": "Papilio androgeus",
						facts: "The swallowtail depicted here is a gynandromorph, an individual that exhibits both male and female characteristics. These hermaphroditic butterflies are quite rare and command high prices. Butterflies are so exquisite to contemplate that they are popular as a décor item. An exceptionally large, all-yellow Ornithoptera meridionalis recently sold over the Internet for $5,000. Butterfly diversity and plentitude are good indicators of general habitat health. While the main cause of butterfly decline worldwide is deforestation, trade threatens groups such as birdwing butterflies because they lay relatively few eggs. Captive rearing provides specimens of some species for the dead-stock trade."
					},
					{
						"index": 27,
						"name": "Queen Alexandra’s birdwing",
						"latin": "Ornithoptera alexandrae"
					},
					{
						"index": 28,
						"name": "Jamaican swallowtail",
						"latin": "Papilio homerus"
					},
					{
						"index": 29,
						"name": "Luzon peacock swallowtail",
						"latin": "Papilio chikae"
					},
					{
						"index": 30,
						"name": "Madagascan moon moth",
						"latin": "Argema mittrei"
					},
					{
						"index": 31,
						"name": "Sunset moth",
						"latin": "Chrysiridia riphearia"
					},
					{
						"index": 32,
						"name": "Agrias butterfly",
						"latin": "Agrias claudia claudia",
						facts: "The habitat of these gorgeous, much sought-after butterflies overlaps with the coca growing regions of Brazil and Peru. Agrias larvae live on wild relatives of the plants grown for cocaine production. In the 1990s the United States Drug Enforcement Agency planned an anti-coca program dusting drug crops with a fungus called Fusarium oxysporum. This pathogen is known to be toxic to humans and has a very high mutagenicity rate. A diverse group of international nonprofit organizations including the Sierra Club and Greenpeace, protested Fusarium use, calling it a biological weapon. Fortunately, President Clinton scrapped the plan. Agrias butterflies could have been just one of the many potential collateral victims of this type of indiscriminate herbicide use."
					},
					{
						"index": 33,
						"name": "Ceylon tree nymph",
						"latin": "Idea jasonia"
					},
					{
						"index": 34,
						"name": "Wallace’s sphinx moth",
						"latin": "Xanthopan praedicta",
						facts: "When Darwin saw the comet orchid Angraecum sesquipedale with its foot long nectary, he postulated that there must be a creature that had a proboscis, or sipper, long enough to get at the nectar and thereby pollinate the flower. This long tongued moth was discovered in Madagascar  and described in 1903, fulfilling both Darwin and Alfred Russel Wallace’s prediction of its existence.  In 1990 pollination of the orchid by the moth was finally observed and photographed."
					},
					{
						"index": 35,
						"name": "Atlas beetle",
						"latin": "Chalcosoma atlas"
					},
					{
						"index": 36,
						"name": "Beyer’s jewel scarab",
						"latin": "Chrysina beyeri"
					},
					{
						"index": 37,
						"name": "Golden jewel scarab",
						"latin": "Chrysina resplendens"
					},
					{
						"index": 38,
						"name": "Ceiba borer",
						"latin": "Euchroma gigantea"
					},
					{
						"index": 39,
						"name": "European stag beetle",
						"latin": "Lucanus cervus"
					},
					{
						"index": 40,
						"name": "Alpine longhorn rosahina",
						"latin": "Rosalia alpina"
					},
					{
						"index": 41,
						"name": "Golden ground beetle",
						"latin": "Carabus (Tachypus) auratus"
					},
					{
						"index": 42,
						"name": "Silver jewel scarab",
						"latin": "Chrysina chrysargyrea"
					},
					{
						"index": 43,
						"name": "Jewel scarab",
						"latin": "Chrysina aurigans"
					},
					{
						"index": 44,
						"name": "Orchid mantis",
						"latin": "Hymenopus coronatus"
					},
					{
						"index": 45,
						"name": "Sander’s paphiopedilum",
						"latin": "Paphiopedilum sanderianum",
						facts: "The Convention on International Trade in Endangered Species, or CITES, is the organization that classifies plants’ and animals’ international trade status. CITES regulations and permits are intended  to help curb black market traffic. When an orchid becomes the rage of the moment, the value skyrockets and wild specimens become vulnerable to theft and poachers. However, the greatest threat by far to the survival of wild orchid populations is habitat destruction. Conservationists often do not receive getting CITES permits in time to remove plants to save them from large scale operations such as logging, mining, road building, and dam construction, particularly in remote areas. Commercial cultivation is one strategy to reduce collection pressure on wild populations, however, some orchids prove to be very difficult to propagate, and wild populations get depleted in the attempt. There is heated and passionate controversy within the orchid community on how best to foster both the protection and cultivation of orchids."
					},
					{
						"index": 46,
						"name": "Ghost orchid",
						"latin": "Dendrophylax lindenii"
					},
					{
						"index": 47,
						"name": "Red vanda",
						"latin": "Renanthera imschootiana"
					},
					{
						"index": 48,
						"name": "Lawrence’s paphiopedilum",
						"latin": "Paphiopedilum lawrenceanum"
					},
					{
						"index": 49,
						"name": "Darwin’s orchid",
						"latin": "Angraecum sesquipedale"
					},
					{
						"index": 50,
						"name": "Apricot orange paphiopedilum",
						"latin": "Paphiopedilum armeniacum"
					},
					{
						"index": 51,
						"name": "Gray’s lily",
						"latin": "Lilium grayi"
					},
					{
						"index": 52,
						"name": "Mountain sweet pitcher-plant",
						"latin": "Sarracenia jonesii"
					},
					{
						"index": 53,
						"name": "American ginseng",
						"latin": "Panax quinquefolius"
					},
					{
						"index": 54,
						"name": "Monkey-puzzle wood",
						"latin": "Araucaria araucana"
					},
					{
						"index": 55,
						"name": "Rainbow plant",
						"latin": "Byblis gigantea"
					},
					{
						"index": 56,
						"name": "Large-leaved pitcher plant",
						"latin": "Nepenthes macrophylla"
					},
					{
						"index": 57,
						"name": "West Asian iris",
						"latin": "Iris paradoxa"
					},
					{
						"index": 58,
						"name": "Patagonian cypress wood",
						"latin": "Fitzroya cupressoides"
					},
					{
						"index": 59,
						"name": "Coca",
						"latin": "Erythroxylum coca"
					},
					{
						"index": 60,
						"name": "Rose periwinkle",
						"latin": "Catharanthus roseus"
					},
					{
						"index": 61,
						"name": "Venus flytrap",
						"latin": "Dionaea muscipula"
					},
					{
						"index": 62,
						"name": "Triangle palm seeds",
						"latin": "Dypsis decaryi"
					},
					{
						"index": 63,
						"name": "Nellie corycactus",
						"latin": "Pelecyphora minima"
					},
					{
						"index": 64,
						"name": "False peyote",
						"latin": "Lophophora diffusa"
					},
					{
						"index": 65,
						"name": "Jabali pincushion cactus",
						"latin": "Coryphantha werdermannii"
					},
					{
						"index": 66,
						"name": "American Matsutake",
						"latin": "Tricholoma magnivelare"
					},
					{
						"index": 67,
						"name": "White truffle",
						"latin": "Tuber magnatum"
					},
					{
						"index": 68,
						"name": "Common green birdwing (caterpillar)",
						"latin": "Ornithoptera priamus"
					}
				]
			}, {
				slug: 'understory',
				key: [{
						"index": 1,
						"name": "Dartwhite butterfly",
						"latin": "Catasticta thomasorum (Ecuador, 1998)"
					},
					{
						"index": 2,
						"name": "Passion lower",
						"latin": "Passiflora luzmarina (Ecuador, 1997)"
					},
					{
						"index": 3,
						"name": "Pernambuco pygmy-owl",
						"latin": "Glaucidium mooreorum (Brazil, 2003)",
						facts: "To a virtuoso violinist like Joshua Bell, his Stradivarius would not sound as good without his 200-year old, $40,000 bow. Fine bows like his are made from pernambuco, the heartwood of the pau brasil tree. This tree’s habitat is Brazil’s Atlantic Forest, which is home as well to Glaucidium mooreorum. This new species of pygmy-owl is named for Gordon and Betty Moore, generous supporters of research in biodiversity and many other causes. Unfortunately, the survival of both the pau brasil and the Pernambuco pygmy-owl is in question, as Brazil’s population has encroached on coastal forests and harvested the tree for fence posts, firewood and other uses. The pau-brasil recently received trade protection under CITES, the Convention on International Trade in Endangered Species, but most of the areas of forest that remain are small, isolated, and difficult to protect."
					},
					{
						"index": 4,
						"name": "Bush-cricket",
						"latin": "Rhomboptera selangorensis (Malaysia, 2005)"
					},
					{
						"index": 5,
						"name": "El Oro parakeet",
						"latin": "Pyrrhura orcesi (Ecuador, 1988)",
						facts: "The story of the discovery of this bird in Ecuador demonstrates how surprises can be hidden in museum collections. In 1980, a field team observed some unrecognizable parakeets in the cloud forest of southwestern Ecuador. Five years later, a second expedition made further observations and collected some specimens, as a probable new species. Pyrrhura orcesi’s formal description was published in 1988, using the recently-collected birds as the type specimens. (A type specimen is the example to which other birds are compared.) However during the research that took place between discovery and description, a lone example of this ‘new’ species was found in the ornithological collections of Britain’s Natural History Museum, quietly unrecognized amongst a closely-related group. This museum specimen, a male, had in fact been collected in 1939. Of particular interest is that it was found well below the altitude at which the ‘new’  birds were encountered a possible indication that the species’ habitat has contracted. The El Oro parakeet is still only known in a very restricted range, and is classified as endangered."
					},
					{
						"index": 6,
						"name": "Butterfly",
						"latin": "Tithorea pacifica (Ecuador, 2004)"
					},
					{
						"index": 7,
						"name": "Rock firefinch",
						"latin": "Lagonosticta sanguinodorsalis (Nigeria, 1998)"
					},
					{
						"index": 8,
						"name": "Lina’s sunbird",
						"latin": "Aethopyga linaraborae (Philippines, 1997)"
					},
					{
						"index": 9,
						"name": "Flowering tree",
						"latin": "Toussaintia patriciae (Tanzania, 2005)"
					},
					{
						"index": 10,
						"name": "Butterfly",
						"latin": "Actinote eberti (Brazil, 2004)"
					},
					{
						"index": 11,
						"name": "White-eared tree frog",
						"latin": "Feihyla kajau (Borneo, 1984)"
					},
					{
						"index": 12,
						"name": "Monywa bent-toed gecko",
						"latin": "Cyrtodactylus slowinskii (Myanmar, 2002)",
						facts: "Why do we study such seemingly inconsequential animals? For the important lessons they can teach us. Since the fifth century BC, when Aristotle noted how a gecko “can run up and down a tree in any way, even with the head downward,” we have wondered how geckos defy gravity. It’s not because their feet are sticky—they are dry and smooth to the touch. These tiny appendages owe their remarkable adhesion to microscopic, spatula-tipped filaments (some 2 billion per square centimeter) on their toe pads. Each filament, at only a hundred nanometers thick, is so fine that it interacts at the molecular level with the surface on which the gecko walks. One of the reasons biodiversity is so important is the ways in which complex designs found in nature, like sticky gecko’s feet or hydrodynamic shark skin, can be used to solve problems in engineering, materials science, medicine, and other fields."
					},
					{
						"index": 13,
						"name": "Green bush frog",
						"latin": "Philautus bunitus (Borneo, 1995)"
					},
					{
						"index": 14,
						"name": "Roosmalens’ dwarf marmoset",
						"latin": "Mico humilis (Brazil, 1998)"
					},
					{
						"index": 15,
						"name": "False Malabar gliding frog",
						"latin": "Rhacophorus pseudomalabaricus (India, 2000)"
					},
					{
						"index": 16,
						"name": "Butterfly",
						"latin": "Zaretis pythagoras (Panama, 2001)"
					},
					{
						"index": 17,
						"name": "Babbler",
						"latin": "Liocichla bugunorum (India, 2006)"
					},
					{
						"index": 18,
						"name": "Saturniid moth",
						"latin": "Actias angulocaudata (China, 1998)"
					},
					{
						"index": 19,
						"name": "Suntiger tarantula",
						"latin": "Psalmopoeus irminia (Venezuela, 1994)"
					},
					{
						"index": 20,
						"name": "Roosmalens’ dwarf porcupine",
						"latin": "Coendou roosmalenorum (Brazil, 2001)",
						facts: "The tree porcupine in the lower left and the titi monkey to the right of it were both found by Marc van Roosmalen, a taxonomist and conservationist who has spent much of his life working in the Amazon. The tree porcupine was described by Marc, along with his son Tomas, who has also become a scientist. The problems that many environmental workers face as they confront the consequences of rapid, destructive change are well illustrated by Marc’s current plight: in his haste to work rapidly, he got into trouble with the state of Brazil for failing to comply precisely with state protocols and paperwork. He has been charged with “bio-piracy.” At the time of this writing he has been released from jail while his case is on appeal."
					},
					{
						"index": 21,
						"name": "Three-banded flying gecko",
						"latin": "Gekko trinotaterra (Vietnam/Thailand, 1999)"
					},
					{
						"index": 22,
						"name": "Saturniid moth",
						"latin": "Copaxa bella (Peru, 2005)"
					},
					{
						"index": 23,
						"name": "Hasenpusch family stick insect (*new genus)",
						"latin": "Parapodacanthus hasenpuschorum (Australia, 2003)"
					},
					{
						"index": 24,
						"name": "Butterfly",
						"latin": "Papilio hermeli (Philippines, 1992)"
					},
					{
						"index": 25,
						"name": "Panay bushy-tailed cloud rat",
						"latin": "Crateromys heaneyi (Philippines, 1996)"
					},
					{
						"index": 26,
						"name": "Spitting spider",
						"latin": "Scytodes mapinguari (Brazil, 2004)"
					},
					{
						"index": 27,
						"name": "Butterfly",
						"latin": "Delias hidecoae (Philippines, 1993)"
					},
					{
						"index": 28,
						"name": "Golden legged bush frog",
						"latin": "Philautus aurantium (Borneo, 1989)"
					},
					{
						"index": 29,
						"name": "Hesterburg’s sister",
						"latin": "Delias nuydaorum (Philippines, 1975)"
					},
					{
						"index": 30,
						"name": "Butterfly",
						"latin": "Adelpha hesterbergi (Costa Rica, 1999)"
					},
					{
						"index": 31,
						"name": "Black-crowned barwing",
						"latin": "Actinodura sodangorum (Vietnam, 1999)"
					},
					{
						"index": 32,
						"name": "Moon-gentian",
						"latin": "Macrocarpaea apparata (Ecuador, 2001)",
						facts: "This three to four meter tall tree grows in the hills of Ecuador. When researchers Lena Struwe and Jason Grant first saw it, they were certain it was something new—probably in the Gentian family. In order to confirm this hypothesis, however, they had to locate and collect specimens from a plant in flower. As darkness approached, the cold, wet, and discouraged botanists suddenly saw some white flowers that seemed to “apparate,” like Harry Potter, out of the mist. The ghostly, almost magical nature of the flower’s appearance is immortalized in this tree’s Latin name. The night blooming, yellowish-white, bell shaped blossoms suggest pollination by moths, bats, or other small nocturnal mammals."
					},
					{
						"index": 33,
						"name": "Mekong wagtail",
						"latin": "Motacilla samveasnae (Cambodia, 2001)"
					},
					{
						"index": 34,
						"name": "Carrizal seedeater",
						"latin": "Amaurospiza carrizalensis (Venezuela, 2003)"
					},
					{
						"index": 35,
						"name": "Palawen gliding dragon",
						"latin": "Draco palawanensis (Philippines, 1998)"
					},
					{
						"index": 36,
						"name": "Madame Berthe’s mouse lemur",
						"latin": "Microcebus berthae (Madagascar, 2000)",
						facts: "In the upper middle of the picture, an animal makes direct eye contact with you: a representative of a newly discovered species of mouse lemur. Lemurs are primates found only on the island nation of Madagascar and the nearby Comoro Islands. Microcebus berthae was described by Steve Goodman of the Field Museum and named in honor of Madame Berthe Rakotosamimanana. “Madame Berthe,” as she was known to hundreds of foreign researchers and Malagasy university students, was the Coordinator of Research Activities in Kirindy forest and a major force in the advancement of Malagasy zoology. Sadly, she died unexpectedly in November of 2005."
					},
					{
						"index": 37,
						"name": "Long horn giant stick insect",
						"latin": "Bactrododema krugeri (South Africa, 2004)"
					},
					{
						"index": 38,
						"name": "Twin-spotted flying frog",
						"latin": "Rhacophorus bipunctatas (Myanmar, 2005)"
					},
					{
						"index": 39,
						"name": "Chiribiquete emerald",
						"latin": "Chlorostilbon olivaresi (Columbia, 1996)"
					},
					{
						"index": 40,
						"name": "Otuo bamboo pitviper",
						"latin": "Trimeresurus medoensis (Myanmar, 1977)"
					},
					{
						"index": 41,
						"name": "Orchid bee",
						"latin": "Euglossa paisa (Columbia, 2005)"
					},
					{
						"index": 42,
						"name": "Stephen Nash’s titi",
						"latin": "Plecturocebus stephennashi (Brazil, 2002)"
					},
					{
						"index": 43,
						"name": "Lacewing (*new genus)",
						"latin": "Adamsiana curoei (Honduras, 1996)"
					},
					{
						"index": 44,
						"name": "Orange-breasted forest robin",
						"latin": "Stiphrornis crythrothorax sanghensis (Central African Republic, 1999)"
					},
					{
						"index": 45,
						"name": "Inger’s bubble nest frog",
						"latin": "Philautus ingeri (Borneo, 1987)"
					},
					{
						"index": 46,
						"name": "Kohil’s atlas moth",
						"latin": "Samia kohlli (Malaysia, 2001)"
					},
					{
						"index": 47,
						"name": "Camiguin hanging-parrot",
						"latin": "Loriculus camiguinensis (Philippines, 2006)"
					},
					{
						"index": 48,
						"name": "Purple spotted swallowtail",
						"latin": "Graphium weiskei (Papua New Guinea, 1999)"
					},
					{
						"index": 49,
						"name": "Brosset’s big-eared bat",
						"latin": "Micronycteris brosseti (French Guiana, 1998)"
					},
					{
						"index": 50,
						"name": "Farmland green flying frog",
						"latin": "Zhangixalus arvalis (Taiwan, 1995)"
					},
					{
						"index": 51,
						"name": "Spitting spider",
						"latin": "Scytodes altamira (Brazil, 2000)"
					},
					{
						"index": 52,
						"name": "Gladiator bug (*new order)",
						"latin": "Tyrannophasma gladiator (Namibia, 2003)"
					},
					{
						"index": 53,
						"name": "Myanmar spotted tree frog",
						"latin": "Chiromontis punctatus (Myanmar, 2003)"
					},
					{
						"index": 54,
						"name": "Lulu’s tody-tyrant",
						"latin": "Poecilotriccus luluae (Peru, 2001)"
					},
					{
						"index": 55,
						"name": "Sommer’s Sulawesi rat (*new genus)",
						"latin": "Sommeromys macrorhinos (Sulawesi, 2002)"
					},
					{
						"index": 56,
						"name": "Sucking louse",
						"latin": "Hoplopleura sommeri (Sulawesi, 2002)"
					},
					{
						"index": 57,
						"name": "Scarlet-banded barbet",
						"latin": "Capito wallacei (Peru, 2000)"
					},
					{
						"index": 58,
						"name": "Appalachian tiger swallowtail",
						"latin": "Papilio appalachiensis (North Carolina, USA, 2002)"
					}
				]
			}]
		}
	]
}