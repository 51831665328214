export default {
    categories: [{
        order: 1,
        hero: 3,
        name: 'Nova',
        slug: 'nova',
        date: '2007-2011',
        description: "The Nova depicts flora and fauna that were new to the scientific literature at the time of the research for and execution of the artworks. Each of the four canvases in this series represents one of the strata of a typical mid-elevation tropical rainforest: forest floor, understory, canopy, or emergent layer. Actually, the plants and animals depicted are from many countries and would never be seen together or all at once, so the ecosystems pictured here are metaphoric. Published descriptions, specimens, drawings, and other artists’, scientists', and photographers’ images have all served as reference for the examples included here. <br/><br/>These paintings provide a glimpse of the amazing diversity of plants and animals still to be found, whether in the remains of the world's wildlands or in our own backyards. While this story of discovery and change seems hopeful, often these creatures survive only in a remnant habitat in rapid decline, often both in quality and quantity. As John C. Sawhill, a past president of the Nature Conservancy once said, “In the end, our society will be defined not only by what we create, but by what we refuse to destroy.”",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Forest Floor",
            slug: "forest-floor",
            year: "2007", 
            medium: "Oil on polyester over panel", 
            dimensions: "36” x 60”",
            description: "In general, the soil in an environment such as the one pictured here—a stream habitat on the floor of a mature rainforest—is shallow and nutrient poor, its minerals washed away by an annual rainfall of between 75 and 225 inches. A host of nearly invisible decomposers, including springtails, nematodes, tardigrades, bacteria, and fungi, break down fallen leaves, branches, and other plant detritus; the usable nutrients produced by their activities are promptly taken up again by the vegetation.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Understory",
            slug: "understory",
            year: "2007", 
            medium: "Oil on polyester over panel", 
            dimensions: "60” x 48”",
            description: "Understory shows the middle level of a prototypic tropical rainforest. The species of flowering tree that provides the underlying structure for this painting has branches that grow in an unusual pattern of alternating Vs off the main trunk. This pattern is reminiscent of the diagrams known as cladograms—from the Greek word for “branch”—that are used to sort species into phylogenetic or taxonomic order. The array and number of birds and animals depicted invokes the crowding of more and more of our biodiversity into smaller and smaller plots of land uninhabited by humans.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Canopy",
            slug: "canopy",
            year: "2008", 
            medium: "Oil on polyester over panel", 
            dimensions: "60” x 48”",
            description: "Canopy shows the sunlit world of the treetops. The rainforest canopy has been called one of “the last terrestrial frontiers” on Earth. This habitat was uncharted territory until the 1970s, when high-tech mountaineering equipment was used by researchers to climb up into it and canopy rafts—mesh stretched over inflatable pontoons—were used to perch on top of it.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Emergent",
            slug: "emergent",
            year: "2011", 
            medium: " Oil on polyester over panel", 
            dimensions: "60” x 48”",
            description: "Depicted here is the uppermost layer of an imaginary, mid-elevation tropical rainforest, where the tallest trees emerge into full sun. In this airiest strata of the forest, 200 feet above the ground, plants and animals exist that are seen nowhere else, and whose entire life cycles are spent in the trees. The trees themselves, mostly evergreen hardwoods, live very self-contained lives—most do not touch their neighbors at all. Species tend to bloom synchronously, though they are usually spaced widely apart—rarely do you see a stand of one kind of tree. All the recently described species shown here are from different parts of the world: they do not belong together, though they might all be found in the upper layers of a tropical forest. For more on rainforests: <a href='https://striresearch.si.edu/rainforest/home/lush-but-fragile/'>Lush but Fragile</a>."
        }]
    },
    {
        order: 2,
        hero: 5,
        name: 'Taxa',
        slug: 'taxa',
        date: '1999-2004',
        description: "\"Taxa\" is a Greek word meaning \"order\" or \"arrangement\" and is the root word of \"taxonomy\": the scientific description of species and their placement into evolutionary order. Each of the six canvases in this series explores one aspect or dynamic of conservation; the subject of each painting grew out of the research for the previous. Each took nearly a year to complete. In-depth study of a single species can yield a world of information: each life cycle is deeply entwined with that of the others in its community. The more we learn about the intricacies of one organism, its behavior and its ecosystem, the more we recognize the limits of our current knowledge. The more we discover and understand, the more we comprehend our responsibility for every other living thing.",
        paintings: [{
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Descendant",
            slug: "descendant",
            year: "1999",
            medium: "Oil on panel", 
            dimensions: "48” x 36”",
            "description": "Descendant depicts plants and animals all in decline in mainland United States, Hawaii, or Central America. While a few are 'presumed extinct,' most of them are on either state or federal endangered species lists. This painting was finished in the year 1999: some information has changed since then. There have been significant additions to and a few subtractions from both state and federal lists. This site will be amended in the future to reflect any changes in the status of the species included here. Use <a href='https://www.fws.gov/program/endangered-species/species'>this link</a> for the current US Endangered Species List."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Ascendant",
            slug: "ascendant",
            year: "2000",
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 36”",
            description: "Shown here are non-native species introduced in some part of the United States or its trust territories, as of the year 2000. Their populations have increased as they successfully out-compete native residents. There are undoubtedly more species that have made their way to the US since 2000, like the Burmese python, spotted lanternfly, northern snakehead, and brown apple moth. See USDA Invasive Species List <a href='https://www.invasivespeciesinfo.gov/species-profiles-list'>here</a>.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Trade",
            slug: "trade",
            year: "2001",
            medium: "Oil on polyester over panel", 
            dimensions: "36” x 48”",
            description: "Wild populations of the species in this painting are depleted by collection for both legal and illegal markets. Wildlife is the third largest illegal market commodity in the world, ranking just behind guns and drugs. Traffic runs a great website about wildlife crime and its effect on the world's biodiversity. Visit <a href='https://www.traffic.org/about-us/illegal-wildlife-trade/'>Traffic</a> for more information."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Collection",
            slug: "collection",
            year: "2002",
            medium: "Oil on polyester over panel", 
            dimensions: "36” x 48”",
            description: "This painting is an exploration of our desire to possess. Featured are plants and animals that people want to study in depth, to exhibit, or simply to admire at leisure. Each taxon shown here has an unusual story about how, why, or when it was collected.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Back",
            slug: "back",
            year: "2003",
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 36”",
            description: "The plants and animals in this picture have gone to the brink of extinction and been carefully nurtured back, or they were presumed extinct and then re-found. Such a lucky re-found species is sometimes referred to as 'Lazarus taxon': <a href='https://en.wikipedia.org/wiki/Lazarus_taxon'>Lazarus taxon</a>. Here are a few other plants and animals that have been re-found in some remnant habitat: <a href='https://www.treehugger.com/lazarus-species-extinct-animals-found-alive-4869279'>Lazarus species</a>."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Gone",
            slug: "gone",
            year: "2004",
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 36”",
            description: "The 63 species painted in GONE have all become extinct since the mid-1800s and the colonization of the New World. All but one of these species are extinct at the full species level, meaning not just a sub-species or small subset of the species. Many other plants and animals are thought to have gone extinct since this painting was made. Here is one list: <a href='https://www.scientificamerican.com/article/what-weve-lost-the-species-declared-extinct-in-2020/'>Species declared extinct in 2020</a>."
        }]
    },
    {
        order: 3,
        hero: 1,
        name: 'Non-Series work',
        slug: 'large-stand-alone-paintings',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Nantahala",
            slug: "nantahala", 
            year: "2023",
            medium: "Oil & alkyd on polyester over panel",
            dimensions: "36” x 48”",
            description: "The plants and animals in this outdoor still life were seen from one trail in the Joyce Kilmer Memorial Forest, part of the Nantahala Forest and Slickrock Wilderness Area, near Robbinsville, NC, in the Great Smoky Mountains.  Joyce Kilmer has some of the oldest stands of cove hardwood forest left anywhere on the east coast. The boxes that surround the central image show close-ups of the surface of each of the species depicted. The close shots were taken through a microscope to explore how color is dispersed on the surface of living tissues.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Fathertime",
            slug: "fathertime",
            year: "2022",  
            medium: "Oil on polyester over panel",
            dimensions: "60” x 48”",
            description: "Father Time depicts a sampling of animals where males perform a big share of the care for their offspring. From nest building and provisioning, to carrying the infants, to raising the young from egg to near adult, the males in this painting are present for their offspring; sometimes, as in the case of the giant cassowary, they are the sole caretaker. While we are used to seeing male birds help in spring with nest building, territory defense, and feeding, it is interesting to note how many other orders of animal dads, from amphibians to insects, are engaged in rearing their young. With all of the animals on earth, one wonders why so few fathers are involved with their offspring. This painting was made for Sarah Hrdy, author of Father Time: A Natural History of Men and Babies, which will be out in May of 2024 from Princeton University Press: <a href='https://press.princeton.edu/books/hardcover/9780691238777/father-time'>Father Time Book</a>."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Edibles",
            slug: "edibles",	
            year: "2021", 
            medium: "Oil & alkyd on polyester over panel",
            dimensions: "36” x 48”",
            description: "All the organisms in this picture are eaten by humans in some part of the world. They are all depicted at life size and are in the correct relative scale. There are about 2000 species of edible insects worldwide. To learn more about edible insects, read about Dr. Brian Fisher’s BREAKFAST BEFORE CONSERVATION program here: <a href='https://www.ipsio.org/valala-farms.html'>BREAKFAST BEFORE CONSERVATION</a>. There is an interesting article about Dr. Fisher's work in Madagascar and beyond here: <a href='https://time.com/5942290/eat-insects-save-planet/'>Eat Insects, Save the Planet</a>. To help support that ongoing research, please visit the California Academy of Sciences: <a href='https://give.calacademy.org/campaign/breakfast-before-conservation/c291684'>Support Breakfast Before Conservation</a>. For a list of insects consumed by humans around the world, go to: <a href='https://www.perlego.com/book/1641341/edible-insects-of-the-world-pdf'>Edible Insects of the World</a>.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Nudibranchia",
            slug: "nudibranchia",	
            year: "2014", 
            medium: "Oil & alkyd on canvas over panel",
            dimensions: "4' x 5'",
            description: "Nudibranchs are sea slugs, mollusks with no visible shell: “nudi” = naked and “branch” = gill. The oceans are the last great frontier on this planet, but estimates of the total number of species of nudibranch run from 3,000 to 6,000. Given  the vastness of the oceans and the limited time humans have explored undersea depths, who knows? Sea slugs inhabit every part of every ocean: from shallow reefs and mudflats to the depth of thermal vents, from the Arctic to the Antarctic. These creatures are short lived and many have only a few days to a few months to reproduce once they reach sexual maturity. To maximize their chances to find a mate, all are hermaphrodites. They can also mate across generations: any sexually mature nudibranch can mate with any other mature one of its species, regardless of size. A wide array of mimicries and camouflages has also evolved among nudibranchs. The multifunctional “fingers”, or cerata, of some slugs look so much like their food species that the slugs are virtually invisible against them. Nudibranchs have rudimentary sight, they cannot perceive color, yet, to our eyes they are remarkably colorful. <a href='https://en.seaslug.world/'>https://en.seaslug.world/</a>",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "CoExistants",
            slug: "co-existants",
            year: "2017",
            medium: "Oil on polyester over panel",
            dimensions: "44” x 64”",
            description: "This picture was commissioned by the Art in Embassy program of the US State Department for display in a new embassy building in Paramaribo, Suriname. The artist spent three weeks in Suriname in the spring of 2016. Scientists at Conservation International, Green Heritage and Anton de Kom Universiteit helped create a list of species representative of the country’s flora and fauna. Ten percent of Suriname was declared a World Heritage Site because of its extensive biological diversity. That great species diversity is matched by wide cultural diversity. The title “Co-Existants” honors the grace with which the myriad peoples of Suriname, for the most part, get along; and it echoes the “Peaceable Kingdom” quality of the composition. Every seed and plant in this painting is used by the people of Suriname medicinally. All species in this painting are shown at half life-size in the original.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Membracidae",
            slug: "membracidae",	
            year: "2021",   
            medium: "Oil & alkyd on polyester over panel",
            dimensions: "60” x 48”",
            description: "Treehoppers and thorn bugs from the family Membracidae are plant sap-sucking insects that are related to cicadas and leafhoppers. While not long-lived, these insects are found all over the world, except in Antarctica. Membracidae have evolved interesting mutually beneficial behaviors with wasps, ants, and even geckos. These insects have evolved an array of structures on their prothorax, just behind the head, which make some species nearly identical to their mutualistic partners. Treehoppers and other insects communicate through vibrations they produce that travel through the stem of their host plant. These subsonic sounds are used to warn and woo. Learn more about Treehopper sounds: <a href='https://www.google.com/search?sca_esv=589973973&cs=0&q=Tree+hopper+sounds&sa=X&ved=2ahUKEwij8eLj4oiDAxVyH0QIHc7CADoQpboHegQIChAA&biw=1377&bih=877&dpr=1.6 - fpstate=ive&vld=cid:3c1204f9,vid:OUU_NPh66_M,st:0'>Tree Hopper Sounds</a>."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Palisades",
            slug: "palisades",	
            year: "2012", 
            medium: "Oil on polyester over panel",
            dimensions: "48” x 36”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Trobriand Dawn",
            slug: "trobriand-dawn",
            year: "2015",
            medium: "Oil on polyester over panel",
            dimensions: "48” x 60”",
            description: "",
        }]														
    },
    {
        order: 4,
        hero: 8,
        name: "Gravestone Lichens",
        slug: "gravestone-lichens",
        date: '2018-19',
        description: "What we call lichens are made of both algae and fungi in a symbiotic relationship. Lichens are excellent monitors of air quality since they have no roots but get all of their nutrients from sugars made by the photosynthesis of the algae and the atmosphere itself. Too much nitrogen can kill the algae's chlorophyll. The lichens in this series were all growing on stones in the Old Westside Cemetery in Edgartown on Martha's Vineyard. That cemetery was founded in pre-Revolutionary times, 350+ years ago, allowing time for a rich diversity of lichens to grow. Many cemeteries pressure wash headstones which kills these free air quality monitors.",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 5000,    
            name: "Gravestone Lichens I",
                slug: "gravestone-lichens-i",
                year: "2017", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens II",
                slug: "gravestone-lichens-ii",
                year: "2017", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens III",
                slug: "gravestone-lichens-iii",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens IV",
                slug: "gravestone-lichens-iv",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens V",
                slug: "gravestone-lichens-v",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens VI",
                slug: "gravestone-lichens-vi",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens VII",
                slug: "gravestone-lichens-vii",
                year: "2019", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens VIII",
                slug: "gravestone-lichens-viii",
                year: "2019", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens IX",
                slug: "gravestone-lichens-viiii",
                year: "2019", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            }
        ]
    },
    {
        order: 5,
        hero: 8,
        name: 'Butterflies of ABRI',
        slug: 'butterflies-of-abri',
        date: '2018-19',
        description: "The African Butterfly Institute in Nairobi, Kenya holds over 4 million specimens, about 1 million of which are pinned. ABRI has the most complete collection of African lepidoptera anywhere in the world, representing 98% of all African species and much of Madagascar's diversity as well. These paintings were made to help fund ABRI's future and to raise awareness of this remarkable repository of the continent's butterflies.  This collection is the entire life's work of 30-40 people. At present ABRI resides on private land and has no institutional home, so its future is an open question. <a href='https://www.lepsocafrica.org/?p=activities&s=regions'>https://www.lepsocafrica.org/?p=activities&s=regions</a>",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Kalima jacksoni',	
            slug: 'kalima-jacksoni',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Pseudactraea boisduvali',	
            slug: 'pseudactraea-boisduvali',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: 'Papilio zalmoxis',	
            slug: 'papilio-zalmoxis',	
            year: '2019',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: 'Junonia',	
            slug: 'junonia',	
            year: '2019',
            dimensions: "16” x 18”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3600,
            name: 'Protogoniomorpha parhassus',	
            slug: 'protogoniomorpha-parhassus',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Seasonal Dimorphism (Precis octavia)',	
            slug: 'seasonal-dimorphism',	
            year: '2020',
            dimensions: "12” x 16”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Mixed Box with Uraniids',	
            slug: 'mixed-box-uraniids',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: 'Mutant Papilio dardanus',	
            slug: 'mutant-papilio-dardanus',	
            year: '2019',
            dimensions: "12” x 12”",
            medium: 'Oil & alkyd on panel',
            description: "",
        }]
    },
    {
        order: 6,
        hero: 7,
        name: "Oceans",
        slug: "oceans",
        date: '2020-ongoing',
        description: "Oceans cover more than 70% of our planet's surface. As Sylvia Earle says, \"No blue, no green.\" Without oceans, there would be no life here on Earth. The oceans are our last great frontier. The paintings in this section highlight just a few curious saltwater denizens. Thanks to the many biologists, divers, and oceanographers that have allowed me to use their photographs for reference and accuracy.",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Squat Lobsters",	 
            slug: "squat-lobsters",	 
            year: "2021", 
            medium: "Oil & alkyd on wood panel",
            dimensions: "20”x20”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Brittle Star Aggregation",
            slug: "brittle-star-aggregation",
            year: "2021",
            medium: "Oil & alkyd on polyester over panel.",	
            dimensions: "36” x 36”", 
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Empty Aegean",	
            slug: "empty-aegean",	
            year: "2016", 
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "<i>Glaucus atlanticus</i>, also called the blue sea dragon, floats upside down just under the water's surface of the tropical and subtropical oceans of the world. This nudibranch eats Portuguese man-o-war and other organisms that sting. It can save the stinging cells and redeploy them for its own defense. Beware, these dragons can give a human a powerful sting.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Bubbles",	
            slug: "bubbles",	
            year: "2017",
            medium: "Oil & alkyd on wood", 
            dimensions: "14” x 11”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "One Species",	
            slug: "one-species",	
            year: "2014", 
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Appendages",	
            slug: "appendages",	
            year: "2014",
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "The left half of this painting shows the range of structures nudibranchs use for sensing their environment. These earlike stalks are called rhinophores, suggesting their relationship to noses. Most rhinophores can be drawn back into the body when danger is sensed. Their world is defined by chemistry: sea slugs are virtually blind, though some have patches of cells able to detect basic light and dark. Nudibranchs are basically blind to color perception as far as we can tell ….. all of this wild color is not for each other. The right half of the painting represents the variety of cerata: worm, acorn, leaf, or blade like projections from many slugs’ backs. Cerata are used for oxygen exchange: species with cerata do not have gills on their butts, (affectionately called “butt lettuce”) and vice versa. In species that willfully eject cerata under duress, some of those cerata wiggle on their own once ejected, adding to an attacker’s confusion, possibly allowing the slug to escape. The more blade-shaped cerata function as housing units, hosting symbiotic photosynthesizing algae, called zooxanthellae. Host nudibranchs will orient their cerata perpendicularly to incoming sunlight, this maximizes their zooxanthellae’s production of sugars, which both host and hosted will consume. ",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Self Defense",	
            slug: "self-defense",	
            year: "2014",
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "Sea slugs use a variety of means to defend themselves from predators. Most of the Armina species, like that in the upper left, bury themselves for a portion of the day or night to hide. To the right of that Cerberilla affinis has shed some of its cerata (those projections on its back), rather the way a lizard can shed its tail, giving the animal a chance to get away. Plocamopherus imperialis, at middle left, flashes a startling bluish light if it is disturbed. , Jorunna funebris, at middle right, is covered with glass-like spicules…any fish biting it will get a mouth full of stickers. Many of the Dorid slugs like this Glossodoris cincta can emit foul tasting chemicals from pores along the edge of their “skirt” or mantle. The slug at the bottom right has a way to use the stinging cells, or nematocysts, from their prey. Flabeliina exoptata and other Aeolids consume nematocysts when eating corals, anenomes and hydroids. They do not digest the stinging cells but store them in the tips of their cerata and deploy them against their own would-be predators. Stored nematocysts must be refreshed every 5 days.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Egg Masses",	
            slug: "egg-masses",	
            year: "2014",
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "Looking across from upper left to lower right, these are the egg cases of nudibranchs. Each species lays a unique type of egg case. The individual eggs vary in size. Some species’ eggs hatch into miniature versions of the adult nudibranch that immediately settle down to life. Other species’ eggs hatch into a minute shelled stage, called a verliger. Verligers are small enough to be suspended in the water column. They geographically disperse on ocean currents before growing enough to settle down, literally, and transform into fully functioning adults. • Hexabranchus sanguineus • Navanax inermis • Hypselodoris picta • Bonisa nakaza • Onchidoris depressa • Hermissenda crassicornis • Marionia hawaiiensis • Chromodoris annae • Pleurolidia juliae • Janolus fuscus • Melibe megacerus • Leminda millecra • Unknown Melibe? • Unknown • Possibly Doto amyra • Aegires pruvotfolae",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Appendages II",
            slug: "appendages-ii",
            year: "2022",  
            medium: "Oil on panel",
            dimensions: "10” x20”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Dumbo",
            slug: "dumbo",
            year: "2015",
            medium: "Oil on panel",
            dimensions: "8” in diameter",
            description: ""
        }]
    },
    {
        order: 7,
        hero: 1,
        name: 'Birds',
        slug: 'birds',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Paradise Parrots",
            slug: "paradise-parrots",	
            year: "2015", 
            medium: "Oil on panel",
            dimensions: "15.75” x 19.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3500,
            name: "Well Studied",
            slug: "well-studied",	
            year: "2018", 
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Ivory-billed Woodpeckers",
            slug: "ivory-billed-woodpecker",	
            year: "2005", 
            medium: "Oil on panel", 
            dimensions: "20” x 16” & 10.75” x 13.75”",
            description: "This picture was created in 2005, when a story broke in the New York Times about the possible sighting of an ivory-billed woodpecker in the Big Woods region of eastern Arkansas. Woodpeckers forage for food and nest in holes in standing deadwood. In the past, forest managers have tended to remove dead, fallen or snagged trees in hopes of suppressing wildfires. Although sadly the sighting seems to be erroneous, this picture still represents our sincere hope that nature is more resilient than it appears.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Bachman's Warbler",
            slug: "bachmans-warbler",	
            year: "2011", 
            medium: "Oil on panel", 
            dimensions: "15.75” x 19.75”",
            description: "<i>Vermivora bachmani</i> was first recorded by Reverend John Bachman who sent skins to John J. Audubon. Audubon described the bird as a species in honor of Bachman in 1833. Its last confirmed sightings were in the 1960's and it is presumed extinct, despite several unconfirmed or mistaken sightings in this century. The vast swathes of cane that used to cover and protect the low lying coasts of the southeastern states of the US have disappeared, along with this and other species that relied on this habitat.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5500,
            name: "Bachman's Warbler Redux",
            slug: "bachmans-warblers-redux",	
            year: "2018", 
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Akialoa",
            slug: "akialoa",
            year: "2011",
            medium: "Oil on panel",
            dimensions: "18” x 20”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Great Auk Egg",
            slug: "great-auk-eggs",
            year: "2011",
            medium: "Oil on panel",	
            dimensions: "12”x 6”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Great Auk Egg 2",
            slug: "great-auk-eggs2-tn",
            year: "2018",
            medium: "Oil on panel",	
            dimensions: "12”x 6”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "I'iwi",
            slug: "iiwi",
            year: "2011",
            medium: "Oil on panel",
            dimensions: "7.75” x 9.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Varied Thrush",
            slug: "varied-thrush",
            year: "1994",
            medium: "Oil on panel",
            dimensions: "6” x 11-7/8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Cadmium jay",
            slug: "cadmium-jay",
            year: "1994",   
            medium: "Oil & powdered cadmium on panel",
            dimensions: "16.5” x 13.25”",
            description: "",
        }]
    },
    {
        order: 8,
        hero: 1,
        name: 'Insects, Bugs & Spiders',
        slug: 'insects-and-bugs',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Spider Foot",
            slug: "spider-foot",
            year: "2021", 
            dimensions: "10” x 10”", 
            medium: "Oil & alkyd on panel",
            description: "Thanks to Wim van Egmond for letting me make this painting from his photograph, taken through a microscope, of a typical European garden spider’s foot  (Araneus diadematus). You can clearly see from this image how well a spider can read its own web."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Famous Bugs",
            slug: "famous-bugs",
            year: "2012",
            medium: "Oil on panel",
            dimensions: "19.75” x 15.75”",
            description: "This picture includes three new species of slime mold beetles named for Bush, Cheney, and Rumsfeld. The slime mold beetles <i>Agathidium bushi</i>, <i>Agathidium cheneyi</i>, and <i>Agathidium rumsfeldi</i> are all very small in scale. <i>Agra eowilsoni</i>, <i>Eristalis alleni</i>, <i>Eristalis gatesi</i>, <i>Cyclocephala Casanova</i>, <i>Lycaeides idas nobokovi</i>, and <i>Dalailama vadim</i> are named after prominent people whose names you might recognize. Some new names are honorific, some are in thanks for support, and some are bestowed because of appearance.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Old Opportunists",
            slug: "old-opportunist",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "20” x 16”",
            description: "The beetles crawling around this traditional still life are Japanese beetles (<i>Popillia japonica</i>); they  arrived in the US from Japan in 1916. In Japan they have natural predators, but in their adopted home, where they do not, their numbers have soared and they are now considered pests. In keeping with the subject of an \"old\" invader, the picture references Dutch still life tropes like a pewter plate and pitcher and adds to the suggestion of antiquity with an early American quilt."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "New Opportunists",
            slug: "new-opportunist",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "16” x 20”",
            description: "Visually hidden in this more modern style flower painting are spotted lanternflies, <i>Lycorma delicatula</i>. These planthoppers native to Asia were first found in the US in 2014 in Pennsylvania.(These insects are hyper-aware and will scuttle around the other side of a tree to avoid observation or a camera lens.) Since 2014 they have spread to Connecticut, Delaware, Indiana, Massachusetts, New Jersey, New York, North Carolina, Ohio, Pennsylvania, Virginia, West Virginia, and Maryland, despite some extreme measures taken against their proliferation."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Morpho Chrysalises",
            slug: "morpho-chrysalises",
            year: "2023",
            medium: "Oil on panel",
            dimensions: "12” x 12”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Heliconius Chrysalises",
            slug: "heliconius-chrysalis",
            year: "2023",
            medium: "Oil on panel",
            dimensions: "12” x 12”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Mpala Walking Stick",
            slug: "kenyan-walking-stick",
            year: "2020",
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Super Green I",
            slug: "super-green-i",
            year: "2020", 
            medium: "Oil & alkyd on panel",
            dimensions: "24” x 18”", 
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Super Green II",
            slug: "super-green-ii",
            year: "2021",
            medium: "Oil & alkyd on panel",
            dimensions: "18” x 24”", 
            description: ""
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Butterfly Chrysalises - 3",
            slug: "butterfly-chrysalises-3",
            year: "",
            medium: "",
            dimensions: "",
            description: ""
        },
        {
        }]
    },
    {
        order: 9,
        hero: 3,
        name: 'Plants',
        slug: 'plants',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Leucistic Redwood",	
            slug: "leucistic-redwood",	
            year: "2019",
            medium: "Oil on panel",
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 6000,
            name: "Cereus",
            slug: "cereus",
            year: "",
            medium: "",
            dimensions: "",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Leaf",
            slug: "leaf",
            year: "2013",
            dimensions: "14” x 10.5”",
            medium: "Oil on panel",
            description: ""
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Night-blooming Cereus",
            slug: "night-blooming-cereus",
            year: "",
            dimensions: "",
            medium: "Oil on panel",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Passiflora tarminiana 1",
            slug: "passiflora-tarminiana-i",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "13.75 x 10.75",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Passiflora tarminiana 2",
            slug: "passiflora-tarminiana-ii",
            year: "2005",
            medium: "Oil on panel", 
            dimensions: "13.75 x 10.75",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Passiflora gabrielliana",
            slug: "passiflora-gabrielliana",
            year: "2005",
            medium: "Oil & alkyd on wood",
            dimensions: "10.75” x 13.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Blakea scarlatina",
            slug: "blakea-scarlatina",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "13.75” x 10.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Mutisia magnifica",
            slug: "mutisia-magnifica",
            year: "2005",
            medium: "Oil on panel", 
            dimensions: "13.75” x 10.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Spring Rain",
            slug: "spring-rain",
            year: "2019",
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Magnolia",
            slug: "magnolia",
            year: "2013",
            medium: "Oil on panel", 
            dimensions: "8” x 10”",
            description: "",
        }]
    },
    {
        order: 10,
        hero: 2,
        name: 'Genetics',
        slug: 'genetics',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Dolly's Perfectly Healthy CoClones",
            slug: "dolly",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "16” x 20”",
            description: "Dolly made a lot of headlines when she was born: she was the first animal cloned from adult sheep cells. And there was some noise and worry when she developed age-related health issues early, at around age five. However, Dolly was just one of 13 cells cloned at the same time and from the same cell line. This painting is of four of her surviving \"cell mates\". Her co-clones have lived normal lives outside of the spotlight with no signs of early senescence.",
        }, 
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Transgenic Mice",
            slug: "transgenic-mice",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "12” x 16”",
            description: "",
        }, 
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Astraptes Complex",
            slug: "astraptes-complex",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "36” x 30”",
            description: "This piece is in honor of the millions of hours humans have invested to describe the nature that surrounds us, with just the basic tools shown and a lot of attention to detail. This picture is also about an idea at the time proposed by Dr. Daniel Jantzen to use a sample of DNA as a new \"barcode\" of life. The caterpillars in the foliage are a prime example of why this new barcode would be a transformative new tool in taxonomy: Jantzen and his team reared each of these caterpillars and every one of them appeared to be the same species of adult, <i>Astraptes fulgerator</i>.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Bio-luminescent Mice",
            slug: "bio-mice",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "10.75” x 13.75”",
            description: "",
        }]
    },
    {
        order: 11,
        hero: 3,
        name: 'Phasmid Eggs',
        slug: 'phasmid-eggs',
        date: '2021',
        description: "Phasmids belong to the order of walking sticks and leaf insects, Phasmatodea. In Ancient Greek “phasma” meant ghost or phantom: these insects are very cryptic and blend in extremely well with branches and foliage.  Most phasmid eggs are between three and 30 millimeters in length.  Eggs of many of these species have small caps made of fats and proteins attractive to ants.  An ant will carry an egg to their nest, eat the cap, and forget the egg.  The egg then remains in the nest, safe and at constant temperature and humidity, until it hatches into a nymph.  The nymph leaves the ant nest and goes on with its life.    Read more about this group of insects at the Phasmid Study Group online: <a href='https://phasmidstudygroup.org/'>https://phasmidstudygroup.org/</a>",
        paintings: [{
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Phasmid Eggs 1",	
            slug: "phasmid-eggs-1",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 60”",
            description: "Phasmid is shorthand for Phasmatodea, the order of walking sticks and leaf insects. In Ancient Greek “phasma” meant ghost or phantom: these insects are very cryptic, blending in extremely well with branches and foliage. Most of the eggs of Phasmids are between two and thirty millimeters in length. These are NOT painted to scale here, as I wanted to emphasize the profound diversity in texture and appearance by keeping them within a close size range. <br/><br/>Many of these species have a small cap on top that is made of fats and proteins, very altractive to ants. An ant will pick up the egg. carry it to its nest, eat the cap, and forget the egg. The egg then gets to develop, safe from harm, at a constant temperature and humidity. Once it hatches, it leaves the nest and begins its life. Look up “Phasmid Study Group” to learn all you would ever want to know.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Phasmid Eggs 2",	
            slug: "phasmid-eggs-2",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 60”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Phasmid Eggs 3",	
            slug: "phasmid-eggs-12-1",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "12” x 12”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 6000,
            name: "Phasmid Eggs 2",	
            slug: "phasmid-eggs-12-2",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "12” x 12”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 6000,
            name: "Phasmid Eggs 3",	
            slug: "phasmid-eggs-12-3",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "12” x 12”",
            description: "",
        }]
    },
    {
        order: 12,
        hero: 1,
        name: 'Diversity',
        slug: 'diversity',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Bunny Meeting",
            slug: "bunny-meeting",
            year: "2010",
            medium: "Oil on panel",
            dimensions: "18” x 24”",
            description: "<i>Nesolagus timminsi</i>, or the Annamite striped rabbit, was first seen in 1996 at a market in Bak Lak, Laos. It was described in 2000. The border between Laos and Vietnam is in the Annamite mountains, an area heavily carpet bombed during the Vietnam war. Scientists did not work in that area for many years for that reason.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "By a Thread",
            slug: "thread",
            year: "2005", 
            medium: "Oil on panel", 
            dimensions: "20” x 16”",
            description: "<i>Sicyos alba</i> (or <i>Sicyos albus</i>) is a plant in the cucumber family that lives only in Hawaii where it is threatened by wild pigs and habitat loss. <i>Aepypodius bruijnii</i> is an endangered megapode from the island of West Papua in Indonesia. Threats to its continued existence are hunting and habitat loss, though its population may have always been restricted geographically.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Chodsigoa caovansunga",
            slug: "chodsigoa",
            year: "2005",
            medium: "Oil on panel",	
            dimensions: "3” x 5”",
            description: "",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Diptych: The Quick and the Dead",	
            slug: "quick-dead",
            year: "1997",
            medium: "Oil on panel", 
            dimensions: "",
            description: "These two paintings together ask a question: How do we decide how much time and money to allocate to which problems or causes? The Quick shows an American Kestrel, <i>Falco sparverius</i>, superimposed over an image taken from the news of the Million Man March at the US Capitol in Washington, DC. The Dead shows another event at the US Capitol, again taken from the news: the last time the entire AIDS quilt was in one place. The Dead has a pair of extinct male passenger pigeons, <i>Ectopistes migratorius</i>, superimposed over it. Both subjects are worthy of our time, attention, and support, but how can each be assigned a numeric value?",	
        }]
    },
    {
        order: 13,
        hero: 5,
        name: 'Nature in the Margins',
        slug: 'nature-in-the-margins',
        date: '1995-1999',
        description: "These paintings were done in the late 90's. They each show some species which live in a remnant habitat, a small corner of nature we have unintentionally left for them. The paintings were used to experiment with a variety of painting techniques including those of the still life masters of 17th C Netherlands",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Hubble Space",
            slug: "hubble",
            year: "1997",
            medium: "Oil on masonite",
            dimensions: "24” x 18”",
            description: "The white-footed or wood mouse, <i>Peromyscus leucopus</i>, is the second most used lab mouse after the house mouse. So, it is very common, and here, it stands for each one of us, so small against the infinity of space as seen through the lens of the Hubble telescope.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Mass Transit",
            slug: "mass-transit",
            year: "1996",
            medium: "Oil on panel",
            dimensions: "14” x 11”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Mall of America",
            slug: "mall-america",
            year: "1996",
            medium: "Oil on true gesso on masonite", 
            dimensions: "11” x 14”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Shuttle",
            slug: "shuttle",
            year: "1995", 
            medium: "Oil on true gesso on masonite", 
            dimensions: "7” x 9”",
            description: "from Nature in the Margins Series",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Unnatural Angle: In Heaven there Is No Right Wing",
            slug: "in-heaven",
            year: "1996", 
            medium: "Oil on true gesso on masonite", 
            dimensions: "13.5” x 16.75”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Mission Blue",
            slug: "mission-blue",
            year: "1996", 
            medium: "Oil on masonite", 
            dimensions: "8” x 10”",
            description: "Mission blue butterfly, <i>Icaricia icarioides missionensis</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Love Nest",
            slug: "love-nest",
            year: "1997",
            medium: "Oil on masonite", 
            dimensions: "17” x 21”",
            description: "Satin bowerbird, <i>Ptilonorhynchus violaceus</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Where Will the Blue-tailed Mole Skinks Live?",
            slug: "where-will-mole",
            year: "1996",
            medium: "Oil on masonite", 
            dimensions: "20” x 10”",
            description: "Blue-tailed mole skink, <i>Eumeces egregius lividus</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Rarest Mammal in the Lower 48",
            slug: "rarest-mammal",
            year: "1996",
            medium: "Oil on masonite", 
            dimensions: "24” x 12”",
            description: "Spotted bat, <i>Euderma maculatum</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Painted Beauty (Cynthia virginiensis)",
            slug: "painted-lady",
            year: "1995",
            medium: "Oil on masonite",
            dimensions: "4” x 4”",
            description: "Painted lady butterfly, <i>Cynthia virginiensis</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Auk Egg and Key",
            slug: "auk-egg-key",
            year: "2003",
            medium: "Oil on masonite",
            dimensions: "14” x 11”",
            description: "Great auk, <i>Pinguinus impennis</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Auk Egg and Least Blue (Maculinea arion)",
            slug: "auk-egg-leastblue",
            year: "2003",
            medium: "Oil on masonite",
            dimensions: "14” x 11”",
            description: "Great auk, <i>Pinguinus impennis</i> Least blue butterfly, <i>Maculinea arion</i>"
        }]
    },
    {
        order: 14,
        hero: 1,
        name: 'Luck Suite',
        slug: 'luck-suite',
        date: '2017-ongoing',
        description: "This set of works incorporate pressed and dried four-leafed clovers as marks and as mnemonic devices. These abstract, sculptural paintings are about the nature of luck and how we think of it.",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "123 Lucky Mutants",
            slug: "123-lucky-mutants",
            year: "2017",
            medium: "4-leafed clovers & oil and alkyd on polyester over panel",
            dimensions: "3” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Breathe",
            slug: "breathe",
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 24”",
            description: "",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Circulation",
            slug: "circulate",
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 24”",
            description: "",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Device for the Prevention of Armageddon",	
            slug: "device-armageddon",	
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "36” x 36”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Hard Luck",
            slug: "hard-luck",
            year: "2020",
            medium: "Oil, clovers, & graphite on panel",
            dimensions: "48” x 36”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Hex",	
            slug: "hex",	
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 18”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Morph-Luck",
            slug: "morph-luck",
            year: "2018",
            medium: "4-leafed clovers & oil and alkyd on wood",
            dimensions: "9” x 9”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Nine Patch",	
            slug: "nine-patch",	
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 24”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Oceanic",	
            slug: "oceanic",	
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "24” x 28.5”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Radiate",	
            slug: "radiate",	
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "12” x 12”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Split",
            slug: "split",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "12” x 14”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 1",
            slug: "countdown-1",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 2",
            slug: "countdown-2",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 3",
            slug: "countdown-3",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 4",
            slug: "countdown-4",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 5",
            slug: "countdown-5",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 6",
            slug: "countdown-6",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 7",
            slug: "countdown-7",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Isolate, Sequester, Quarantine",	
            slug: "isolate",	
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Pandemic",
            slug: "pandemic",
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Survival",
            slug: "survival",
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Neighbors",	
            slug: "neighbors",	
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "We Were So Lucky",
            slug: "so-lucky",
            year: "2014",
            medium: "4-leaf clovers & oil and alkyd on wood",
            dimensions: "15.5” x 24”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "25 Years",
            slug: "25-years",
            year: "",
            medium: "",
            dimensions: "20” x 16”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Lead",
            slug: "lead",
            year: "",
            medium: "",
            dimensions: "10” x 10”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Quilt",
            slug: "quilt",
            year: "",
            medium: "",
            dimensions: "18” x 24”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Raspberry",
            slug: "raspberry",
            year: "",
            medium: "",
            dimensions: "9” x 12”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Pie squared",
            slug: "pie-squared",
            year: "",
            medium: "",
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "We are All Unique",
            slug: "we-are-all-unique",
            year: "",
            medium: "Oil on panel",
            dimensions: "12” x 12”",
            description: "",
        }]
    },
    {
        order: 15,
        hero: 1,
        name: 'Pencil Suite',
        slug: 'pencil-suite',
        date: '',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "My Hands",
            slug: "my-hands",
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Old Colebus",	
            slug: "old-colebus",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Dreamscape",	
            slug: "dreamscape",	
            year: "2022",
            medium: "Graphite on Arches paper",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Dry Season Shadow",	
            slug: "dry-season",	
            year: "2022",
            medium: "watercolor on Arches paper",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Weighted line drawing",	
            slug: "weighted-line",	
            year: "2022",
            medium: "Graphite on Arches paper",
            dimensions: "48” x 36”" ,
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Vine",	
            slug: "kenyan-vine",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Thorns",	
            slug: "kenyan-thorn",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Lichen",	
            slug: "kenyan-lichen",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Brush",	
            slug: "kenyan-brush",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Acacia",	
            slug: "kenyan-acacia",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        }]
    }]
}