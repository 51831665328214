<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import UnevenLayout from '../components/layout/UnevenLayout.vue';

import generateMetaInfo from '../helpers/generateMetaInfo.js';

import paintings from './../data/paintings';

import Fuse from 'fuse.js';

export default {
    name: 'SearchPage',
    components: {
        GlobalHeader,
        GlobalFooter,
        UnevenLayout
    },
    data() {
        return {
            searchQuery: this.$route.query.query || '',
            fuse: null
        };
    },
    computed: {
        paintings: function() {
            let paintingsArray = [];

            paintings.categories.forEach(category => {
                category.paintings.forEach(painting => {
                    paintingsArray.push({
                        categoryName: category.name,
                        categoryDescription: category.description,
                        categorySlug: category.slug,
                        ...painting
                    });
                })
            });

            console.log('paintingsArray', paintingsArray);

            return paintingsArray;
        },
        filteredPaintings() {
            if (!this.searchQuery) {
                return []; // this.paintings;
            }

            const searchResults = this.fuse.search(this.searchQuery);
            console.log('searchResults',searchResults);
            return searchResults;
        }
    },
    metaInfo() {
        const title = 'Search – Isabella Kirkland';
        
        // Description should be picked up as the first set of text on the page.

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title);
    },
    created() {
        const options = {
            findAllMatches: true,
            keys: ['name', 'description','categoryName','categoryDescription'],
            ignoreLocation: true,
            threshold: 0.1 // Adjust the threshold as needed
        };

        this.fuse = new Fuse(this.paintings, options);
    },
    methods: {
        highlightMatches(text) {
            if (!this.searchQuery) return text;
            const regex = new RegExp(this.searchQuery, 'gi');
            return text.replace(regex, match => `<mark>${match}</mark>`);
        }
    }
}
</script>

<template>
  <div>
    <GlobalHeader/>
    <div class="content pad">
        <h2>Search</h2>
        <div>
            <input :style="{ backgroundImage: `url(${require('@/assets/svg/magnifying-glass.svg')})` }" id="search-input" type="text" v-model="searchQuery" placeholder="Search paintings...">
            <h3 v-if="filteredPaintings.length > 0">Your search results</h3>
            <ul>
                <li v-for="painting in filteredPaintings" :key="painting.item.slug">
                    <router-link :to="{ path: `/collections/${painting.item.categorySlug}/paintings/${painting.item.slug}` }">
                        <UnevenLayout :verticalCenter="false">
                            <template #left>
                                <img :src="`/painting-thumbnails/${painting.item.categorySlug}/${painting.item.slug}-tn.jpg`" />
                            </template>
                            <template #right>
                                <h3 v-html="highlightMatches(painting.item.name)"></h3>
                                <p v-html="highlightMatches(painting.item.description)"></p>
                            </template>
                        </UnevenLayout>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
.content {
    gap: 0em;
}
#search-input {
    background-color: #EDEDED;
    background-repeat: no-repeat;
    background-position: 20px 20px;
    background-size: 25px 24px;
    border-radius: 1em;
    border: none;
    box-sizing: border-box;
    margin: 2em 0;
    padding: 25px 25px 25px 54px;
    width: 100%;
    max-width: 80vw;
}
a {
    text-decoration: none;
}
ul {
    padding: 0;
}
li:first-child {
    margin-top: 0;
}
li {
    list-style: none;
    margin: 4em 0;
}
@media screen and (max-width: 768px) {

}
</style>
