<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import HeroSection from '../components/HeroSection.vue'

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'CurriculumVitaePage',
    metaInfo() {
        const title = 'Curriculum Vitae – Isabella Kirkland';
        
        const description = 'The curriculum vitae of Isabella Kirkland.';

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title, description);
    },
    components: {
        GlobalHeader,
        GlobalFooter,
        HeroSection
    }
}
</script>

<template>
  <div id="resume-page">
    <GlobalHeader/>
    <HeroSection
      :image="require('@/assets/images/resume-page/cv-header.jpg')"
      subheader=""
      header="Curriculum Vitae"
      caption="" 
      height="50vh"
    />
    <div class="content pad">
        <div class="title-grid">
            <h3></h3>
            <div class="info-grid">
                <span class="emboldened">
                    Born:
                </span>
                <span>
                    1954 Old Lyme, CT
                </span>

                <span class="emboldened">
                    Education:
                </span>
                <span>
                    1971-1973 Guilford College, Greensboro, NC<br/>
                    1975 Virginia Commonwealth University, Richmond, VA<br/>
                    1978 San Francisco Art Institute, San Francisco, CA
                </span>
            </div>
        </div>
          
        <div class="title-grid">
            <h3>Solo Exhibitions</h3>
            <div class="info-grid">
                <span class="emboldened">2021</span>
                <span>
                    <i>ADAA The Art Show</i>, Hosfelt Booth, New York, NY<br/>
                    <i>Isabella Kirkland: The Small Matter</i>, Hosfelt Gallery, San Francisco, CA
                </span>

                <span class="emboldened">2015</span>
                <span><i>Isabella Kirkland: New Paintings</i>, Bolinas Museum, Bolinas, CA</span>

                <span class="emboldened">2014</span>
                <span>
                    <i>Isabella Kirkland: Stilled Life</i>, Dayton Art Institute, Dayton, OH<br/>
                    <i>Life Cycles: Isabella Kirkland’s TAXA</i>, St. Louis Art Museum, St. Louis, MO
                </span>

                <span class="emboldened">2012</span>
                <span><i>Promise and Peril: A Study in Biodiversity</i>, Hazel Wolfe Gallery, Brower Center, Berkeley, CA</span>

                <span class="emboldened">2011</span>
                <span><i>NOVA</i> Feature Inc, New York, NY</span>

                <span class="emboldened">2008</span>
                <span>
                    <i>NOVA (New Species): Paintings by Isabella Kirkland</i>, Toledo Art Museum, Toledo, OH<br/>
                    <i>Taxa: Paintings by Isabella Kirkland</i>, Upstairs Gallery, National Academy of Sciences, Washington, DC
                </span>

                <span class="emboldened">2005</span>
                <span><i>Isabella Kirkland</i>, Feature Inc., New York, NY</span>

                <span class="emboldened">2003</span>
                <span><i>Isabella Kirkland</i>, Feature Inc., New York, NY</span>

                <span class="emboldened">1984</span>
                <span><i>Pandora’s Box: SFAI Annual</i>, New Langton Arts, San Francisco, CA</span>

                <span class="emboldened">1983</span>
                <span><i>Micronic Obsessions</i>, Real Art Ways, Hartford, CT</span>

                <span class="emboldened">1980</span>
                <span>
                    <i>Micronic Problems</i>, 80 Langton Street Gallery, San Francisco, CA<br/>
                    <i>Untitled</i>, Introductions, Gallery Paule Anglim, San Francisco, CA
                </span>

            </div>
        </div>
        
        <div class="title-grid">
            <h3>Group Exhibitions</h3>
            <div class="info-grid">
                <span class="emboldened">2023</span>
                <span><i>Off the Grid: Post Formal Conceptualism</i>, Hosfelt Gallery, San Francisco, CA</span>

                <span class="emboldened">2021</span>
                <span><i>Where We Are</i>, Hosfelt Gallery, San Francisco, CA</span>

                <span class="emboldened">2020</span>
                <span><i>Bird, Nest, Nature</i>, Bedford Gallery, Lesher Center for the Arts, Walnut Creek, CA</span>

                <span class="emboldened">2019</span>
                <span>
                    <i>Between Them</i>, Hosfelt Gallery, San Francisco, CA<br/>
                    <i>Endangered Species: Artists on the Front Line of Biodiversity</i>, Whatcom Museum, Bellingham, WA<br/>
                    <i>Anthropocenic: Art About the Natural World in the Human Era</i>, Bates Museum of Art, Olin Arts Center, Lewiston, ME
                </span>

                <span class="emboldened">2018</span>
                <span>
                    <i>Frankenstein’s Birthday Party</i>, Hosfelt Gallery, San Francisco, CA<br/>
                    <i>Big Botany</i>, Conversations with the Plant World, Spencer Museum of Art, University of Kansas, Lawrence, KS<br/>
                    <i>Making Life Visible</i>, Faulconer Gallery, Grinnell College, Grinnell, IA<br/>
                    <i>Strange Attractors: The Anthology of Interplanetary Folk Art</i>, Vol., Life on Earth, Redling Fine Art, Los Angeles, CA
                </span>

                <span class="emboldened">2017</span>
                <span>
                    <i>State of the Art: Discovering American Art Now</i>, Frist Museum of Art, Nashville, TN<br/>
                    <i>So I traveled a great deal…</i>, Matthew Marks Gallery, New York, NY
                </span>

                <span class="emboldened">2016</span>
                <span>
                    <i>Bird in Hand</i>, Palo Alto Art Center, Palo Alto, CA<br/>
                    <i>State of the Art</i>, Crystal Bridges Museum of Art, Bentonville, AR
                </span>

                <span class="emboldened">2014</span>
                <span><i>Promise and Peril: A Study in Biodiversity</i>, Hazel Wolfe Gallery, Brower Center, Berkeley, CA</span>

                <span class="emboldened">2013</span>
                <span>
                    Nature’s Toolbox: Biodiversity, Art and Invention, Field Museum, Chicago, IL<br/>
                    <i>Pop: Politics of Place</i>, Art Design and Architecture Museum, University of California, Santa Barbara, CA<br/>
                    <i>The Animals Look Back at Us</i>, Byrdcliffe Kleinert/James Center for the Arts, Woodstock, NY and Williamsburg Art &amp; Historical Center, Brooklyn, NY<br/>
                    <i>Nature’s Toolbox: Biodiversity</i>, Art, and Invention, The Leonardo, Salt Lake City, UT and Ulrich Museum of Art at Wichita State University, KS
                </span>

                <span class="emboldened">2012</span>
                <span>
                    Punt, Feature Inc., New York, NY<br/>
                    <i>Tending toward the Untamed: Artists Respond to the Wild Garden</i>, Glyndor Gallery, Wave Hill, Bronx, NY
                </span>

                <span class="emboldened">2011</span>
                <span><i>Global Flora: Botanical Imagery and Exploration</i>, Davis Museum and Cultural Center, Wellesley College, Wellesley, MA</span>

                <span class="emboldened">2009</span>
                <span><i>NeoRococo</i>, Shore Institute of Contemporary Art, Long Branch, NJ</span>

                <span class="emboldened">2008</span>
                <span><i>Trouble in Paradise: Examining Discord Between Nature and Society</i>, Tucson Museum of Art</span>

                <span class="emboldened">2007</span>
                <span><i>Painting as Fact – Fact as Fiction</i>, de Pury &amp; Luxembourg, Zurich, Switzerland</span>

                <span class="emboldened">2006</span>
                <span>
                    Biodiversity: Order, Consumption &amp; Man, Sun Valley Center for the Arts, Sun Valley, ID<br/>
                    <i>The Way of All Flesh</i>, Bravin Lee Productions, New York, NY
                </span>

                <span class="emboldened">2005</span>
                <span><i>ECO: Art About the Environment</i>, Fine Arts Gallery, San Francisco State University, San Francisco, CA</span>

                <span class="emboldened">2004</span>
                <span>
                    <i>Continuities: Themes from the Fine Art Collection Reflected in Recent Northern California Art</i>, Richard L. Nelson Gallery, University of California, Davis, CA<br/>
                    <i>Natural Histories: Realism Revisited</i>, Scottsdale Museum of Contemporary Art, Scottsdale, AZ
                </span>

                <span class="emboldened">2002</span>
                <span><i>Isabella Kirkland and Michael St. John</i>, Feature Inc., New York, NY</span>

                <span class="emboldened">2001</span>
                <span><i>Taxa: Isabella Kirkland</i>, Yerba Buena Center for the Arts, San Francisco, CA</span>

                <span class="emboldened">2000</span>
                <span>
                    <i>Beauty on the Wing: The Double Lives of Butterflies</i>, Harvard Museum of Natural History, Cambridge, MA<br/>
                    <i>6th Anniversary Group Exhibition</i>, Heritage Fine Arts, San Jose, CA
                </span>

                <span class="emboldened">1999</span>
                <span><i>Natural World Observed</i>, Bedford Gallery, Dean Lescher Center for the Arts, Walnut Creek, CA</span>

                <span class="emboldened">1995</span>
                <span><i>Animals and Artists</i>, Joan Roebuck Gallery, Lafayette, CA</span>

                <span class="emboldened">1992</span>
                <span><i>Still Lives</i>, Zen Center Hospice Guest House, San Francisco, CA</span>

                <span class="emboldened">1991</span>
                <span>
                    <i>Erotic Drawings</i>, ArtSpace, San Francisco, CA<br/>
                    <i>LOCOmotion: 4 one-minute animations</i>, ArtSpace, San Francisco, CA
                </span>

                <span class="emboldened">1990</span>
                <span>
                    <i>The Dirty Show</i>, The Living Room, San Francisco, CA
                </span>
                      
                <span class="emboldened">1989</span>
                <span>
                    <i>Nepotism</i>, Hallwalls, Buffalo, NY    
                </span>

                <span class="emboldened">1987</span>
                <span>
                    <i>Perdurabo</i>, Bard College, New York, NY     
                </span>

                <span class="emboldened">1986</span>
                <span>
                    <i>Night of Fog</i> (installation), Cat Club, New York, NY
                </span>

                <span class="emboldened">1985</span>
                <span>
                    <i>Payola</i>, MO David Gallery, New York, NY
                </span>

                <span class="emboldened">1983</span>
                <span>
                    <i>Sacred Artifacts, Objects of Devotion</i>, Alternative Museum, New York, NY
                </span>

            </div>
        </div>

        <div class="title-grid">
            <h3>Press</h3>
            <div class="info-grid">
                <span class="emboldened">2023</span>
                <span>David M. Roth, <i>The Grid Revisited</i>, Squarecylinder, May<br/>
                    <a target="nofollow" href="https://www.squarecylinder.com/2023/05/the-grid-revisited/">https://www.squarecylinder.com/2023/05/the-grid-revisited/</a>
                </span>

                <span class="emboldened">2022</span>
                <span>
                    Heather Riverun, <i>The Power of Attention: How Isabella Kirkland's Work Stands Witness to Nature's Losses and Gains</i>, Kinute, September 22<br/>
                    PODCAST: Interview with David Helvarg, Isabella Kirkland's Art of Nature, Rising Tide #64, Blue Frontier, August 29
                </span>

                <span class="emboldened">2021</span>
                <span>
                    David M. Roth, <i>The Nature Conundrum</i>, Squarecylinder, June<br/>
                    <a target="nofollow" href="https://www.squarecylinder.com/2021/06/the-nature-conundrum/">https://www.squarecylinder.com/2021/06/the-nature-conundrum/</a><br/>
                    Renny Pritikin, <i>On Isabella Kirkland @Hosfelt</i>, Squarecylinder, May<br/>
                </span>

                <span class="emboldened">2018</span>
                <span>Charles Desmarias, <i>Summer exhibition at Hosfelt Gallery a monstrous affair</i>, San Francisco Chronicle, July 20</span>

                <span class="emboldened">2017</span>
                <span>
                    Jennifer Krasinski, <i>Paradise Lost</i>, The Village Voice, p. 27, July 19<br/>
                    Dan Piepenbring, <i>"So I Traveled a Great Deal..."</i>, The Paris Review July 10<br/>
                    <a target="nofollow" href="https://www.theparisreview.org/blog/2017/07/10/so-i-traveled-a-great-deal/">https://www.theparisreview.org/blog/2017/07/10/so-i-traveled-a-great-deal/</a><br/>
                    Holland Cotter, <i>“So I traveled a great deal...”</i>, The New York Times, p. C14, August 11<br/>
                    <a target="nofollow" href="https://mmg.nyc3.cdn.digitaloceanspaces.com/online/so-i-traveled-a-great-deal-
organized-by-vincent-fecteau-and-jordan-stein-07-2017/2017-08-11_The-New-York-
Times.pdf">https://mmg.nyc3.cdn.digitaloceanspaces.com/online/so-i-traveled-a-great-deal-
organized-by-vincent-fecteau-and-jordan-stein-07-2017/2017-08-11_The-New-York-
Times.pdf</a><br/>
                    <i>In These Hands</i>, Orion 35th Anniversary Issue, Volume 36, #4
                </span>

                <span class="emboldened">2013</span>
                <span><i>De-Extinction</i>, (interview) studio360.org, July 19</span>

                <span class="emboldened">2012</span>
                <span>
                    Donna Maria deCreeft, <i>Wave Hill: Artists Respond to the Wild Garden</i>, Artezine, July<br/>
                    Isabella Kirkland's forward to <i>Tiffany Bozic: Drawn by Instinct</i>, Gingko Press, 2012<br/>
                    Zygote Quarterly, no.3, Fall, pp. 28-33 (reproductions)
                </span>

                <span class="emboldened">2011</span>
                <span>Ken Johnson, <i>Isabella Kirkland: ‘Nova’</i>, New York Times, C25, June 9</span>

                <span class="emboldened">2010</span>
                <span>
                    Kyoto Journal no.75 (reproduction)
                </span>

                <span class="emboldened">2009</span>
                <span>Andrew Revkin, <i>On the Origin (and Fate) of Species</i>, New York Times February 13</span>

                <span class="emboldened">2008</span>
                <span>
                    Anna Lena Phillips, <i>Ars Scientifica</i>, American Scientist, May - June, pp. 205-206<br/>
                    Steve Pollick, 250 New Plants, Animals at Art Museum, Toledo Blade, September 7<br/>
                    Samantha Campos, <i>Life on Canvas</i>, Pacific Sun, September 19-25
                </span>

                <span class="emboldened">2007</span>
                <span>
                    Andrew Revkin, <i>Permanent Art, Evanescent Life</i>, New York Times, November 1 (interview)<br/>
                    Andrew Revkin, <i>Paintings of Nature's Comeback Kids</i>, Dot Earth, New York Times, October 30
                </span>

                <span class="emboldened">2006</span>
                <span>
                    Michael Ames, <i>Going, Going, Almost Gone</i>, Idaho Mountain Express, February 15, p. C1<br/>
                    Karen Bossick, <i>‘Gone,’ ‘Back’ and ‘Nova’</i>, Wood River Journal, February 15, p. B2
                </span>

                <span class="emboldened">2004</span>
                <span>
                    Richard Nilsen, <i>How Real Can It Get?</i>, Arizona Republic, May 30, pp. E:1, 10 (reproduction)<br/>
                    Dolores Tropiano, <i>Natural World Show</i>, Scottsdale Republic, September (reproduction)
                </span>

                <span class="emboldened">2002</span>
                <span>Bob Nickas, <i>Best of 2002: Top Ten</i>, Artforum, December, pp. 116-117</span>

                <span class="emboldened">2001</span>
                <span>David Bonetti, <i>Three Artists Who Grapple with History</i>, San Francisco Chronicle, November 20, p. D1</span>

                <span class="emboldened">2000</span>
                <span>Stewart Brand, <i>Whole Earth Covers</i>, CoEvolution Quarterly, Fall, pp. 58-59</span>
            </div>
        </div>

        <div class="title-grid">
            <h3>Publications, Portfolios, and Projects</h3>
            <div class="info-grid">
                <span class="emboldened">2023</span>
                <span>Speaker at TED Countdown Summit, July 2023</span>

                <span class="emboldened">2020</span>
                <span>
                    Cover artwork for <i>Animal Traffic: Lively Capital in the Global Exotic Pet Trade</i> by Rosemary-Claire Collard, Duke University Press<br/>
                    <i>Isabella Kirkland</i>, Umbigo Magazine Issue #72, <a rel="nofollow" href="https://umbigomagazine.com/en/blog/2020/04/22/umbigo-72-pt-download-gratuito/" target="_blank">https://umbigomagazine.com/en/blog/2020/04/22/umbigo-72-pt-download-gratuito/</a>
                </span>

                <span class="emboldened">2019</span>
                <span><i>Taxa</i>, Antennae, the Journal of Nature in Visual Culture, Issue #49</span>

                <span class="emboldened">2017</span>
                <span>
                    <i>In These Hands</i>, Orion 35th Anniversary Issue, Volume 36, #4<br/>
                    Cover Artwork for <i>Extinction Studies: Stories of Time, Death, and Generations</i>, by Rose, van Dooren, and Chrulew, Columbia University Press
                </span>

                <span class="emboldened">2016</span>
                <span>
                    Speaker at The Long Now Foundation, The Interval, San Francisco, CA<br/>
                    <i>Illustrations in Imagining Extinction: The Cultural Meanings of Endangered Species</i>, by Ursula K Heise, The University of Chicago Press<br/>
                    Speaker at DREAMFORCE ‘16, San Francisco, CA
                </span>

                <span class="emboldened">2013</span>
                <span>
                    Speaker at opening of <i>Nature’s Toolbox</i>, Ulrich Museum, Wichita, KS<br/>
                    Panelist and speaker at The Dayton Art Institute, OH<br/>
                    Speaker at Science Illustration Certificate Program, California State University, Monterey Bay, CA<br/>
                    Speaker at TEDx DeExtinction, conference convened by Revive and Restore with the support of TED and in partnership with the National Geographic Society, Washington DC<br/>
                    In discussion with Stewart Brand, David Brower Center, March 7; in conjunction with the Long Now Foundation
                </span>

                <span class="emboldened">2012</span>
                <span>Forward written by Isabella Kirkland to <i>Tiffany Bozic: Drawn by Instinct</i>, Ginko Press, Berkeley, CA</span>

                <span class="emboldened">2011</span>
                <span>
                    <i>NOVA</i>, limited-edition suite of prints, published by Feature Inc., New York, NY<br/>
                    Exit Art 2011 (and final) print portfolio (with Walton Ford, Charles Juhasz-Alvarado, Robert Kushner, Enoc Perez, and Alexis Rockman)<br/>
                    Speaker at <i>Biodiversity in Four Dimensions</i> symposium, Stanford University, April 8
                </span>

                <span class="emboldened">2010</span>
                <span>Presenter at Night Life series, California Academy of Sciences, San Francisco, CA</span>

                <span class="emboldened">2009</span>
                <span>International Conference on Biodiversity Informatics, London, UK, use of images for conference materials</span>

                <span class="emboldened">2008</span>
                <span>
                    Presenter at Bioneers annual conference, San Rafael, CA<br/>
                    <i>TAXA</i>, limited-edition suite of prints, published by Feature Inc., New York, NY<br/>
                    <i>Taxa</i>, monograph with texts by Edward O. Wilson and Susan Emerling, published by Feature Inc., New York, NY<br/>
                    Cover art for National Academies Press seasonal book catalog
                </span>

                <span class="emboldened">2007</span>
                <span>Presenter in conjunction with EO Wilson, TED, Monterrey, CA</span>

                <span class="emboldened">2006</span>
                <span>
                    Illustrations for <i>Art in Action: Nature, Creativity and Our Collective Future</i>, The Natural World Museum &amp; UNEP, Earth Aware Editions, San Rafael, CA<br/>
                    Reproductions in <i>Éco-Art</i> by Catherine Merckling, Université Marc Bloch, Strasbourg<br/>
                    <i>Call of Life: Facing the Mass Extinction</i> (reproductions of artwork), Film by Species Alliance
                </span>

                <span class="emboldened">2005</span>
                <span>
                    Reproductions in <i>Tricycle: The Buddhist Review</i>, Summer<br/>
                    American Butterflies 13, no. 1 (Spring), pp. 44-45 (reproduction and artist’s statement)
                </span>

                <span class="emboldened">2003</span>
                <span>Portfolio in <i>Grand Street</i>, no. 71 (Spring), Grand Street Press, New York, NY, pp. 92-96</span>

                <span class="emboldened">2002</span>
                <span>Cover art for <i>The Future of Life</i> by E. O. Wilson, Knopf, New York, NY</span>

                <span class="emboldened">2000</span>
                <span>Covers for Whole Earth Catalog front and back covers, Fall</span>
            </div>
        </div>

        <div class="title-grid">
            <h3>Selected Awards</h3>
            <div class="info-grid">
                <span class="emboldened">2008</span>		
                <span>Individual Artist Grant, Wynn Newhouse</span>
                
                <span class="emboldened">2004</span>		
                <span>
                    Individual Artist Grant, George Sugarman Foundation<br/>
                    Individual Artist Grant, Marin Arts Council
                </span>
            </div>
        </div>

        <div class="title-grid">
            <h3>Public Collections</h3>
            <div>
                Art, Design and Architecture Museum, University of California, Santa Barbara, CA<br/>
                Berkeley Art Museum and Pacific Film Archive, Berkeley, CA<br/>
                Chazen Museum of Art, University of Wisconsin, Madison, WI<br/>
                Davis Museum, Wellesley College, Wellesley, MA<br/>
                The Frances Lehman Loeb Art Center, Vassar College, Poughkeepsie, NY<br/>
                Hammer Museum, University of California, Los Angeles, CA<br/>
                The Hood Museum of Art, Dartmouth College, Hanover, NH<br/>
                Missouri Botanic Gardens, St Louis, MO<br/>
                New York Public Library, New York, NY<br/>
                Pennsylvania Academy of Fine Arts, Philadelphia, PA<br/>
                Picker Art Gallery, Colgate University, Hamilton, NY<br/>
                Queens Museum, Queens, NY<br/>
                Rhode Island School of Design, Providence, RI<br/>
                Richmond Center for Visual Arts, Western Michigan University, Kalamazoo, MI<br/>
                Rockefeller University, New York, NY<br/>
                San Francisco Museum of Modern Art, San Francisco, CA<br/>
                Spenser Museum of Art, University of Kansas, Lawrence, KS<br/>
                St. Louis Museum of Art, St. Louis, MO<br/>
                Tang Museum, Skidmore College, Saratoga Springs, NY<br/>
                The Garrison Institute, Garrison, NY<br/>
                The Toledo Art Museum, Toledo, OH<br/>
                University of Minnesota, Minneapolis, MN<br/>
                University Museum of Contemporary Art, University of Massachusetts, Amherst, MA<br/>
                Vera List Center, The New School, New York, NY<br/>
                Whitney Museum of American Art, New York, NY<br/>
                Williams College Museum of Art, Williamstown, MA<br/>
                Yale University Art Gallery, New Haven, CT<br/>
                Zimmerli Art Museum, Rutgers University, New Brunswick, NJ<br/>
            </div>
        </div>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
h3 {
    margin-top: 0;
    padding-top: 0;
}
.title-grid,
.info-grid {
  display: grid;
  gap: 6em;
}
.title-grid {
  grid-template-columns: 15em 1fr;
  gap: 6em;
}
.info-grid {
  grid-template-columns: 5em 1fr;
  gap: 1em 3em;
}
@media screen and (max-width: 768px) {
    .title-grid {
        gap: 1em;
        padding: 2em;
    }
    .title-grid,
    .info-grid {
        grid-template-columns: 1fr;
    }
}
.emboldened {
  font-weight: bold;
  text-transform: uppercase;
}
</style>
