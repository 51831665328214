<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import ImageWithContent from '../components/ImageWithContent.vue'
import BackTo from '../components/BackTo.vue';

import paintingsData from '../data/paintings';

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'CollectionPage',
    metaInfo() {
        const title = this.collection ? this.collection.name + ' – Isabella Kirkland' : 'Isabella Kirkland';
        
        // Description should be picked up as the first set of text on the page.

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title);
    },
    data: function() {
        return {
            viewAll: true
        };
    },
    components: {
        GlobalHeader,
        GlobalFooter,
        ImageWithContent,
        BackTo
    },
    computed: {
        'collection': function() {
            return Array.from(paintingsData.categories).find(collection => collection.slug === this.$route.params.collection);
        },
        'paintings': function () {
            const paintings = this.viewAll ? paintingsData.categories[this.collection.name] : paintingsData.categories[this.collection.name].slice(0, 200);
            
            return paintings.sort((a,b) => parseInt(a.year || 0) < parseInt(b.year || 0));
        }
    },
}
</script>

<template>
  <div>
    <GlobalHeader/>
    <div class="content pad">
        <div>
            
            <div class="grid--rows-of-two grid--vertical-center">
                <div class="heading-container">
                    <!-- <span class="sub-heading">Paintings</span> -->
                    <h2>{{ collection.name }}</h2>
                    <p>{{ collection.date }}</p>
                </div>
                <p v-html="collection.description"></p>
            </div>
        </div>
        <div>
            <BackTo path="/collections" text="paintings" :inline="true" />
            <div class="grid--rows-of-three">
                <ImageWithContent 
                    v-for="(painting, index) in this.collection.paintings.filter(painting => painting.visible)"
                    :key="painting.name"
                    :index="index"
                    :image="`/painting-thumbnails/${collection.slug}/${painting.slug}-tn.jpg`"
                    :header="painting.name"
                    :caption="`${painting.year}, ${painting.medium}, ${painting.dimensions}`" 
                    :route="`/collections/${collection.slug}/paintings/${painting.slug}`"
                />
            </div>
        </div>
        <!-- <button v-if="!viewAll" @click="() => { viewAll = true; }" class="button">View all</button> -->
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
button {
    margin: 0 auto;
}
div.content {
    gap: 4em;
}
.heading-container {
    height: fit-content;
}
.heading-container p {
    padding-top: 0;
}
</style>
