<template>
    <header>
        <section>
            <span></span>
            <img class="logo" @click="visitHome" src="@/assets/svg/logo.svg" />
            <span>
                <img v-if="search" class="search" @click="toggleSearch" src="@/assets/svg/magnifying-glass-grey.svg" />
                <img v-else class="search" @click="toggleSearch" src="@/assets/svg/magnifying-glass.svg" />
                <img v-if="!open" @click="toggleNavigation" src="@/assets/svg/burger-menu.svg" />
                <img v-else @click="toggleNavigation" src="@/assets/svg/cross.svg" />
            </span>
        </section>

        <nav v-show="open">
            <a class="h2" href="/collections">Paintings</a>
            <a class="h2" href="/species-keys">Species Keys</a>
            <a class="h2" href="/statement-and-acknowledgements">Statement &amp; Acknowledgements</a>
            <a class="h2" href="/curriculum-vitae">Curriculum Vitae</a>
            <a class="h2" href="/writings-and-talks">Writings &amp; Talks</a>
            <a class="h2" href="/process">Process Time-lapse</a>
            <a class="h2" href="/contact">Contact</a>
        </nav>

        <form v-show="search" id="search-form" @submit.prevent="runSearch">
            <input ref="search" :style="{ backgroundImage: `url(${require('@/assets/svg/magnifying-glass.svg')})` }" id="search-input" type="text" v-model="searchQuery" placeholder="Search paintings...">
            <button type="submit" @click="runSearch" id="search-button">Search</button>
        </form>
    </header>
</template>

<script>
export default {
    name: 'GlobalHeader',
    data() {
        return { 
            open: false,
            search: false,
            searchQuery: ''
        }
    },
    methods: {
        visitHome: function() {
            window.location.href = "/";
        },
        toggleNavigation: function() {
            this.open = !this.open;
        },
        toggleSearch: function() {
            this.search = !this.search;
            if(this.search) {
                this.$refs.search.focus();
            }
        },
        runSearch: function() {
            if(this.searchQuery.length > 0) {
                this.toggleSearch();
                window.location.href = `/search?query=${this.searchQuery}`;
            }
        }
    }
}
</script>

<style scoped>
header {
    border-bottom: 1px solid black;
    padding-top: 2rem;
    padding: 1.4em 4em 1.4em 3.12em;
}
header section {
    display: flex;
    justify-content: space-between;
}
header section .search {
    max-width: 240px;
    width: 15px;
    position: relative;
    top: -4px;
    left: -14px;
}
header section .logo {
    max-width: 240px;
}
header section img {
    cursor: pointer;
}
nav {
    align-content: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 4rem;
    min-height: 100vh;
    font-size: 15px;
    z-index: 1;
}
nav a {
    text-decoration: none;
}
#search-form {
    display: flex;
    gap: 1em;
    opacity: 1;
}
#search-input {
    background-color: #EDEDED;
    background-repeat: no-repeat;
    background-position: 20px 20px;
    background-size: 25px 24px;
    border-radius: 1em;
    border: none;
    margin: 2em 0;
    padding: 25px 25px 25px 54px;
    width: 100%;
    max-width: 80vw;
}
#search-button {
    background: black;
    border-radius: 1em;
    border: none;
    color: white;
    margin: 2em 0;
    padding: 25px 35px;
}
@media screen and (max-width: 768px) {
    header {
        padding-left: 1em;
        padding-right: 1em;
    }
    nav {
        font-size: 12px;
        gap: 0rem;
        padding: 2rem;
    }
}

@media screen and (min-width: 768px) {
    nav {
        position: absolute;
        background: white;
        width: 40vw;
        right: 0;
        gap: 0rem;
        font-size: 0.6em;
        padding: 4em 6em;
    }
}
</style>
