<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

// import DownloadButton from '../components/DownloadButton.vue'
import PaintingViewer from '../components/PaintingViewer.vue'
import ImageWithContent from '../components/ImageWithContent.vue'

import paintingsData from '../data/paintings';
import paintingKeysData from '../data/painting-keys';
import paintingKeysCoordinates from '../data/painting-keys-coordinates';

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'PaintingKeyPage',
    metaInfo() {
        const title = this.painting ? this.painting.name + ' – Isabella Kirkland' : 'Isabella Kirkland';
        
        const description = this.painting && this.painting.description ? this.painting.description : false;

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(title, description);
    },
    data: function() {
        return {
            viewAll: false
        };
    },
  components: {
    GlobalHeader,
    GlobalFooter,
    // DownloadButton,
    PaintingViewer,
    ImageWithContent
  },
  computed: {
        'collection': function() {
            return Array.from(paintingsData.categories).find(collection => collection.paintings.some(painting => painting.slug === this.$route.params.painting));
        },
        'painting': function() {
            return Array.from(this.collection.paintings).find(painting => painting.slug === this.$route.params.painting);
        },
        'collectionPaintings': function () {
            const otherPaintings = this.collection.paintings.filter(painting => painting.visible && painting.slug !== this.painting.slug);
            return this.viewAll ? otherPaintings : otherPaintings.slice(0, 3);
        },
        'paintingKey': function() {
            return Array.from(paintingKeysData.categories).find(pkc => pkc.slug === this.collection.slug).paintings.find(pk => pk.slug === this.painting.slug);
        },
        'paintingKeyCoords': function() {
            console.log(this.painting.slug, paintingKeysCoordinates[this.painting.slug]);
            return paintingKeysCoordinates[this.painting.slug];
        }
  },
  methods: {
      focusOnKey: function(key) {
          this.$refs.paintingViewer.$el.scrollIntoView({ behavior: 'smooth' });
          
          setTimeout(() => {
              this.$refs.paintingViewer.focusOnKey(key);
          }, 400);
      } 
  }
}
</script>

<template>
  <div id="painting-key-page">
    <GlobalHeader/>
    <PaintingViewer ref="paintingViewer" :collection="collection" :painting="painting" :paintingKey="paintingKey" :keyCoords="paintingKeyCoords" :key="$route.query._r" />
    <div class="content medium-pad">
        <div>
            <h2 id="heading">{{ painting.name }}</h2>
            <p>{{ painting.year }}, {{ painting.medium }}, {{ painting.dimensions }}</p>
        </div>
        <div class="content painting-key-information">
            <div>
                <ol>
                    <li v-for="entry in paintingKey.key" @click="focusOnKey(entry.index)" :key="entry.index">{{entry.name}} <span class="latin">{{entry.latin}} <span v-if="entry.location">({{entry.location}})</span></span></li>
                </ol>
                <p v-if="paintingKey.keyAddition">{{ paintingKey.keyAddition }}</p>
            </div>
            <!-- <DownloadButton :label="`${painting.name}-painting-key.pdf`" :file="`/painting-key-files/${collection.slug}/${painting.name.toLowerCase()}-painting-key.pdf`" text="Download" /> -->
        </div>
    </div>
    <div class="content medium-pad">
        <div>
            <h3>More {{ collection.name }} Paintings</h3>
            <div class="grid--rows-of-three">
                <ImageWithContent 
                    v-for="(painting, index) in collectionPaintings"
                    :key="painting.name"
                    :index="index"
                    :image="`/painting-thumbnails/${collection.slug}/${painting.slug}-tn.jpg`"
                    :header="painting.name"
                    :caption="`${painting.year}, ${painting.medium}, ${painting.dimensions}`" 
                    :route="`/collections/${collection.slug}/paintings/${painting.slug}`"
                />
            </div>
            <button v-if="!viewAll" @click="() => { viewAll = true; }" class="button">View all</button>
        </div>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
button {
    display: block;
    margin: 0 auto;
}
ol {
    column-count: 2;
    column-gap: 3em;
    padding-left: 1em;
    font-weight: bold;
}
@media screen and (max-width: 768px) {
    ol {
       column-count: 1;
    }
}
ol li span.latin {
    font-weight: 100;
    font-style: italic;
}
.painting-key-information {
    display: grid;
    grid-template-columns: auto;
}
.painting-key-information li {
    cursor: pointer;
}
.content {
    padding-top: 2em;
}
h2 {
    padding-bottom: 0;
}
</style>

<style>
#painting-key-page .content {
    gap: 0;
}
</style>
